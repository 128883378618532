export const textContent = {

    /**
     * EFT info for the bank
     */
    EFT_INFO: {
        description1: "Please EFT your order total into our account as seen below, and we will start preparing your order as soon as your payment hits our account.",
        description2: "Payments received into <b>Lions Den Biltong Online Bank Zero account</b> from other banks should reflect on the same business day in the evening between <b>19h00 - 21h00</b>, if the payment was triggered from the other bank before <b>16h00</b>. If the payment is made after <b>16h00</b>, it will only reflect in <b>Lions Den Biltong Online account</b> the following business day in the evening between <b>19h00 - 21h00</b>.",
        bank: {
            name: "Bank Zero Mutual Bank",
            account_type: "Check Account",
            account_name: "Lions Den Biltong Online Pty Ltd",
            account_number: "80204178318",
            branch_code: "888000",
        }
    },

    /**
     * The Terms & Conditions for usage of the website
     */
     TERMS_AND_CONDITIONS: {
        cookies: 'By signing/registering into our system, you agree to the storing of cookies and similar technologies on your device to enhance site navigation, analyze site usage, offer certain functionalities and assist in our marketing efforts. Strictly necessary cookies are always active.',
        goodsDescription: 'Lions Den Biltong Online (PTY) LTD is a business in the Retail Trade in Food  &  industry that  sells Biltong, Snacks & related products.',
        deliveryPolicy: 'Subject to availability and receipt of payment, requests will be processed within 7 days and delivery confirmed by way of email with order number, using door to door Couriers and PostNet or PAXI pick-up points, see detailed T\'s & C\'s for delivery costs and process on delivery fee document.',
        exportRestriction: 'The offering on this website is available to South African clients only.',
        returnAndRefunds: 'The provision of goods and services by Lions Den Biltong Online (PTY) LTD is subject to availability. In cases of unavailability, Lions Den Biltong Online (PTY) LTD will refund the client in full within 30 days. Cancellation of orders by the client will attract a 10% administration fee. Policy for damaged good, please see delivery fee document.',
        customerPrivacyPolicy: 'Lions Den Biltong Online (PTY) LTD shall take all reasonable steps to protect the personal information of users. For the purpose of this clause, "personal information" shall be defined as detailed in the Promotion of Access to Information Act 2 of 2000 (PAIA). The PAIA may be downloaded from: http://www.polity.org.za/attachment.php?aa_id=3569.',
        paymentOptionsAccepted: 'Payment may be made via Visa and MasterCard Credit & Debit Cards or Place Order then EFT amount into our account(account details will be emailed to you)',
        cardAcquiringAndSecurity: 'Card transactions will be acquired for Lions Den Biltong Online (PTY) LTD via Yoco Technologies Proprietary Limited Trading as Yoco  who are the approved payment gateway for all South African Acquiring Banks. For all Yoco T\'s & C\'s go to https://www.yoco.com/za/terms/  no Card details are stored on our website.',
        customerDetails: 'Customer details will be stored by Lions Den Biltong Online (PTY) LTD separately from card details which are entered by the client on Yoco’s secure site. For more detail on Yoco refer to https://www.yoco.com/za/terms/',
        merchantOutletCountryAndCurrency: 'The merchant outlet country at the time of presenting payment options to the cardholder is South Africa. Transaction currency is South African Rand (ZAR).',
        responsibility: 'This website is governed by the laws of South Africa and Lions Den Biltong Online (PTY) LTD chooses as its domicilium citandi et executandi for all purposes under this agreement, whether in respect of court process, notice, or other documents or communication of whatsoever nature, Postal Address: 39 Mpushini Avenue Waterfall, Pinetown, Durban, 3610. ',
        countryOfDomicile: 'This website is governed by the laws of South Africa and Lions Den Biltong Online (PTY) LTD chooses as its domicilium citandi et executandi for all purposes under this agreement, whether in respect of court process, notice, or other documents or communication of whatsoever nature, Postal Address: 39 Mpushini Avenue Waterfall, Pinetown, Durban, 3610.',
        variation: 'Lions Den Biltong Online (PTY) LTD may, in its sole discretion, change this agreement or any part thereof at any time without notice.',
        companyInformation: 'This website is run by Lions Den Biltong Online (PTY) LTD  based in South Africa trading as Lions Den Biltong Online and with registration number 2020/471406/07 and Directors Shaun Langton & Shirley Langton.',
        contactDetails: 'Company Physical Address: 39 Mpushini Avenue Waterfall, Pinetown, Durban, 3610. Email: shop@lionsdenbiltong.co.za Telephone: 031-7621258 / 0725769111'
    },

    DELIVERY_FEES: {
        freeDelivery: 'FREE DELIVERY for orders OVER R1000 Nationally ',
        normalOrder: {
            heading1: 'For orders UNDER R1000 ',
            heading2: 'Nationally - A flat rate of R99 per delivery',
            list: [
                'Door-to-door delivery with Fastway couriers to most major towns and cities; IF Door-to-Door delivery charges exceeds R99 in OUTLYING AREAS, we will use one of the following two options below.',
                'PostNet-to-PostNet Pick-up points are at all PostNet stores nation wide.',
                'PAXI pick-up points (There is guaranteed to be a PAXI Point near you. There are over 2000 locations around the country, including every PEP store in South Africa)',
            ]
        },
        specialInstructions: 'Please remember to add clear instructions to your order if you prefer PostNet-to-PostNet or PAXI pick up points, including if there are certain conditions we need to follow, for example, specific times of delivery.',
        deliveryProcess : 'Lions Den Biltong Online is based in Durban and all our products are shipped from Durban. We strive to deliver your order to you as soon as possible, but please allow 2 to 5 business days for delivery to take place once your order is with the courier company. The courier will deliver the parcels to the address you supplied when placing the order, OR to the nearest PostNet / PEP store to your supplied delivery address. You will receive an email with the relevant tracking details. We continuously strive to make sure your orders are shipped and delivered as efficiently, effectively and economically as possible and that your products arrive in a good condition.',
        damageInTransit : {
            text1: 'While the utmost care is taken when packaging your order, Lions Den Biltong Online uses third-party couriers to make your special deliveries. We cannot always guarantee that the products will reach you in the same condition that they left us, but we can help you in the unlikely event that your delivered goods are damaged.',
            steps: [
                'Take a photo of any damaged items.',
                'Send photo\s with your details, Waybill or Order Number to our email address shop@lionsdenbiltong.co.za or our WhatsApp  Shaun\'s WhatsApp',
                'We will replace the damaged items for you at no extra cost.',
                'Do not use the damaged product if it\'s being returned - this will void the arrangement and then we cannot replace it.'
            ],
            text2: 'In the unlikely event that the products you received do not match the order you placed, please contact us immediately so that we can rectify your order and make sure you receive the products you paid for.'
        },
        other: 'For any other queries regarding your order, please contact us on Email or Shaun\'s WhatsApp'
    },

    OLD_WEBSITE: {
        home: {
            section1: {
                header: "ONLINE STORE NOW OPEN",
                description: "We are proud to announce that our Online Store is now open to the public. We now are offering an opening special of FREE courier service all over South Africa for orders greater than R500. Our products are packed fresh for your orders and we stock a wide variety of snacks, biscuits, and sweet treats to tantalize your tastebuds. Head on over to our shop and see what we have in store for you!"
            },
            section1: {
                header: "LIONS DEN BILTONG",
                description: "We know Biltong, and our biltong connects people! We cater for every diverse preference! We complete every party!"
            },
            section1: {
                header: "ABOUT US",
                description: "Lions Den Biltong offers a wide range of biltong products made from beef, venison, ostrich, chicken and pork, with many different variants in these categories. Our biltong products are also packed in a number of different ways to suit our clients' requirements."
            }
        },
        team: {
            people: ['mzo', 'muzi', 'shaun'],
            description: "LIONS DEN BILTONG hails from a small town in Zimbabwe where the age-old tradition of biltong-making was perfected! This awesome snack, based in Durban, roams the east coast of SOUTH AFRICA, roaring out its delightful message, 'Just one bite, will whet your appetite'",
        },
        aboutUs: {
            "Lions Den Biltong": ["Lions Den Biltong offers a wide range of biltong products made from beef, venison, ostrich, chicken and pork, with many different variants in these categories. Our biltong products are also packed in a number of different ways to suit our clients' requirements. However, biltong is not the only product that we offer.", "We also distribute a number of other products in our basket namely: Just Nuts Flavoured Peanuts, Bisco Biscuits, Jennings Fine Foods, Squirrels Bavarian Nuts, Lions Den Sev & Nuts and Murkoo, Superade Sports Drink, Pork Crackling, Plastic Cups, Shooter Glasses & Winkies shooters"],
            "Client Satisfaction": ["We at Lions Den Biltong strive to offer all our customers unique service and client satisfaction. More than just offering good service, we also enjoy building a connection with all our clients, because we believe business is all about people, and people love connections!"],
            "Distribution": ["We distribute to a wide range of Supermarkets, convenience stores, pubs, liquor stores, biltong shops and everything in between. CONTACT US and one of our friendly sales representatives will be happy to personally see you when we are next in your area. At the moment, we are distributing to most of KZN."],
            "History": [
                "LIONS DEN BILTONG hails from a small town in Zimbabwe where the age-old tradition of biltong-making was perfected! This awesome snack, based in Durban, roams the east coast of South Africa, roaring out its delightful message, 'Just one bite, will whet your appetite'. Lions Den Biltong had a very humble and small beginning. I, Shaun Langton, grew up on a sugarcane farm at Oribi Gorge and later managed a dairy farm for almost 10 years.",
                "I decided to take the massive leap into the unknown by becoming an entrepreneur! In November 2002, I moved my family to Durban, and Lions Den Biltong was born in December 2002, with one staff member and myself. When Lions Den Biltong first started, we had a very small range of biltong products. In 2004, we added 'Just Nuts' to our basket, and with a lot of trial and error, we extended the biltong range. At approximately this time, we also developed our now flagship product, 'Lions Den CABBANOSSI'.",
                "This product has become a leader in the market, and we can proudly say we make the best Cabbanossi in KZN! In October 2004, we opened a retail store in the Pick n Pay Center situated in Hillcrest and ran this retail store alongside our wholesale department. Because of the very high overheads, we decided to close this retail store in October 2008, to concentrate on the wholesale department. In 2011, we started to experiment with adding other products to our basket.",
                "Some of these products were successful in the market, however, others weren't and therefore we stopped distributing them. Our history has consisted of good times and very tough times, to the point of nearly giving it all up, and going back to working for someone. The life lessons and experiences Lions Den Biltong has given me personally, are invaluable. The biggest lesson learnt is this: 'What you think about most, is what you become! Success starts inside each one of us before we ever see any results on the outside.' Some things to ponder on... Keeping perseverance in these areas will help to pave the way to success: PLAN, FOCUS, BE AUTHENTIC, KEEP LEARNING and watch your PERSONAL PRODUCTIVITY.",
            ]
        }
    },

};