import { State } from "@jordan_langton/activejs-raspberry-core";
import { Products } from "../Firebase/Collections/products";
import { Filters } from "../Firebase/Collections/Filters";
import {
    GetProductsFromCache,
    GetFiltersFromCache,
    CheckIfProductsAreStored,
    CheckIfFiltersAreStored,
    StoreProductsInCache,
    StoreFiltersInCache,
    CheckIfUsersAreStored, GetUsersFromCache, StoreUsersInCache, ClearCache
} from "../cache";
import {Utility} from "../Utility";
import {GetAllUsers} from "./usersState";
import { Types } from "../Types";

const ProductState = State.createStore({
    allProducts: [],
    filters: [],
});

/**
 * Makes sure the DB and the cache are always in sync
 */
export const setupProductDBSync = async () => {
    return new Promise((resolve) => {
        const unsubscribe = Products.initializeProductsSync((querySnapshot) => {
            // temp products
            const temp = [];
            const cache_items = GetProductsFromCache();
    
            // get list of products and their actions
            querySnapshot.docChanges().forEach((change) => {
                // get the product data
                const DB_PRODUCT = {
                    type: change.type,
                    product: change.doc.data(),
                    action: null
                };
    
                // select the type of change that happened to the DB
                if (change.type === Types.TYPE_DB_ADDED) DB_PRODUCT.action = () => LoadNewProduct(DB_PRODUCT.product);
                if (change.type === Types.TYPE_DB_MODIFIED) DB_PRODUCT.action = () => updateProductByKey(DB_PRODUCT.product.id);
                if (change.type === Types.TYPE_DB_REMOVED) DB_PRODUCT.action = () => DeleteProductById(DB_PRODUCT.product.id);
    
                // save product into temp
                temp.push(DB_PRODUCT);
            });
    
            // check for a count match
            const missing_items = [];
            let cache_products_amount = (cache_items)? cache_items.length : 0;
            const db_products_amount = temp.length;
    
            // if the is a miss match the cache is out of date
            if (cache_products_amount !== db_products_amount) {
                // clear current cache
                ClearCache();
                
                // make sure to add document to the cache and state
                temp.forEach(db_item => db_item.action())
            }
            // changes have been made to document
            else temp.forEach(db_item => db_item.action())

            resolve();
        });
    })
};

/**
 * Adds products to the list of products then stores in cache
 */
export const LoadProducts = async () => {
    // if products are in cache store those in the state
    if ((CheckIfProductsAreStored())) {
        ProductState.Commit("allProducts", [...GetProductsFromCache()]);
    }
    else {
        // no products in cache so we get from DB
        const products = await Products.getAll();
        ProductState.Commit("allProducts", [...products]);

        // also store these products from DB in cache for later
        StoreProductsInCache(products);
    }
}

/**
 * Makes sure the DB and the cache are always in sync
 */
 export const setupFilterDBSync = async () => {
    return new Promise((resolve) => {
        const unsubscribe = Products.initializeFiltersSync((querySnapshot) => {
            // temp products
            const temp = [];
            const cache_items = GetFiltersFromCache();
    
            // get list of products and their actions
            querySnapshot.docChanges().forEach((change) => {
                // get the product data
                const DB_FILTER = {
                    type: change.type,
                    filter: change.doc.data(),
                    action: null
                };
    
                // select the type of change that happened to the DB
                if (change.type === Types.TYPE_DB_ADDED) DB_FILTER.action = () => LoadNewFilter(DB_FILTER.filter);
                if (change.type === Types.TYPE_DB_MODIFIED) DB_FILTER.action = () => null;
                if (change.type === Types.TYPE_DB_REMOVED) DB_FILTER.action = () => DeleteFilterById(DB_FILTER.filter.id);
    
                // save product into temp
                temp.push(DB_FILTER);
            });
    
            // check for a count match
            const missing_items = [];
            let cache_filters_amount = (cache_items)? cache_items.length : 0;
            const db_filters_amount = temp.length;
            
            // if the is a miss match the cache is out of date
            if (cache_filters_amount !== db_filters_amount) {
                // clear current cache
                ClearCache();
                
                // make sure to add document to the cache and state
                temp.forEach(db_item => db_item.action())
            }
            // changes have been made to document
            else temp.forEach(db_item => db_item.action())

            resolve();
        });
    })
};

/**
 * Adds filters to the list of filters then stores in cache
 */
 export const LoadFilters = async () => {
    // if filters are in cache, store those in the state
    if ((CheckIfFiltersAreStored())) {
        ProductState.Commit("filters", [...GetFiltersFromCache()]);
    }
    else {
        // no products in cache so we get from DB
        const filters = await Filters.GetAllFilters();
        ProductState.Commit("filters", [...filters]);

        // also store these products from DB in cache for later
        StoreFiltersInCache(filters);
    }
}

/**
 * Adds an product to the list of products then stores in cache
 * @param {Object} product
 */
export const LoadNewProduct = (product) => {
    const current = GetAllProducts();
    ProductState.Commit("allProducts", [product, ...current]);

    // Update the cache with the new product
    StoreProductsInCache(GetAllProducts());
};

/**
 * Adds a filter to the list of filters then stores in cache
 * @param {Object} filter
 */
 export const LoadNewFilter = (filter) => {
    const current = GetAllFilters();
    ProductState.Commit("filters", [filter, ...current]);

    // Update the cache with the new product
    StoreFiltersInCache(GetAllFilters());
};

/**
 * Will update a product
 * @param {Object} productToUpdate
 */
export const UpdateProduct = (productToUpdate) => {
    // get the products
    let products = [];
    if ((CheckIfProductsAreStored())) products = GetProductsFromCache();
    else products = GetAllProducts();

    // update
    products.forEach(product => {
        if (product.id == productToUpdate.id) {
            product.status = productToUpdate.status;
            product.updates = productToUpdate.updates;
        }
    });

    // also store these products from DB in cache for later
    StoreProductsInCache(products);
};

/**
 * Will update the product in state based on a key value pair
 * @param {String} id
 * @param {Object} object
 */
export const updateProductByKey = async (id, object) => {
    let products = [];
    if (CheckIfProductsAreStored()) products = GetProductsFromCache();
    else products = GetAllProducts();

    // get the index of the user
    const check = {index: null};
    for (let i = 0; i < products.length; i++) {
        if (products[i].id == id) check.index = i;
    }

    // if the user is found make the updates
    if (check.index !== null) {
        // Find user details to updated
        for (const key in object) {
            Utility.setNestedProperty(products[check.index], key, object[key]);
        }

        // Update the users in state
        ProductState.Commit('allProducts', products);

        // Update the users in CACHE
        StoreProductsInCache(products);
    }
};

/**
 * Will remove a filter
 * @param {Object} productToUpdate
 */
export const removeFilter = (filerLabel) => {
    // get the products
    const updated = [];

    // get all filters in cache that don't have the label passed
    GetFiltersFromCache().forEach(filter => {if (filter.label !== filerLabel) updated.push(filter);});

    // update state
    ProductState.Commit("filters", updated);

    // also store these filters from DB in cache for later
    StoreFiltersInCache(updated);
};

/**
 * Retrieves a product based on an ID
 * @param {Number} Id
 * @returns
 */
export const GetById = (Id) => ProductState.Select("allProducts", false, (products) => products.filter(product => product.id === Id)[0]);

/**
 * Retrieves a product based on a CategoryId
 * @param {Number} Id
 * @returns
 */
export const GetByCategoryId = (Id) => ProductState.Select("allProducts", false, (products) => products.filter(product => product.categoryId === Id));

/**
 * Will return all the products
 * @returns {Object[]}
 */
export const GetAllProducts = () => ProductState.Select("allProducts");

/**
 * Will return all the products
 * @returns {Object[]}
 */
 export const GetAllFilters = () => {
    const Filters = ProductState.Select("filters");
    return Filters.sort((a, b) => a.label.localeCompare(b.label));
 }

/**
 * Will get all filters under a category
 * @returns {Object[]}
 */
export const GetFiltersByCategory = (Id) => ProductState.Select("filters").filter(_filter => _filter.categoryId == Id);

/**
 * Will delete a product by ID
 * @param { String } id
 * @param { Function } done
 */
export const DeleteProductById = (id, done) => {
    // get all users
    const all = GetAllProducts();
    
    // get the index of the user you want to delete
    let productIndex = null;
    all.forEach((product, i) => {if (product.id == id) productIndex = i});

    // delete the user from the list
    all.splice(productIndex, 1);
    
    // update state and CACHE
    ProductState.Commit('allUsers', all);
    StoreProductsInCache(all);

    if (done) done()
};

/**
 * Will delete a filter by ID
 * @param { String } id
 * @param { Function } done
 */
 export const DeleteFilterById = (id, done) => {
    // get all users
    const all = GetAllFilters();
    console.log("FILTER :: ", id);

    // get the index of the user you want to delete
    let filterIndex = null;
    all.forEach((filter, i) => {if (filter.id == id) filterIndex = i});

    // delete the user from the list
    all.splice(filterIndex, 1);

    // update state and CACHE
    ProductState.Commit('filters', all);
    StoreFiltersInCache(all);

    if (done) done()
};

export const resetProductsStore = (done) => {
    ClearCache();
    ProductState.Commit("allProducts", []);
    ProductState.Commit("filters", []);
    if (done) done();
};
