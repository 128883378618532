export const Banner = {
    
    /**
     * Will create and render a new banner section
     * @param {{renderEl: String, banners: Object[]}}} options
     * @returns {Object}
     */
    New: (options) => {
        // build up the banners html
        const Html = Banner.Html({
            banners: options.banners
        });

        // render the banners
        let swiper;
        Banner.Render({
            renderEl: options.renderEl,
            html: Html
        }, () => {
            // initialize a new swiper class
            swiper = new Swiper('.swiper', Banner.buildSwiperOptions());
        });

        return swiper;
    },

    /**
     * Will generate the swiper options
     */
    buildSwiperOptions: () => {
        const config = {
            // Install modules
            speed: 1000,
            slidesPerView: 1,
            direction: "vertical",
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
        };

        return config;
    },

    /**
     * Generates the banners HTML
     * @param {{banners: Object[]}} options
     * @returns {String}
     */
    Html: (options) => {
        let banners = '';
        options.banners.forEach(banner => {banners += `<div id="banner-slide-${banner.id}" class="swiper-slide"><img src="${banner.url}"></div>`;});

        return `
        <div class="swiper mySwiper">
            <div class="swiper-wrapper">${banners}</div>
            <div class="swiper-pagination"></div>
        </div>`;
    },

    /**
     * Renders the banners
     * @param {{renderEl: String, html: String}} options 
     * @param {Function} finish 
     */
    Render: (options, finish) => {
        const el = document.getElementById(options.renderEl);
        if (el) {
            el.innerHTML = options.html;
            finish();
        }
    }

};