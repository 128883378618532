import { State } from "@jordan_langton/activejs-raspberry-core";
import { Promos } from "../Firebase/Collections/promos";
import { Types } from "../Types";
import { Utility } from "../Utility";

const PromoStore = State.createStore({
    loadedPromoCodes: [
        // {
        //     code: "TESTPROMO",
        //     label: "10% discounted from your promo",
        //     percentage: .10,
        //     uses: 1,
        //     users: [],
        // },
        // {
        //     code: "TESTPROMO2",
        //     label: "50% discounted from your promo",
        //     percentage: .50,
        //     uses: 3,
        //     users: [
        //         {
        //             userId: "", 
        //             used: 0
        //         }
        //     ]
        // }
    ],
});

/**
 * Makes sure the DB and the cache are always in sync
 */
export const setupPromosDBSync = async () => {
    return new Promise((resolve) => {
        const unsubscribe = Promos.initializePromoSync((querySnapshot) => {
            // temp products
            const temp = [];
    
            // get list of products and their actions
            querySnapshot.docChanges().forEach((change) => {
                // get the product data
                const DB_PROMO = {
                    type: change.type,
                    promo: change.doc.data(),
                    action: null
                };
                
                // select the type of change that happened to the DB
                if (change.type === Types.TYPE_DB_ADDED) DB_PROMO.action = () => LoadNewPromo(DB_PROMO.promo);
                if(DB_PROMO.promo.id) {
                    if (change.type === Types.TYPE_DB_REMOVED) DB_PROMO.action = () => DeletePromo(DB_PROMO.promo.id, true);
                    if (change.type === Types.TYPE_DB_MODIFIED) DB_PROMO.action = () => UpdatePromo(DB_PROMO.promo.id, DB_PROMO.promo);
                }
                

                // save product into temp
                temp.push(DB_PROMO);
            });

            // changes have been made to document
            temp.forEach(db_item => db_item.action())
            
            resolve();
        });
    })
};

/**
 * Will return all the promos
 * @returns {Object[]}
 */
export const GetAllPromos = () => PromoStore.Select("loadedPromoCodes");

/**
 * Adds a promo to the list of promos then stores in cache
 * @param {Object} promo
 */
export const LoadNewPromo = (promo) => {
    const current = GetAllPromos();
    PromoStore.Commit("loadedPromoCodes", [promo, ...current]);
};

/**
 * Will update the product in state based on a key value pair
 * @param {String} id
 * @param {Object} object
 */
 export const UpdatePromo = async (id, object) => {
    let promos = GetAllPromos();

    // get the index of the user
    const check = {index: null};
    for (let i = 0; i < promos.length; i++) {
        if (promos[i].id == id) check.index = i;
    }

    // if the user is found make the updates
    if (check.index !== null) {
        // Find user details to updated
        for (const key in object) {
            Utility.setNestedProperty(promos[check.index], key, object[key]);
        }

        // update the promo in state
        PromoStore.Commit("loadedPromoCodes", promos);
    }
};

/**
 * Deletes a promo based on the ID
 * @param {String} id
 */
export const DeletePromo = (id) => {
    // get all users
    const all = GetAllPromos();
    
    // get the index of the promo you want to delete
    let promoIndex = null;
    all.forEach((promo, i) => {if (promo.id == id) promoIndex = i});

    // delete the promo from the list
    all.splice(promoIndex, 1);
    
    // update state and CACHE
    PromoStore.Commit('loadedPromoCodes', all);

    if (done) done()
};

/**
 * Will apply a use of a promo code to a user id
 * @param {*} userId 
 * @param {*} promoId 
 * @param {*} users 
 */
export const ApplyUseForUser = (promoId, users) => Promos.UpdateWithKey(promoId, {users}, true);