import { Load } from "@jordan_langton/activejs-raspberry-core";
import {Config} from "../Config";
import {Types} from "../Types";
import { dbBuilder } from "./dbBuilder";
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator, Timestamp } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator  } from "firebase/storage";
import { setupAuthStateChange } from "./Collections/authentication";
import { getPerformance } from "firebase/performance";
import { getAnalytics, logEvent } from "firebase/analytics";
import {Utility} from "../Utility";

export let DB;
export let AUTH;
export let FUNCTIONS;
export let TIME_STAMP;
export let STORAGE;
export let PERF;
export let ANALYTICS;
export let LOG_EVENT;

export const Firebase = {

    initialize: () => {
        // Initialize Cloud Firestore through Firebase
        const firebaseApp = initializeApp({
            apiKey: Utility.ENV_VALUE(process.env.API_KEY_LIVE),
            authDomain: Utility.ENV_VALUE(process.env.AUTH_DOMAIN_LIVE),
            databaseURL: Utility.ENV_VALUE(process.env.DATABASE_URL_LIVE),
            projectId: Utility.ENV_VALUE(process.env.PROJECT_ID_LIVE),
            storageBucket: Utility.ENV_VALUE(process.env.STORAGE_BUCKET_LIVE),
            messagingSenderId: Utility.ENV_VALUE(process.env.MESSAGING_SENDER_ID_LIVE),
            appId: Utility.ENV_VALUE(process.env.APP_ID_LIVE),
            measurementId: Utility.ENV_VALUE(process.env.MEASUREMENT_ID_LIVE)
        });

        // load the firebase app
        Load("firebaseApp", firebaseApp);

        // setup firebase accessors
        AUTH = getAuth();
        DB = getFirestore();
        FUNCTIONS = getFunctions(firebaseApp);
        STORAGE = getStorage();
        PERF = getPerformance(firebaseApp);
        ANALYTICS = getAnalytics();
        LOG_EVENT = logEvent;
        TIME_STAMP = Timestamp;

        // setup the events for auth updates
	    setupAuthStateChange();

        // setup the test/dev environment
        const ENVIRONMENT = Utility.ENV_VALUE(process.env.ENVIRONMENT);
        if (ENVIRONMENT === Types.DEVELOPMENT) {
            // connect to emulator
            connectAuthEmulator(AUTH, "http://localhost:9099");
            connectFirestoreEmulator(DB, 'localhost', 3031);
            connectFunctionsEmulator(FUNCTIONS, "localhost", 5001);
            connectStorageEmulator(STORAGE, "localhost", 9199);
            // populate DB with data
            dbBuilder.addToUsersCollection(5, !Config.USERS_PERSIST);
            dbBuilder.addOrdersCollection(!Config.USERS_PERSIST);
            dbBuilder.addProductsCollection(!Config.USERS_PERSIST);
            dbBuilder.addFiltersCollection(!Config.USERS_PERSIST);
        }
    }
};
