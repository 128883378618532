import {productList} from "./productList"
export const productCategories = {

    storedProductLists: {},

    /**
     * Will generate a top navigation bar
     * @param {{
     *     renderEl: String,
     *     categories: Array,
     *     products: Array,
     * }} options
     * @constructor
     */
    Create: (options) => {
        // reset the stored lists
        productCategories.storedProductLists = {};

        // generate HTML
        const HTML = productCategories.buildHTML({
            categories: options.categories,
            products: options.products,
        });

        // render the component
        productCategories.Render({renderEl: options.renderEl, html: HTML.html}, () => {
            // apply onclick to all nav items
            options.categories.forEach(category => {
                // get element
                const leftArrow = document.getElementById(`categoryArrowLeft-${category.id}`);
                const rightArrow = document.getElementById(`categoryArrowRight-${category.id}`);

                // apply onclick to the arrows
                if (leftArrow && productCategories.storedProductLists[category.id]) leftArrow.onclick = () => productCategories.storedProductLists[category.id].showPrevious(300, false);
                if (rightArrow && productCategories.storedProductLists[category.id]) rightArrow.onclick = () => productCategories.storedProductLists[category.id].showNext(300, false);

                // render the products in the category products wrapper
                if (productCategories.storedProductLists[category.id]) productCategories.storedProductLists[category.id].render();
            })
        });
    },

    /**
     * Will build up the html for the component
     * @param {{categories: Array, products: Array}} options
     * @returns {{html: String}}
     */
    buildHTML: (options) => {
        // generate the nav items html
        let categoriesHTML = "";
        options.categories.forEach(category => {
            const products = productCategories.getProductsForCategory(category.id, options.products);

            // make sure there are products to render
            if (products.length !== 0) {
                // generate the products
                if (!productCategories.storedProductLists[category.id]) productCategories.storedProductLists[category.id] = new productList({
                    id: category.id,
                    renderEl: `categoryProductsWrapper-${category.id}`,
                    length: products.length,
                    products: products
                });

                // build up category wrapper
                categoriesHTML += `
                <div class="categoryWrapper d-flex flex-col">
                    <div class="header d-flex flex-between flex-align">
                        <div class="heading">${category.label}</div>
                        <div class="icons d-flex flex-between flex-align">
                            <div id="categoryArrowLeft-${category.id}" class="icon d-flex flex-center flex-align"><i class="fas fa-chevron-left"></i></div>
                            <div id="categoryArrowRight-${category.id}" class="icon d-flex flex-center flex-align"><i class="fas fa-chevron-right"></i></div>
                        </div>
                    </div>
                    <div id="categoryProductsWrapper-${category.id}"></div>
                </div>`;
            }
        });

        return {
            html: `
            <div id="productCategories" class="d-flex flex-col">
               ${categoriesHTML}
            </div>`
        };
    },

    /**
     *
     * @param {{renderEl: String, html: String}} options
     * @param {Function} callback
     * @constructor
     */
    Render: (options, callback) => {
        // get render element
        const renderElement = document.getElementById(options.renderEl);

        // if element was found, render navbar
        if (renderElement) {
            // render
            renderElement.innerHTML = options.html;

            // apply onclick
            callback();
        }
        else console.error("productCategories :: Failed to find render element");
    },

    getProductsForCategory: (categoryId, items) => {
        const products = [];
        items.forEach(product => {if (product.categoryId === categoryId) products.push(product)});
        return products;
    }
};