import { Types } from "../Types";

export const productFilters = {

    /**
     * Generates a new product filter list
     * @param {{
     *      renderEl: String,
     *      filters: Object[],
     *      categoryId: Number,
     *      action: Function
     * }} options
     * @constructor
     */
    Create: (options) => {
        const FILTERS = productFilters.buildHTML({filters: options.filters, categoryId: options.categoryId});

        productFilters.Render({
            renderEl: options.renderEl,
            html: FILTERS
        }, () => {
            options.filters.forEach(filter => {
                // add click event to all filters
                const el = document.getElementById(`productFilter-${filter.id}`);
                if (el) el.onclick = () => options.action(filter.id);

                // add event to the (All) filter
                const allEl = document.getElementById(`productFilter-all`);
                if (allEl) allEl.onclick = () => options.action('all');
            });
        })
    },

    /**
     * Builds up the html for the filters list
     * @param {{filters: Object[], categoryId: Number}} options
     */
    buildHTML: (options) => {
        const text = (options.categoryId === Types.CATEGORY_BILTONG.id)? "All Biltong" : "All Snacks";
        let filters = `<div id='productFilter-all' class='filterItem is-active'>${text}</div>`;
        options.filters.forEach(filter => {filters += `<div id="productFilter-${filter.id}" class="filterItem">${filter.label}</div>`;});

        return filters;
    },

    Render: (options, callback) => {
        const renderEl = document.getElementById(options.renderEl);
        if (renderEl) {
            renderEl.innerHTML = options.html;
            callback();
        }
    }
};