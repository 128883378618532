import { Timestamp } from "@firebase/firestore";
import { TIME_STAMP } from "../Firebase/Firebase";
import { Types } from "../Types";
import { Utility } from "../Utility";

export const ordersHistoryList = {

    /**
     * Creates a list of your order history items
     * @param {{
     *      renderEl: String,
     *      orders: Object[],
     *      actions: { select: Function }
     * }} options
     * @param
     */
    Create: (options) => {
        const html = ordersHistoryList.buildHTML(options.orders);
        ordersHistoryList.Render({renderEl: options.renderEl, html}, () => {
            options.orders.forEach(order => {
                const orderEl = document.querySelector(`#orderItem-${order.id}`);
                if (orderEl) orderEl.onclick = () => options.actions.select(order.id);
            });
        });
    },

    /**
     * Builds the items
     * @param {Object[]} ordersList
     */
    buildHTML: (ordersList) => {
        let orders = "";
        ordersList.forEach(order => {
            const paymentColors = ordersHistoryList.getPaymentStatusColor(order.paymentStatus);
            const orderColors = ordersHistoryList.getOrderStatusColor(order.status);
            const orderIcon = ordersHistoryList.getStatusIcon(order.status);
            const datePlaced = new Date(order.datePlaced.seconds).toLocaleDateString("en-US", {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'});
            const status = (
                (order.paymentStatus.label == Types.PAYMENT_STATUS_AWAITING.label) &&
                (order.status.id == Types.ORDER_STATUS_WAITING.label)
            )? order.paymentStatus.label : order.status.label;
            const isDesktop = (Utility.device() === Types.DEVICE_TYPE_DESKTOP)? "" : "d-none";
            
            orders += `
            <div id="orderItem-${order.id}" class="order d-flex flex-between flex-align">
                <div class="item text-sm ${isDesktop}"># <span class="text-muted">${order.id}</span></div>
                <div class="item text-sm">${datePlaced}</div>
                <div class="item text-sm ${isDesktop}">${order.user.email}</div>              
                <div class="item text-sm"><span class="total">R${order.total}</span></div>
                <div class="item status">
                    <div class="highlight d-flex flex-align" >
                        <div class="icon" style="background-color: ${orderColors.bg}; color: ${orderColors.text}">${orderIcon}</div>
                        <div class="label" style="color: ${orderColors.bg}">${status}</div>
                    </div>
                </div>
            </div>
            `;
        });

        return orders;
    },

    /**
     * Renders the list
     * @param {{renderEl: String, html: String}} options
     * @param {Function} finish
     */
    Render: (options, finish) => {
        const el = document.getElementById(options.renderEl);
        if (el) {
            el.innerHTML = options.html;
            finish();
        }
    },

    /**
     * Gets the colors for the payment status
     * @param {{id: Number, label: String, dateUpdated: String}} status
     * @returns {{bg: String, text: String}}
     */
    getPaymentStatusColor: (status) => {
        switch (status.id) {
            case Types.PAYMENT_STATUS_AWAITING.id: return {bg: 'var(--warning)', text: 'var(--dark)'};
            case Types.PAYMENT_STATUS_RECEIVED.id: return {bg: 'var(--success)', text: 'var(--white)'};
        }
    },

    getOrderStatusColor: (status) => {
        switch (status.id) {
            case Types.ORDER_STATUS_WAITING.id: return {bg: 'var(--dark)', text: 'var(--white)'};
            case Types.ORDER_STATUS_PAYMENT_RECEIVED.id: return {bg: 'var(--warning)', text: 'var(--dark)'};
            case Types.ORDER_STATUS_PROCESSING.id: return {bg: 'var(--success)', text: 'var(--white)'};
            case Types.ORDER_STATUS_SHIPPED.id: return {bg: 'var(--info)', text: 'var(--white)'};
            case Types.ORDER_STATUS_DELIVERED.id: return {bg: 'var(--primary)', text: 'var(--white)'};
            case Types.ORDER_STATUS_CANCELED.id: return {bg: 'var(--danger)', text: 'var(--white)'};
        }
    },

    getStatusIcon: (status) => {
        switch (status.id) {
            case Types.ORDER_STATUS_WAITING.id: return '<i class="fas fa-paper-plane"></i>';
            case Types.ORDER_STATUS_PAYMENT_RECEIVED.id: return '<i class="fas fa-clipboard-check"></i>';
            case Types.ORDER_STATUS_PROCESSING.id: return '<i class="fas fa-tasks"></i>';
            case Types.ORDER_STATUS_SHIPPED.id: return '<i class="fas fa-shipping-fast"></i>';
            case Types.ORDER_STATUS_DELIVERED.id: return '<i class="fas fa-truck-loading"></i>';
            case Types.ORDER_STATUS_CANCELED.id: return '<i class="fas fa-ban"></i>';
        }
    },

};
