import { Utility } from "../Utility";
import { Types } from "../Types";
import { Cart } from "../Cart";

export const topNavBarStore = {

    /**
     * Nav item
     * @type {{id: *, icon: String, label: String, active: Boolean, highlight: Boolean, action: Function}}
     */
    TYPE_NAV_ITEM: {
        id: "",
        icon: "" || false,
        label: '',
        active: false,
        highlight: false,
        action: () => {
        }
    },

    /**
     * Will generate a top navigation bar
     * @param {{
     *      renderEl: String,
     *      logo: String,
     *      type: String,
     *      company: String,
     *      navItems: topNavBarStore.TYPE_NAV_ITEM[],
     *      mobileBars: () => Any,
     *      mobileCart: () => Any
     * }} options
     * @constructor
     */
    Create: (options) => {
        // generate HTML
        const HTML = topNavBarStore.buildHTML({
            logo: options.logo,
            icon: options.icon,
            type: options.type,
            company: options.company,
            navItems: options.navItems,
        });

        // render the component
        topNavBarStore.Render({renderEl: options.renderEl, html: HTML}, () => {
            if (Utility.device() !== Types.DEVICE_TYPE_DESKTOP) {
                const mobileNavBars = document.querySelector('#mobileNavBars');
                const sideNavAction = document.querySelector('#sideNavAction');
                const cartButton = document.querySelector('#cartButton');
                const cartIndicator = document.querySelector('#cartIndicator');
                if (mobileNavBars) mobileNavBars.onclick = (e) => options.mobileBars(e);
                if (sideNavAction) sideNavAction.onclick = (e) => options.mobileBars(e);
                if (cartIndicator && cartButton) {
                    const showCartIndicator = (Cart.items.length === 0)? "d-none" : "d-flex";
                    cartButton.onclick = (e) => options.mobileCart(e);
                    cartIndicator.innerHTML = Cart.items.length;
                }
            }
            
            // apply onclick to all nav items
            options.navItems.forEach(item => {
                // get element
                const isMobile = (Utility.device() === Types.DEVICE_TYPE_MOBILE || Utility.device() === Types.DEVICE_TYPE_TABLET);
                const element = document.getElementById(`navBarItem-${item.id}${(isMobile)? "-mobile" : ""}`);
                
                // apply onclick if element was found
                if (element) element.onclick = item.action;
                else console.error(`topNavBarStore :: Failed to apply onclick for element (navBarItem-${item.id})`);
            })
        });

        return {
            toggleSideMenu: () => {
                const navbar = document.querySelector('#mobileSideNav');
                if (navbar) {
                    if (navbar.classList.contains('active')) navbar.classList.remove('active')
                    else navbar.classList.add('active')
                }
            },
            updateCartIndicator: () => {
                const cartIndicator = document.querySelector('#cartIndicator');
                if (cartIndicator && Cart.items.length > 0) {
                    let amount = 0;
                    Cart.items.forEach(item => amount += item.qty)
                    cartIndicator.innerHTML = amount;
                    cartIndicator.classList.remove('d-none');
                    cartIndicator.classList.add('d-flex');
                }
            }
        };
    },

    /**
     * Will build up the html for the component
     * @param {{logo: String, icon: String, type: String, company: String, navItems: topNavBarStore.TYPE_NAV_ITEM[]}} options
     * @returns String
     */
    buildHTML: (options) => {

        // generate the nav items html
        let navItems = "";
        const isMobile = (Utility.device() === Types.DEVICE_TYPE_MOBILE || Utility.device() === Types.DEVICE_TYPE_TABLET);
        const showMobileNav = (isMobile)? "d-flex" : "d-none";
        const showDesktopNav = (isMobile)? "d-none" : "d-flex";
        const showCartIndicator = (Cart.items.length === 0)? "d-none" : "d-flex";
        if (options.navItems) options.navItems.forEach(item => {
            // select which item is active
            const isActive = (item.active)? 'is-active' : '';
            const highlight = (item.highlight)? 'highlight' : '';
            const position = (isMobile)? 'd-flex flex-align' : 'd-flex flex-center flex-align';

            // choose to put a logo
            let icon = "";
            if (item.icon !== "" && item.icon !== false) icon = `<div class="icon">${item.icon}</div>`;

            // choose to put a logo
            let label = "";
            if (item.label !== "" && item.label !== false) label = `<div class="label text-md-bold">${item.label}</div>`;

            navItems += `
            <div id="navBarItem-${item.id}${(isMobile)? "-mobile" : ""}" class="item ${highlight} ${isActive} ${position}">
                ${icon}
                ${label}
            </div>`;
        });

        // choose to show logo or a custom icon
        let logo = "";
        if (options.logo) logo = `<img src="${options.logo}">`;
        else logo = options.icon; 

        return `
        <div id="topNavBarStore" class="d-flex flex-between flex-align">
            <!-- hamburger -->
            <div class="logo d-flex flex-align">
                <div class="icon">${logo}</div>
                <div class="text text-lg-bold">${options.company}</div>
            </div>
            <div class="d-flex flex-align">
                <!-- nav items wrapper -->
                <div class="navItemsWrapper ${showDesktopNav} flex-end flex-align">
                    <!-- nav items -->
                    ${(!isMobile) ? navItems : ""}
                </div>

                <!-- nav items wrapper -->
                <div id="cartButton" class="mobileCartWrapper ${showMobileNav} flex-align flex-center">
                    <i class="fa-solid fa-cart-shopping"></i>
                    <div id="cartIndicator" class="${showCartIndicator} flex-align flex-center"></div>
                </div>

                <!-- cart item wrapper -->
                <div id="mobileNavBars" class="mobileNavWrapper ${showMobileNav} flex-align flex-center">
                    <i class="fa-solid fa-bars"></i>
                </div> 

                <div id="mobileSideNav" class="d-flex flex-col">
                    <!-- header wrapper -->
                    <div class="headerWrapper d-flex flex-align flex-between">
                        <div class="logo d-flex flex-center flex-align">${logo}</div>
                        <div id="sideNavAction" class="action d-flex flex-align flex-center">
                            <i class="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    
                    <!-- items wrapper -->
                    <div class="itemsWrapper d-flex flex-col flex-align">${navItems}</div>
                    
                    <!-- footer wrapper -->
                    <div class="footerWrapper d-flex flex-col flex-align">
                        <div id="sideNavBarItem-logout" class="item d-flex flex-align">
                            <div class="icon text-md"><i class="fas fa-sign-out-alt"></i></div>
                            <div class="labelText text-md">Logout</div>
                            <span class="tip text-center text-sm-bold">Logout</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="menuSpacer"></div>`;
    },

    /**
     *
     * @param {{renderEl: String, html: String}} options
     * @param {Function} callback
     * @constructor
     */
    Render: (options, callback) => {
        // get render element
        const renderElement = document.getElementById(options.renderEl);
        
        // if element was found, render navbar
        if (renderElement) {
            // render
            renderElement.innerHTML = options.html;

            // apply onclick
            callback();
        }
        else console.error("topNavBarStore :: Failed to find render element :: "+options.renderEl);
    },

    /**
     * Methods that the class will use
     */
    methods: {
        toggleMinimize: () => {
            const el = document.getElementById("sideNavBarDashWrapper");
            if (el) el.classList.toggle("minimized");
        }
    }
};