import { State } from "@jordan_langton/activejs-raspberry-core";
import { deleteDoc } from "firebase/firestore";
import { CheckIfOrdersAreStored, GetOrdersFromCache, StoreOrdersInCache, ClearCache } from "../cache";
import { Orders } from "../Firebase/Collections/Orders";
import { Types } from "../Types";
import { Utility } from "../Utility";
const OrderStore = State.createStore({
    allOrders: [],
});

/**
 * Makes sure the DB and the cache are always in sync
 */
 export const setupOrdersDBSync = async () => {
    return new Promise((resolve) => {
        const unsubscribe = Orders.initializeSync((querySnapshot) => {
            // temp products
            const temp = [];
            const cache_orders = GetOrdersFromCache();
    
            // get list of products and their actions
            querySnapshot.docChanges().forEach((change) => {
                // get the product data
                const DB_ORDER = {
                    type: change.type,
                    order: change.doc.data(),
                    action: null
                };
                
                // select the type of change that happened to the DB
                if (change.type === Types.TYPE_DB_ADDED) DB_ORDER.action = () => LoadNewOrder(DB_ORDER.order);
                if(DB_ORDER.order.id) {
                    if (change.type === Types.TYPE_DB_REMOVED) DB_ORDER.action = () => DeleteOrder(DB_ORDER.order.id, true);
                    if (change.type === Types.TYPE_DB_MODIFIED) DB_ORDER.action = () => UpdateOrder(DB_ORDER.order);
                }
                

                // save product into temp
                temp.push(DB_ORDER);
            });
    
            // check for a count match
            const missing_orders = [];
            let cache_orders_amount = (cache_orders)? cache_orders.length : 0;
            const db_orders_amount = temp.length;
    
            // if the is a miss match the cache is out of date
            if (cache_orders_amount !== db_orders_amount) {
                // clear current cache
                ClearCache();
                
                // make sure to add document to the cache and state
                temp.forEach(db_item => db_item.action())
            }
            // changes have been made to document
            else temp.forEach(db_item => db_item.action())
            
            resolve();
        });
    })
};

/**
 * Adds orders to the list of orders then stores in cache
 */
export const LoadOrders = async () => {
    // if orders are in cache store those in the state
    if ((CheckIfOrdersAreStored())) {
        OrderStore.Commit("allOrders", [...GetOrdersFromCache()]);
    }
    else {
        // no orders in cache so we get from DB
        const orders = await Orders.getAll();
        OrderStore.Commit("allOrders", [...orders]);

        // also store these orders from DB in cache for later
        StoreOrdersInCache(orders);
    }
}

/**
 * Adds an order to the list of orders then stores in cache
 * @param {Object} order
 */
export const LoadNewOrder = (order) => {
    const current = GetAllOrders();
    OrderStore.Commit("allOrders", [order, ...current]);

    // Update the cache with the new order
    StoreOrdersInCache(GetAllOrders());
};

export const UpdateOrder = (updatedOrder) => {
    // get the orders
    let orders = [];
    if ((CheckIfOrdersAreStored())) orders = GetOrdersFromCache();
    else orders = GetAllOrders();

    // update
    orders.forEach(order => {
        if (order.id == updatedOrder.id || order.tempId == updatedOrder.tempId) {
            order.id = updatedOrder.id;
            order.status = updatedOrder.status;
            order.updates = updatedOrder.updates;
        }
    });

    // update the order in state
    OrderStore.Commit("allOrders", orders);

    // also store these orders from DB in cache for later
    StoreOrdersInCache(orders);
};

/**
 * Retrieves an order based on an ID
 * @param {Number} Id
 * @returns
 */
export const GetById = (Id) => OrderStore.Select("allOrders", false, (orders) => order.filter(order => order.id == Id)[0]);

/**
 * Will return all the orders
 * @returns {Object[]}
 */
export const GetAllOrders = () => {
    const Orders = OrderStore.Select("allOrders");
    return Orders.sort((a, b) => b.datePlaced.seconds - a.datePlaced.seconds);
};

/**
 * Will return all the orders for a user
 * @param { String } userId
 * @returns {Object[]}
 */
export const GetAllOrdersForUser = (userId) => GetAllOrders().filter(order => order.user.id === userId);

export const GetLatestOrderForUser = (userId) => {
    const usersOrders = [];
    GetAllOrders().filter(order => { if (order.user.id === userId) usersOrders.push(order) });
    return usersOrders.sort((a, b) => b.datePlaced - a.datePlaced)[0];
}


/**
 * Deletes a order based on the ID
 * @param {String} id
 */
export const DeleteOrder = (id) => {
    // get all users
    const all = GetAllOrders();
    
    // get the index of the order you want to delete
    let orderIndex = null;
    all.forEach((order, i) => {if (order.id == id) orderIndex = i});

    // delete the order from the list
    all.splice(orderIndex, 1);
    
    // update state and CACHE
    OrderStore.Commit('allOrders', all);
    StoreOrdersInCache(all);
};

export const resetOrdersStore = () => {
    ClearCache();
    OrderStore.Commit("allOrders", []);
};
