import {Firebase, AUTH, DB, STORAGE} from "../Firebase";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { doc, getDoc, getDocs, deleteDoc, setDoc, addDoc, updateDoc, collection, onSnapshot, orderBy, query, limit } from "firebase/firestore";
import { Popup } from "../../Components/Popup";
import { Types } from "../../Types";
import { Utility } from "../../Utility";

export const Products = {

    /**
     * Will set/override a document under the [users] collection
     * @param {{product: Object, log: Boolean}} options
     * @param { Function } done
     */
    Create: (options, done) => {
        addDoc(collection(DB, "products"), options.product)
        .then((res) => {
            updateDoc(doc(DB, `products/${res.id}`),{id: res.id})
                .then((data) => {
                    // callback
                    if (done) done(res)

                    // log a success
                    if (options.log) Utility.Log("SUCCESS", `ADMIN PRODUCTS :: (addDoc, updateDoc)`, res);
                })
                .catch(err => Popup.New({
                    renderTo: "productsPopup",
                    type: Types.POPUP_ERROR,
                    id: "product-create-fail",
                    header: 'Firebase Failure',
                    message: err.message,
                    other: null,
                    mainAction: {
                        id: 1,
                        text: "Dismiss",
                        action: () => Popup.Close()
                    },
                }));
        });
    },

    /**
     * Will update an product based on a key
     * @param {Number} id
     * @param {Object} key
     * @param {Boolean} log
     * @param {Function} success
     */
    UpdateWithKey: (id, key, log, success) => {
        const productRef = doc(DB, `products/${id}/`);
        updateDoc(productRef, key, { merge: true }).then(() => {
            if (success) success();
            if (log) Utility.Log("SUCCESS", `ADMIN PRODUCTS :: (updateDoc)`, key);
        });
    },

    /**
     * Deletes a user based on the ID
     * @param {String} id
     * @param {Boolean} log
     * @param {Function} callback
     */
    Delete: async (id, log, callback) => {
        await deleteDoc(doc(DB, `products/${id}/`)).then(() => {
            if (callback) callback();
            if (log) Utility.Log("SUCCESS", `ADMIN PRODUCTS :: (deleteDoc)`, id);
        });
    },

    /**
     * Will attempt to retrieve all user documents
     * @returns {Array}
     */
    getAll: async () => {
        const products = [];
        const snapshot = await getDocs(collection(DB, "products"));
        snapshot.forEach(doc => products.push(doc.data()));
        return products;
    },

    /**
     * Will attempt to retrieve a document based on an ID
     * @param {String} id
     * @returns {Object | null}
     */
    getProductByID: async (id) => {
        const productRef = doc(DB, `products/${id}`);
        const productSnap = await getDoc(productRef);

        if (productSnap.exists()) return productSnap.data();
        else {
            Utility.Log("ERROR", `ADMIN PRODUCTS :: (getProductByID)`, id);
            return null;
        }
    },

    /**
     * Deletes every product document stored under the products collection
     */
    deleteAllProducts: async () => {
        const snapshot = await getDocs(collection(DB, "products"));
        snapshot.forEach(doc => Products.Delete(doc.data().id));
    },

    initializeProductsSync: async (callback) => {
        const q = query(collection(DB, "products"));
        return onSnapshot(q, callback);
    },

    initializeFiltersSync: async (callback) => {
        const q = query(collection(DB, "filters"));
        return onSnapshot(q, callback);
    },

    /**
     * Will save a product image
     * @param {{name: String, file: Blob}} options
     * @param {Function} done
     */
    SaveProductImage: (options, done) => {
        const storageRef = ref(STORAGE, options.name);
        uploadString(storageRef, options.file, 'data_url').then(snapshot => Products.GetURLForImage(snapshot, (URL) => done(URL))).catch((err) => Popup.New({
            renderTo: "productsPopup",
            type: Types.POPUP_ERROR,
            id: "upload-image",
            header: 'Failed To Upload Image',
            message: err.message,
            other: null,
            mainAction: {
                id: 1,
                text: "Dismiss",
                action: () => Popup.Close()
            },
        }));
    },

    GetURLForImage: (snapshot, done) => {
        getDownloadURL(snapshot.ref).then(done);
    },

};
