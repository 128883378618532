import { signUserOut } from "../Firebase/Collections/authentication";

export const sideNavBarDash = {

    /**
     * Nav item
     * @type {{id: *, icon: String, label: String, active: Boolean, action: Function}}
     */
    TYPE_NAV_ITEM: {
        id: "",
        icon: "" || false,
        label: '',
        active: false,
        action: () => {
        }
    },

    /**
     * Will generate a top navigation bar
     * @param {{
     *     renderEl: String,
     *     navItems: sideNavBarDash.TYPE_NAV_ITEM[],
     *     collapsed: Boolean,
     *     company: {
     *         logo: String,
     *         text: String,
     *     },
     * }} options
     * @constructor
     */
    Create: (options) => {
        // generate HTML
        const HTML = sideNavBarDash.buildHTML({
            logo: options.company.logo,
            company: options.company.text,
            navItems: options.navItems,
            collapsed: (options.collapsed)
        });

        // render the component
        sideNavBarDash.Render({renderEl: options.renderEl, html: HTML}, () => {
            // check if the navbar is minimized
            if (options.collapsed) {
                const el = document.getElementById("sideNavBarDashWrapper");
                if (el) el.classList.toggle("minimized");
            }

            // add logout action
            const logout = document.getElementById("sideNavBarItem-logout");
            if (logout) logout.onclick = () => signUserOut();


            // apply onclick to all nav items
            options.navItems.forEach(item => {
                // get element
                const element = document.getElementById(`sideNavBarItem-${item.id}`);

                // apply onclick if element was found
                if (element) element.onclick = item.action;
                else console.error(`sideNavBarDash :: Failed to apply onclick for element (sideNavBarItem-${item.id})`);
            })
        });
    },

    /**
     * Will build up the html for the component
     * @param {{logo: String, company: String, collapsed: Boolean, navItems: sideNavBarDash.TYPE_NAV_ITEM[]}} options
     * @returns String
     */
    buildHTML: (options) => {

        // generate the nav items html
        let navItems = "";
        if (options.navItems) options.navItems.forEach(item => {
            // select which item is active
            const isActive = (item.active)? 'is-active' : '';

            // choose to put a logo
            let icon = "";
            if (item.icon !== "") icon = `<div class="icon text-md">${item.icon}</div>`;

            // choose to put a label
            let label = "";
            if (item.label !== "") label = `<div class="labelText text-md">${item.label}</div>`;

            // choose to put a tip
            let tip = "";
            if (item.label !== "") tip = `<span class="tip text-center text-sm-bold">${item.label}</span>`;

            navItems += `
            <div id="sideNavBarItem-${item.id}" class="item ${isActive} d-flex flex-align">
                ${icon}
                ${label}
                ${tip}
            </div>`;
        });

        return `
        <div id="sideNavBarDash" class="d-flex flex-col">
            <!-- header wrapper -->
            <div id="sideNavBarDash-company" class="headerWrapper d-flex flex-center flex-align">
                <div class="logo d-flex flex-center flex-align">${options.logo}</div>
                <div class="company text-white text-lg-bold">${options.company}</div>
            </div>
            
            <!-- items wrapper -->
            <div class="itemsWrapper d-flex flex-col flex-align">${navItems}</div>
            
            <!-- footer wrapper -->
            <div class="footerWrapper d-flex flex-col flex-align">
                <div id="sideNavBarItem-logout" class="item d-flex flex-align">
                    <div class="icon text-md"><i class="fas fa-sign-out-alt"></i></div>
                    <div class="labelText text-md">Logout</div>
                    <span class="tip text-center text-sm-bold">Logout</span>
                </div>
            </div>
        </div>`;
    },

    /**
     *
     * @param {{renderEl: String, html: String}} options
     * @param {Function} callback
     * @constructor
     */
    Render: (options, callback) => {
        // get render element
        const renderElement = document.getElementById(options.renderEl);

        // if element was found, render navbar
        if (renderElement) {
            // render
            renderElement.innerHTML = options.html;

            // apply onclick
            callback();
        }
        else console.error("sideNavBarDash :: Failed to find render element");
    },

    /**
     * Methods the side nav will use
     */
    methods: {

    },
};