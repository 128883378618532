export const filters_dump = [
    {
        "label": "Venison",
        "categoryId": "2"
    },
    {
        "label": "Vacuum Sticks",
        "categoryId": "2"
    },
    {
        "categoryId": "2",
        "label": "Sticks"
    },
    {
        "categoryId": "3",
        "label": "Squirrels"
    },
    {
        "categoryId": "2",
        "label": "Sliced"
    },
    {
        "categoryId": "2",
        "label": "Slabs"
    },
    {
        "categoryId": "3",
        "label": "Sev Nuts & Murkoo"
    },
    {
        "label": "Pork Crackling",
        "categoryId": "3"
    },
    {
        "categoryId": "2",
        "label": "Kudu"
    },
    {
        "categoryId": "3",
        "label": "Just Nuts"
    },
    {
        "categoryId": "2",
        "label": "Dry Wors"
    },
    {
        "label": "Cabanossi",
        "categoryId": "2"
    },
    {
        "categoryId": "2",
        "label": "Beef"
    },
    {
        "categoryId": "2",
        "label": "Bacon Biltong"
    }
]