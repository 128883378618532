import { Utility } from "./Utility";
export const Email = {
    workflow: null,

    /**
     * Sets up the event name
     * @param { String } name
     * @returns
     */
    workflowID: (id) => Email.workflow = id,

    /**
     * Will return you a POST request object
     */
    POST: () => {
        return {
            options: {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYjVmOTFhZjMxNWQwYjdkYWYyOGY5ODM4MTdiMjA1MGZhNmZlZDc4YjgyYzNlYmM4OTk1Yjg1NjQ1MDQ3NjVhMGUzOWEzNjNhZDAzYjJiMjUiLCJpYXQiOiIxNjcwNDI0NDc5Ljc3OTk5MCIsIm5iZiI6IjE2NzA0MjQ0NzkuNzc5OTk0IiwiZXhwIjoiNDgyNDAyNDQ3OS43NzY1NTYiLCJzdWIiOiI3NjM4MzciLCJzY29wZXMiOltdfQ.Y5gOsnweO7rSyKnAWJdWL-eanYWg9r5c6xytJBnqJGzvC6C1d5K7IwV3CjiIbUTIg6-1TXYqf9D5fxR2igmVQA",
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
                body: {}
            },
            data: function (name, value) { this.options.body[name] = value },
        }
    },

    /**
     * Will return you a PATCH request object
     */
    PATCH: () => {
        return {
            options: {
                method: 'PATCH',
                headers: {
                    "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYjVmOTFhZjMxNWQwYjdkYWYyOGY5ODM4MTdiMjA1MGZhNmZlZDc4YjgyYzNlYmM4OTk1Yjg1NjQ1MDQ3NjVhMGUzOWEzNjNhZDAzYjJiMjUiLCJpYXQiOiIxNjcwNDI0NDc5Ljc3OTk5MCIsIm5iZiI6IjE2NzA0MjQ0NzkuNzc5OTk0IiwiZXhwIjoiNDgyNDAyNDQ3OS43NzY1NTYiLCJzdWIiOiI3NjM4MzciLCJzY29wZXMiOltdfQ.Y5gOsnweO7rSyKnAWJdWL-eanYWg9r5c6xytJBnqJGzvC6C1d5K7IwV3CjiIbUTIg6-1TXYqf9D5fxR2igmVQA",
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
                body: {}
            },
            data: function (name, value) { this.options.body[name] = value },
        }
    },

    /**
     * Will return you a GET request object
     */
    GET: () => {
        return {
            options: {
                method: 'GET',
                headers: {
                    "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYjVmOTFhZjMxNWQwYjdkYWYyOGY5ODM4MTdiMjA1MGZhNmZlZDc4YjgyYzNlYmM4OTk1Yjg1NjQ1MDQ3NjVhMGUzOWEzNjNhZDAzYjJiMjUiLCJpYXQiOiIxNjcwNDI0NDc5Ljc3OTk5MCIsIm5iZiI6IjE2NzA0MjQ0NzkuNzc5OTk0IiwiZXhwIjoiNDgyNDAyNDQ3OS43NzY1NTYiLCJzdWIiOiI3NjM4MzciLCJzY29wZXMiOltdfQ.Y5gOsnweO7rSyKnAWJdWL-eanYWg9r5c6xytJBnqJGzvC6C1d5K7IwV3CjiIbUTIg6-1TXYqf9D5fxR2igmVQA",
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }
        }
    },

    /**
     * Will attempt to send an email with the defined options build up
     * @param { Function } done
     */
    send: async (options, done) => {
        // stringify the body
        options.body = JSON.stringify(options.body);

        // make request to SIB API
        const response = await fetch(`https://api.sender.net/v2/workflows/${Email.workflow}/start/`, options)
        try {
            const result = await response.json()
            if (!response.ok) return Utility.Log("ERROR", result.message, result);

            done(result, options)
        } catch (error) {
            Utility.Log("ERROR", error);
        }

    },

    /**
     * Will save a new contact under sender
     * @param { Function } done
     */
    subscribeUser: async (options, done) => {
        // stringify the body
        options.body = JSON.stringify(options.body);

        // make request to SIB API
        const response = await fetch('https://api.sender.net/v2/subscribers', options)
        const result = await response.json()
        if (!response.ok) return Utility.Log("ERROR", result.message, result);

        done(result, options)
    },

    /**
     * Updates the order_id property on a subscriber
     * @param { Function } done
     */
    updateOrderId: async (options, done) => {
        // stringify the body
        const email = options.body.email;
        options.body = JSON.stringify(options.body);

        // make request to SIB API
        const response = await fetch(`https://api.sender.net/v2/subscribers/${email}`, options)
        const result = await response.json()
        if (!response.ok) return Utility.Log("ERROR", result.message, result);

        done(result, options)
    },

    subscriberExists: async (email) => {
        // make request to SIB API
        const response = await fetch(`https://api.sender.net/v2/subscribers/${email}`, Email.GET().options)
        const result = await response.json()

        return result;
    }

};
