import { State } from "@jordan_langton/activejs-raspberry-core";
import { CheckIfUsersAreStored, GetUsersFromCache, StoreUsersInCache, ClearCache } from "../cache";
import { Users } from "../Firebase/Collections/Users";
import { Types } from "../Types";
import { Utility } from "../Utility";

const UserStore = State.createStore({
    allUsers: [],
    loggedInUser: null,
    tempUser: null,
    tempUsers: null,
});

/**
 * Makes sure the DB and the cache are always in sync
 */
 export const setupUsersDBSync = async () => {
    return new Promise((resolve) => {
        const unsubscribe = Users.initializeSync((querySnapshot) => {
            // temp products
            const temp = [];
            const cache_users = GetUsersFromCache();
    
            // get list of products and their actions
            querySnapshot.docChanges().forEach((change) => {
                // get the product data
                const DB_USER = {
                    type: change.type,
                    user: change.doc.data(),
                    action: null
                };
    
                // select the type of change that happened to the DB
                if (change.type === Types.TYPE_DB_ADDED) DB_USER.action = () => LoadNewUser(DB_USER.user);
                if (change.type === Types.TYPE_DB_MODIFIED) DB_USER.action = () => updateUserByKey(DB_USER.user.id);
                if (change.type === Types.TYPE_DB_REMOVED) DB_USER.action = () => DeleteUserById(DB_USER.user.id);
    
                // save product into temp
                temp.push(DB_USER);
            });
    
            // check for a count match
            const missing_orders = [];
            let cache_users_amount = (cache_users)? cache_users.length : 0;
            const db_users_amount = temp.length;
    
            // if the is a miss match the cache is out of date
            if (cache_users_amount !== db_users_amount) {
                // clear current cache
                ClearCache();
                
                // make sure to add document to the cache and state
                temp.forEach(db_item => db_item.action())
            }
            // changes have been made to document
            else temp.forEach(db_item => db_item.action())

            resolve();
        });
    })
};

/**
 * Adds orders to the list of orders then stores in cache
 */
 export const LoadUsers = async (log=false) => {
    // if orders are in cache store those in the state
    if ((CheckIfUsersAreStored())) {
        UserStore.Commit("allUsers", [...GetUsersFromCache()]);
    }
    else {
        // no orders in cache so we get from DB
        const users = await Users.getAll();
        UserStore.Commit("allUsers", [...users]);

        // also store these orders from DB in cache for later
        StoreUsersInCache(users);
    }
}

/**
 * Adds an order to the list of orders then stores in cache
 * @param {Object} order
 */
export const LoadNewUser = (user) => {
    const current = GetAllUsers();
    UserStore.Commit("allUsers", [user, ...current]);

    // Update the cache with the new order
    // StoreUsersInCache(GetAllUsers());
};

/**
 * Will get the logged in user out of state
 * @returns {Object}
 */
export const getLoggedInUserFromState = () => UserStore.Select("loggedInUser");

/**
 * Gets all the users from State
 * @returns {Object[]}
 */
export const GetAllUsers = () => UserStore.Select("allUsers");

/**
 * Will set the current logged in user in state
 * @param {Object} User
 * @returns {Object}
 */
export const saveLoggedInUserToState = (User) => {
    // setup logged in user
    UserStore.Commit("loggedInUser", User);
}

/**
 * Resets the current logged in user to null
 */
export const removeLoggedInUser = () => {
    UserStore.Commit("loggedInUser", null);
};

/**
 * Retrieves the temp user data
 * @returns {Object}
 */
export const getTempUser = () => UserStore.Select("tempUser");

/**
 * Retrieves the temp user data
 * @returns {Object}
 */
export const getTempUsers = () => UserStore.Select("tempUsers");

/**
 * Will store the temp users register credetials for when they needed
 * @param {Object} user
 */
export const setTempUser = (user) => {
    UserStore.Commit("tempUser", user);
};

/**
 * Will get the temp users register credetials for when they needed
 * @param {Object} users
 */
export const setTempUsers = (users) => {
    UserStore.Commit("tempUsers", users);
};

/**
 * Will reset the temp credetials
 */
export const restTempUser = () => {
    UserStore.Commit("tempUser", null);
};

/**
 * Will reset the temp credetials
 */
export const restTempUsers = () => {
    UserStore.Commit("tempUsers", null);
};

/**
 * Updates the users email in state
 * @param {String} email
 */
export const updateUserEmailInState = (email) => {
    return UserStore.Commit("loggedInUser.email", email);
};

/**
 * Will update the user in state based on a key value pair
 * @param {String} id
 * @param {Object} object
 */
export const updateUserByKey = async (id, object) => {
    let users = GetAllUsers();

    // get the index of the user
    const check = {index: null};
    for (let i = 0; i < users.length; i++) {
        if (users[i].id == id) check.index = i;
    }

    // if the user is found make the updates
    if (check.index !== null) {
        // Find user details to updated
        for (const key in object) {
            Utility.setNestedProperty(users[check.index], key, object[key]);
        }

        // Update the users in state
        UserStore.Commit('allUsers', users);

        // Update the users in CACHE
        // StoreUsersInCache(users);
    }
};

/**
 * Will update the logged in user  on a key value pair
 * @param {Object} object
 */
export const updateCurrentUserByKey = (object) => {
    for (const key in object) {
        console.log(`State (loggedInUser.${key}) was updated successfully`);
        return UserStore.Commit(`loggedInUser.${key}`, object[key]);
    }
};

/**
 * Will update the logged in user  on a key value pair
 * @param {Object} object
 */
export const DeleteUserById = (id, done) => {
    // get all users
    const all = GetAllUsers();

    // get the index of the user you want to delete
    let userIndex = null;
    all.forEach((user, i) => {if (user.id == id) userIndex = i});

    // delete the user from the list
    all.splice(userIndex, 1);

    // update state and CACHE
    UserStore.Commit('allUsers', all);
    // StoreUsersInCache(all);

    if (done) done()
};
