import { importFilters } from "./import-filters";


/**
 * Add import scripts to the array below to allow them
 * to run during the app start and allow for testing
 * @type {Array<Function>}
 */
export const loaded = [
    
];

/**
 * Called to start the process of running through
 * the loaded scripts
 */
export const run = () => loaded.forEach(async (action) => await action());