import { Types } from "../Types";
import { Users } from "./Collections/Users";
import { dummyData } from "../testing/dummyData";
import { Interface } from "../interfaces";
import { DB } from "./Firebase";
import { getDocs, collection, query, limit } from "firebase/firestore";
import { createWithEmailAndPassword } from "./Collections/authentication";
import { setTempUser, setTempUsers } from "../stores/usersState";
import { Popup } from "../Components/Popup";
import { Orders } from "./Collections/Orders";
import { Products } from "./Collections/products";
import { CreateUserAsAdmin } from "./Collections/admin";
import { Filters } from "./Collections/Filters";
export const dbBuilder = {

    /**
     * Will add to the users collection
     * @param {Number} length the amount of users you want to generate (max 18)
     * @param {Boolean} perge will delete all users and create new ones
     */
    addToUsersCollection: async (length, perge=false) => {
        // delete old data
        if (perge) await Users.deleteAllUsers();

        // run a check for users existing already
        let usersExist = false;
        const q = query(collection(DB, "users"), limit(1));
        const querySnap = await getDocs(q);
        querySnap.forEach(doc => {
            if (doc.data()) usersExist = true;
        });

        // if there are no users in DB add them
        if (!usersExist) {
            const users = [];
            const tempData = [];
            const usedEmails = [];
            const usersLength = (length > 18)? 18 : length;
            for (let i = 0; i < usersLength; i++) {
                const generateUser = () => {
                    const userTO = Interface.USER();
                    userTO.id = Math.random().toString(36).substr(2, 15);
                    userTO.firstname = dummyData.Firstnames[Math.floor(Math.random() * 18)];
                    userTO.lastname = dummyData.Lastnames[Math.floor(Math.random() * 18)];
                    userTO.email = dummyData.Emails[Math.floor(Math.random() * 8)];
                    userTO.mobileNumber = dummyData.Numbers[Math.floor(Math.random() * 9)];
                    userTO.password = Math.random().toString(36).substr(2, 15);
                    return userTO;
                };
                const saveUser = (userTO) => {
                    if (!usedEmails.includes(userTO.email)) {
                        tempData.push(userTO);
                        usedEmails.push(userTO.email);
                        users.push(new Promise((resolve, reject) => {
                            // create user
                            CreateUserAsAdmin(userTO, userTO, (user) => {
                                console.log('Created User', user);
                                resolve();
                            });
                        }))
                    }
                    else {
                        const userTO = generateUser();
                        saveUser(userTO);
                    }
                };

                const userTO = generateUser();
                saveUser(userTO);
            }

            // add admin users
            const admin = Interface.USER();
            admin.id = Math.random().toString(36).substr(2, 15);
            admin.firstname = "Dev";
            admin.lastname = "Account";
            admin.email = "dev@gmail.com";
            admin.mobileNumber = "0670014872";
            admin.role = Types.USER_ADMIN;
            admin.active = true;
            admin.avatar = "";
            admin.password = "Test1234";
            tempData.push(admin);
            usedEmails.push(admin.email);
            // store all the users temp credetials
            users.push(new Promise((resolve, reject) => {
                CreateUserAsAdmin(admin, admin, (user) => {
                    console.log('Created User', user);
                    resolve();
                });
            }));

            // setup the temp users
            setTempUsers(tempData);

            Promise.all(users).catch((err) => {
                Popup.New({
                    renderTo: "app",
                    type: Types.POPUP_ERROR,
                    id: "dummyUsersFailed",
                    header: "Dummy Data Users Creation Failed",
                    message: "The dummy users failed to be added",
                    other: null,
                    mainAction: {
                        id: 1,
                        text: "Dismiss",
                        action: () => Popup.Close()
                    },
                });
            });
        }
    },

    /**
     * Will add to the orders collection
     * @param {Boolean} perge will delete all users and create new ones
     */
    addOrdersCollection: async (perge=false) => {
        // delete old data
        if (perge) await Orders.deleteAllOrders();

        // run a check for users existing already
        let ordersExist = false;
        const q = query(collection(DB, "orders"), limit(1));
        const querySnap = await getDocs(q);
        querySnap.forEach(doc => {
            if (doc.data()) ordersExist = true;
        });

        if (!ordersExist) dummyData.HistoryOrders.forEach(order => {
            const generateUser = () => {
                const userTO = Interface.USER();
                userTO.id = Math.random().toString(36).substr(2, 15);
                userTO.firstname = dummyData.Firstnames[Math.floor(Math.random() * 18)];
                userTO.lastname = dummyData.Lastnames[Math.floor(Math.random() * 18)];
                userTO.email = dummyData.Emails[Math.floor(Math.random() * 8)];
                userTO.mobileNumber = dummyData.Numbers[Math.floor(Math.random() * 9)];
                userTO.password = Math.random().toString(36).substr(2, 15);
                return userTO;
            };
            order.user = generateUser();

            Orders.Create({order, log: false});
        });
    },

    /**
     * Will add to the products collection
     * @param {Boolean} perge will delete all products and create new ones
     */
     addProductsCollection: async (perge=false) => {
        // delete old data
        if (perge) await Products.deleteAllProducts();

        // run a check for users existing already
        let productsExist = false;
        const q = query(collection(DB, "products"), limit(1));
        const querySnap = await getDocs(q);
        querySnap.forEach(doc => {
            if (doc.data()) productsExist = true;
        });

        if (!productsExist) [...dummyData.Biltong, ...dummyData.Snacks].forEach(product => Products.Create({product, log: false}));
    },

    /**
     * Will add to the filters collection
     * @param {Boolean} perge will delete all filters and create new ones
     */
     addFiltersCollection: async (perge=false) => {
        // delete old data
        if (perge) await Filters.deleteAllFilters();

        // run a check for users existing already
        let filtersExist = false;
        const q = query(collection(DB, "filters"), limit(1));
        const querySnap = await getDocs(q);
        querySnap.forEach(doc => {
            if (doc.data()) filtersExist = true;
        });

        if (!filtersExist) dummyData.Filters.forEach(filter => Filters.Create({filter, log: false}));
    },

};
