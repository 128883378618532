import { Cart } from "./Cart";
import { Config } from "./Config";
import { Types } from "./Types";
import { getLoggedInUserFromState } from "./stores/usersState";
import { httpsCallable } from "firebase/functions";
import { FUNCTIONS } from "./Firebase/Firebase";


export const initializePayment = (mountId) => {
    // Replace the supplied `publicKey` with your own.
    // Ensure that in production you use a production public_key.
    const sdk = new window.YocoSDK({
        publicKey: (Config.ENVIRONMENT === Types.DEVELOPMENT)? Config.YOCO_PUBLIC_TEST : Config.YOCO_PUBLIC_LIVE
    });

    // Create a new dropin form instance
    const userDetails = getLoggedInUserFromState();
    const inline = sdk.inline({
        layout: 'basic',
        amountInCents: Cart.getTotalInCents(),
        currency: 'ZAR',
        showErrors: true,
        showSubmitButton: false,
        customer: {
            firstname: userDetails.firstname,
            lastname: userDetails.lastname,
            email: userDetails.email,
            phone: userDetails.mobileNumber,
        }
    });

    // this ID matches the id of the element we created earlier.
    inline.mount(mountId);

    // Create a token for the user
    const button = document.querySelector('#pay-button');
    button.onclick = (e) => {
        e.preventDefault();
        console.log("ENVIRONMENT", Config.ENVIRONMENT);
        inline.createToken().then((result) => {
            if (result.error) {
                const errorMessage = result.error.message;
                return console.error(errorMessage);
            }

            MakePayment({
                token: result.id,
                amountInCents: Cart.getTotalInCents(),
                currency: 'ZAR',
                secret: (Config.ENVIRONMENT === Types.DEVELOPMENT)? Config.YOCO_SECRET_TEST : Config.YOCO_SECRET_LIVE
            }, (response) => {
                console.log(response);
                debugger;
            })
        }).catch((error) => {
            debugger;
            // Re-enable button now that request is complete
            console.error(error);
        });
    }
};

/**
 * 
 * @param {{ amountInCents: Number, currency: String, customer: { email: String, phone: String, firstname: String, lastname: String } }} options 
 * @param { Function } done
 */
export const initializeYokoPopup = (options, done) => {
    // initialize the YOKO SDK
    const KEY = (Config.ENVIRONMENT === Types.DEVELOPMENT)? Config.YOCO_PUBLIC_TEST : Config.YOCO_PUBLIC_LIVE;
    const Yoco = new window.YocoSDK({
        publicKey: "pk_test_ed3c54a6gOol69qa7f45",
    });

    Yoco.showPopup({
        amountInCents: options.amountInCents,
        currency: options.currency,
        name: 'Lions Den Biltong Online',
        description: 'The 1 stop shop for all your biltong & snacks needs',
        customer: {
            email: options.customer.email,
            phone: options.customer.phone,
            firstname: options.customer.firstname,
            lastname: options.customer.lastname,
        },
        callback: async (result) => {
            // This function returns a token that your server can use to capture a payment
            if (result.error) {
                const errorMessage = result.error.message;
                alert("error occured: " + errorMessage);
                return;
            } 

            // build up options
            const SECRET = (Config.ENVIRONMENT === Types.DEVELOPMENT)? Config.YOCO_SECRET_TEST : Config.YOCO_SECRET_LIVE;
            const options = {
                token: result.id,
                amountInCents: Cart.getTotalInCents(),
                currency: 'ZAR',
                secret: "sk_test_960bfde0VBrLlpK098e4ffeb53e1"
            };

            // get the callable function and make request
            const response = await fetch(Config.MAKE_YOCO_PAYMENT, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(options)
            });

            const data = await response.json();
            console.log(data);
            debugger;
        }
    })
};
