// Import the Raspberry API
import { Router, Load } from "@jordan_langton/activejs-raspberry-core";

// components
import {topNavBarDash} from "./Components/topNavBarDash"
import {topNavBarStore} from "./Components/topNavBarStore"
import {sideNavBarDash} from "./Components/sideNavBarDash"
import {userList} from "./Components/userList"
import {productGrid} from "./Components/productGrid"
import {productCategories} from "./Components/productCategories"
import {productFilters} from "./Components/productFilters"
import {Popup} from "./Components/Popup"
import {productList} from "./Components/productList"
import {Types} from "./Types"
import {Config} from "./Config"
import {Interface} from "./interfaces"
import {Spinner} from "./Spinner"
import {dummyData} from "./testing/dummyData";
import {Cart} from "./Cart";
import {cartOverlay} from "./Components/cartOverlay";
import {Banner} from "./Components/banner";
import {ordersHistoryList} from "./Components/ordersHistoryList";
import {FilterList} from "./Components/filters";
import { Email } from "./Email";

// custom libraries
import {DB, AUTH, TIME_STAMP, PERF, ANALYTICS, LOG_EVENT} from "./Firebase/Firebase"
import { Users } from "./Firebase/Collections/Users"
import { Orders } from "./Firebase/Collections/Orders"
import { Products } from "./Firebase/Collections/Products"
import { Filters } from "./Firebase/Collections/Filters"
import * as globalState from "./stores/globalState"
import { initializePayment, initializeYokoPopup } from "./Payment"
import * as authentication from "./Firebase/Collections/authentication"
import * as usersState from "./stores/usersState"
import * as ordersState from "./stores/ordersState"
import * as productState from "./stores/productState"
import * as promoState from "./stores/promoState"
import * as CACHE from "./cache"
import { CheckIfOrdersAreStored } from "./cache";
import { CreateUserAsAdmin } from "./Firebase/Collections/admin";
import { loaded, run } from "../../scripts/loaded-scripts";
import { textContent } from "./textContent";
import { Promos } from "./Firebase/Collections/promos";


export const Utility = {

    /**
     * Loads all the custom libraries and components into ActiveJS
     */
    LoadLibs: () => {
        // load libraries
        Load("Config", Config);
        Load("Types", Types);
        Load("dummyData", dummyData);
        Load("Users", Users);
        Load("Orders", Orders);
        Load("Products", Products);
        Load("Filters", Filters);
        Load("Promos", Promos);
        Load("DB", DB);
        Load("AUTH", AUTH);
        Load("TIME_STAMP", TIME_STAMP);
        Load("PERF", PERF);
        Load("ANALYTICS", {data: ANALYTICS, LOG_EVENT});
        Load("ANALYTICS", ANALYTICS);
        Load("Authentication", authentication);
        Load("userStore", usersState);
        Load("ordersStore", ordersState);
        Load("productsStore", productState);
        Load("promoStore", promoState);
        Load("globalState", globalState);
        Load("Payment", { initializePayment, initializeYokoPopup });
        Load("Utility", Utility);
        Load("Admin", { CreateUserAsAdmin });
        Load("textContent", textContent);
        Load("Email", Email);

        // load components
        Load("topNavBarDash", topNavBarDash);
        Load("topNavBarStore", topNavBarStore);
        Load("sideNavBarDash", sideNavBarDash);
        Load("userList", userList);
        Load("productGrid", productGrid);
        Load("productCategories", productCategories);
        Load("productFilters", productFilters);
        Load("Popup", Popup);
        Load("Interface", Interface);
        Load("Spinner", Spinner);
        Load("productList", productList);
        Load("Cart", Cart);
        Load("cartOverlay", cartOverlay);
        Load("Banner", Banner);
        Load("ordersHistoryList", ordersHistoryList);
        Load("FilterList", FilterList);
        Load("Quill", Quill);
    },

    /**
     * Checks if you are viewing the site on a mobile device
     * @returns "desktop" | "mobile" | "tablet"
     */
    device: () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            return "mobile";
        }
        return "desktop";
    },

    /**
     * Will check if there are any scripts loaded
     * and run them if there are any
     */
    checkForLoadedScripts: () => {
        if (loaded.length !== 0) run();
    },

    /**
     * Will create an object based off of an array of params
     * @param {Array} RAW_PARAMS
     * @returns {Object}
     */
    generateUrlParams: (RAW_PARAMS) => {
        let dataForRoute = {};
		if (RAW_PARAMS[0] !== "" && RAW_PARAMS[0] !== "undefined") {
			RAW_PARAMS.forEach((param, index) => {
				let paramKey = param.split("=")[0];
				let paramData = param.split("=")[1];
				if (paramData.includes("true") || paramData.includes("false")) {
					if (paramData.includes("true")) {
						dataForRoute[paramKey] = Boolean(paramData);
					} else {
						dataForRoute[paramKey] = Boolean(!paramData);
					}
				} else {
					if (isNaN(Number(paramData))) {
						dataForRoute[paramKey] = paramData;
					} else if (!isNaN(Number(paramData))) {
						dataForRoute[paramKey] = Number(paramData);
					} else {
						dataForRoute[paramKey] = paramData;
					}
				}
			});
		}

		return dataForRoute;
    },

    /**
     * Gets the url requested
     * @returns {String}
     */
    getCurrentURL: () => {
        const RAW = window.location.hash.split("?")[0];
	    return RAW.replace(/#/g, '');
    },

    /**
     * Will get a list of the requested params in the url
     * @param { Array | false } toAdd
     * @returns {[String]}
     */
    getCurrentParams: (toAdd=false) => {
        const urlParams = window.location.hash.split("?")[1];
        const params = decodeURIComponent(urlParams).replace("?", "").split(",");
        if (toAdd) params.push(...toAdd);
	    return params;
    },

    initializeBeforeEachRoute: () => {
        Router.beforeEach((to, from, done) => {
            // hide cart before every route
            cartOverlay.showing = false;
            const user = usersState.getLoggedInUserFromState();

            const finish = () => {
                const isMobile = (Utility.device() === Types.DEVICE_TYPE_MOBILE || Utility.device() === Types.DEVICE_TYPE_TABLET);
                if (to.path == "/authentication" && isMobile) return done({error: true, path: '/mobile/authentication', params: to.params})
                if (to.path == "/storeFront" && isMobile) return done({error: true, path: '/mobile/storeFront', params: to.params})
                if (to.path == "/products" && isMobile) return done({error: true, path: '/mobile/products', params: to.params})
                if (to.path == "/profile" && isMobile) return done({error: true, path: '/mobile/profile', params: to.params})
                if (to.path == "/orderHistory" && isMobile) return done({error: true, path: '/mobile/orderHistory', params: to.params})
                if (to.path == "/product" && isMobile) return done({error: true, path: '/mobile/product', params: to.params})
                if (to.path == "/payment" && isMobile) return done({error: true, path: '/mobile/payment', params: to.params})

                // proceed to route
                done();

                // track page views on FB
                fbq('track', 'ViewContent',
                    {
                        content_ids: ['PAGE_VIEW'],
                        eventref: 'fb_oea' // or set to empty string
                    }
                );
            };

            // if you are trying to access an admin page
            if (to.meta.requiresAdmin) {
                if (user.role == Types.USER_ADMIN) finish();
                else {
                    done({error: true, path: '/authentication'});
                    Popup.New({
                        renderTo: "productsPopup",
                        type: Types.POPUP_ERROR,
                        id: "permission-denied",
                        header: "Permission Denied",
                        message: `You are trying to access a page only ment for Admins of the site. Please sign in with an Admin account to access (${to.path})`,
                        other: null,
                        mainAction: {
                            id: 1,
                            text: "Dismiss",
                            action: () => {
                                Popup.Close();
                                signUserOut();
                            }
                        },
                    });
                }
            }
            else {
                // proceed to route
                finish();
            }
        });
    },

    /**
     * Gets the Dashboard top nav items
     * @returns Array
     */
    getTopNavItems: () => {
        return [
            {id: 1, icon: '', label: 'Profile', active: false, action: () => Router.route('/profile')},
            {id: 2, icon: '', label: 'Back to site', active: false, action: () => Router.route('/storeFront')},
        ];
    },

    /**
     * Gets the Dashboard side nav items
     * @param {{label: String}} activeKey
     * @returns Array
     */
    getSideNavItems: (activeKey) => {
        // Will add a spinner to the button you clicked before doing the action
        const navAction = (id, done) => {
            Spinner.Button("sideNavBarItem-"+id);
            if (done != undefined) done();
        };

        const items = [
            {id: 1, icon: '<i class="fas fa-tachometer-alt"></i>', label: 'Dashboard', active: false, action: () => navAction(1, () => Router.route('/admin/dashboard'))},
            // {id: 2, icon: '<i class="far fa-chart-bar"></i>', label: 'Analytics', active: false, action: () => {}},
            // {id: 3, icon: '<i class="fas fa-ad"></i>', label: 'Campaigns', active: false, action: () => {}},
            {id: 4, icon: '<i class="fas fa-truck-loading"></i>', label: 'My Orders', active: false, action: () => navAction(4, () => Router.route('/admin/orders'))},
            {id: 5, icon: '<i class="fas fa-boxes"></i>', label: 'Products', active: false, action: () => navAction(5, () => Router.route('/admin/products'))},
            {id: 6, icon: '<i class="fas fa-users"></i>', label: 'Users', active: false, action: () => navAction(6, () => Router.route('/admin/users'))},
            {id: 7, icon: '<i class="fas fa-cogs"></i>', label: 'Settings', active: false, action: () => navAction(7)},
        ];

        items.forEach(item => {if (item.label == activeKey.label) item.active = true});
        return items;
    },

    showContactUsInfo: () => {
        const message = `
        <div class="content d-flex flex-col">
            <h4>Details</h4>
            <div><b>Shaun</b>: 072 576 9111</div>
            <div><b>Admin & Orders</b>: Office - 031 762 1258</div>
            <div><b>Admin & Orders</b>: Cell - 081 576 9111</div>
        </div>`;

        Popup.New({
            renderTo: "app",
            type: Types.POPUP_INFO,
            id: "info-contact-us",
            header: 'Contact Us Information',
            message,
            other: null,
            mainAction: {
                id: 'open-whatsapp',
                text: "WhatsApp",
                style: "background-color: var(--success); color: var(--white); border-color: var(--success);",
                action: () => {
                    Popup.Close();
                    window.open(Config.WHATSAPP_URL, '_blank');fbq('track', 'Contact');
                }
            },
            secondary: {
                id: 'close-contact-us',
                text: "Dismiss",
                action: () => Popup.Close()
            }
        });
    },

    generateNavBarItems: (cartOverLay, activePage) => {
        const contactUs = () => Utility.showContactUsInfo();
        const openWhatsapp = () => {window.open(Config.WHATSAPP_URL, '_blank');fbq('track', 'Contact')};
        const currentUser = authentication.isUserSignedIn();
        const navAction = (id, done) => {
            Spinner.Button(`navBarItem-${id}-mobile`);
            done();
        };

        const navBarItems = [
            {id: 'orders', icon: "<i class=\"fas fa-truck-loading\"></i>" , label: 'Orders', active: false, action: () => navAction('orders', () => Router.route('/orderHistory'))},
            {id: 'contact', icon: "<i class=\"fas fa-phone\"></i>" , label: 'Contact Us', active: false, action: () => contactUs()},
            {id: 'whatsapp', icon: "<i class=\"fab fa-whatsapp\"></i>" , label: 'Whatsapp', active: false, action: () => openWhatsapp()},
        ];

        // add the mobile nav items
        if (Utility.device() !== Types.DEVICE_TYPE_DESKTOP) {
            [
                {id: 'biltong', icon: '<i class="fas fa-bacon"></i>', label: 'Biltong', active: false, action: () => navAction('biltong', () => Router.route('/products', {categoryId: Types.CATEGORY_BILTONG.id}))},
                {id: 'snacks', icon: '<i class="fas fa-cookie-bite"></i>', label: 'Snacks', active: false, action: () => navAction('snacks', () => Router.route('/products', {categoryId: Types.CATEGORY_SNACKS.id}))},
                {id: 'order-history', icon: '<i class="fas fa-truck-loading"></i>', label: 'Order History', active: false, action: () => navAction('order-history', () => Router.route('/orderHistory'))},
                {id: 'tsAndCs', icon: '<i class="fa-solid fa-handshake"></i>', label: 'Ts & cs', active: false, action: () => navAction('tsAndCs', () => Router.route('/termsOfService'))},
            ].forEach(item => navBarItems.push(item))
        }

        // if you are logged in show logged in items else just show sign in
        if (currentUser.signedIn) {
            // add sign out item
            const signout = {id: 'logout', icon: "<i class=\"fas fa-sign-out-alt\"></i>" , label: 'Logout', active: false, highlight: false, action: () => authentication.signUserOut()};
            if (!cartOverLay) navBarItems.unshift({id: 'profile', icon: "<i class='fas fa-user-circle'></i>" , label: 'Profile', active: false, action: () => {
                Spinner.Button("navBarItem-profile", "var(--primary)");
                Router.route('/profile');
            }});

            // admin items
            if (currentUser.details.role === Types.USER_ADMIN) {
                navBarItems.push(signout);
                if (cartOverLay) navBarItems.unshift({id: 3, icon: "<i class=\"fas fa-shopping-cart\"></i>" , label: 'Cart', active: false, highlight: false, action: () => cartOverLay.toggle()});
                const dashboard = {
                    id: 'dashboard',
                    icon: "<i class=\"fas fa-tachometer-alt\"></i>",
                    label: 'Dashboard',
                    active: false,
                    highlight: true,
                    action: () => {
                        Spinner.Button("navBarItem-dashboard");
                        Router.route('/admin/dashboard');
                    }
                };
                if (Utility.device() === Types.DEVICE_TYPE_DESKTOP) navBarItems.push(dashboard);
                else navBarItems.unshift(dashboard);
            }
            else {
                signout.highlight = true;
                if (cartOverLay && Utility.device() === Types.DEVICE_TYPE_DESKTOP) navBarItems.push({id: 'cart', icon: "<i class=\"fas fa-shopping-cart\"></i>" , label: 'Cart', active: false, highlight: false, action: () => cartOverLay.toggle()});
                navBarItems.push(signout);
            }

        }
        else {
            navBarItems.push({id: 'cart', icon: "<i class=\"fas fa-shopping-cart\"></i>" , label: 'Cart', active: false, highlight: false, action: () => cartOverLay.toggle()});
            navBarItems.push({id: 'sign-in', icon: "<i class=\"fas fa-sign-in-alt\"></i>" , label: 'Sign In', active: false, highlight: true, action: () => Router.route('/authentication')});
        }

        // select the right page as active
        navBarItems.forEach(item => {if (item.id == activePage) item.active = true;})
        return navBarItems;
    },

    /**
     * Will display a loader for the site
     * @param {Function} callback
     */
    showLoadingPage: (callback) => {
        const html = `
        <div class="loadingWrapper d-flex flex-center flex-align">
            <div class="loadingBubble d-flex flex-col flex-align">
                <div class="logo" style="background-image: url('./src/assets/images/logos/logo.png');"></div>
                <h1>Welcome to Lions Den Biltong!</h1>
                <h2>Please wait while we load the site for you</h2>
                <div class="loaderWrapper">
                    <img src="./src/assets/gifs/loader.gif" alt="">
                </div>
            </div>
        </div>`;

        const el = document.getElementById("app");
        if (el) el.innerHTML = html;
        if (callback) callback();
    },

    /**
     * Will take in a file from an input and convert it to base64
     * @param {*} file
     * @returns {String}
     */
    convertFileToBase64: (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    }),

    setCookie: (cookieName, cookieValue) => {
        const today = new Date();
        const expire = new Date();
        expire.setTime(today.getTime() + 3600000*24*14);
        document.cookie = cookieName+"="+JSON.stringify(cookieValue) + ";expires="+expire.toGMTString();
    },

    getCookie: (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    },

    checkForCookie: (name) => {
        let username = Utility.getCookie(name);
        if (username != "") {
        alert("Welcome again " + username);
        } else {
            username = prompt("Please enter your name:", "");
            if (username != "" && username != null) {
            setCookie("username", username, 365);
            }
        }
    },

    /**
     * Will generate a random string at a length you give
     * @param {Number} length
     * @param {String} CASE
     * @returns {string}
     */
    getRandomString: (length, CASE) => {
        let result = '';
        let characters = '';
        if (CASE != false) {
            switch (CASE) {
                case "UPPERCASE":
                    characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
                    break;
                case "LOWERCASE":
                    characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
                    break;
                default:
                    characters = 'aAbBcCdDeEfFgGhHiIjJkKlLmMnNoOpPqQrRsStTuUvVwWxXyYzZ0123456789';
                    break;
            }
        }
        else {
            characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        }

        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    },

    /**
     *
     * @param VM
     * @param bindingString
     * @returns {*}
     */
    getNestedProperty: (VM, bindingString) => {

        let schema = VM;  // a moving reference to internal objects within obj
        let pList = bindingString.replace(' ', '').split('.');
        let len = pList.length;
        for (let i = 0; i < len - 1; i++) {
            let elem = pList[i];
            // if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }

        if (schema[pList[len - 1]] !== undefined) {
            return schema[pList[len - 1]];
        }
        else {
            return schema[pList[len - 1]];
        }
    },

    /**
     * Sets a nested property in the VM
     * @param {Object} VM
     * @param {String} bindingString
     * @param {String} updateValue
     */
    setNestedProperty: (VM, bindingString, updateValue) => {
        let schema = VM;  // a moving reference to internal objects within obj
        let pList = bindingString.split('.');
        let len = pList.length;
        for (let i = 0; i < len - 1; i++) {
            let elem = pList[i];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }

        const value = schema[pList[len - 1]];

        if (schema[pList[len - 1]] !== undefined) {
            schema[pList[len - 1]] = updateValue;
        }
    },

    /**
     * Clears out the cache and replaces it with the latest DB data
     * @param { Boolean } clearNow
     * @param { Boolean } log
     */
    autoRefreshCacheWithDB : async (clearNow=false, log=false) => {
        const clear = async () => {
            // clear all cached data
            CACHE.ClearCache();

            // load all users into cache
            await usersState.LoadUsers()

            // load all orders into cache
            await ordersState.LoadOrders()

            // load all products into cache
            await productState.LoadProducts()

            // load all filters into cache
            await productState.LoadFilters()

            // Log the output
            if (log) Popup.LOG({type: Types.POPUP_SUCCESS, header: "Cache Refreshed", message: "The cache has been loaded with the latest DB data"})
        };

        // choose to clear cache immediately or in a minute
        if (!clearNow) setInterval(clear, Config.AUTO_REFRESH_TIME);
        else await clear();
    },

    /**
     * Removes unneeded chars from the env variables
     * @param { String } _var
     * @returns {*}
     * @constructor
     */
    ENV_VALUE: (_var) => {
        if (_var) return _var.replace(/'/g, "").replace(/,/g, "");
        return;
    },

    objectsEqual: (o1, o2) => {
        return typeof o1 === 'object' && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length
            && Object.keys(o1).every(p => Utility.objectsEqual(o1[p], o2[p]))
        : o1 === o2;
    },

    /**
     *
     * @param { Object<{ type: String, message: String }> } options
     */
    Log: (type, message, data) => {
        const user = usersState.getLoggedInUserFromState();
        if (user && user.role == Types.USER_ADMIN) {
            switch (type) {
                case "SUCCESS":
                    console.groupCollapsed(`%c ${message} `, "background-color: #75A602; color: #ffffff");
                    break;
                case "WARNING":
                    console.groupCollapsed(`%c ${message} `, "background-color: #E3E262; color: #000000");
                    break;
                case "ERROR":
                    console.groupCollapsed(`%c ${message} `, "background-color: #e71d1d; color: #ffffff");
                    break;
                case "INFO":
                    console.groupCollapsed(`%c ${message} `, "background-color: #3374B5; color: #ffffff");
                    break;
            }
            console.log(data);
            console.groupEnd();
        }
    },
};
