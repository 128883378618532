import { Cart } from "../Cart";

export const productGrid = {

    /**
     * Will create a product list and render it for you
     * @param {{
     *      renderEl: String,
     *      products: Object[],
     *      actions: {
     *          viewProduct: Function,
     *          addToCart: Function,
     *          add: Function,
     *          remove: Function,
     *          like: Function,
     *          acceptCustom: Function,
     *          removeCustom: Function,
     *      }
     * }} options
     */
    Create: (options) => {
        const Products = productGrid.buildHTML({products: options.products});

        productGrid.Render({renderEl: options.renderEl, html: Products}, () => {
            // add events to incrementors
            options.products.forEach(product => {

                const addToCartButton = document.querySelector(`#addToCartButton-${product.id}`);
                const unlikedButton = document.querySelector(`#unliked-${product.id}`);
                const likedButton = document.querySelector(`#liked-${product.id}`);
                const incrementorWrapper = document.querySelector(`#incrementorWrapper-${product.id}`);
                const incrementorMinusButton = document.querySelector(`#incrementor-minus-${product.id}`);
                const incrementorPlusButton = document.querySelector(`#incrementor-plus-${product.id}`);
                const weightedAddButton = document.querySelector(`#acceptCustomButton-${product.id}`);
                const removeCustomButton = document.querySelector(`#removeCustomButton-${product.id}`);
                const productContent = document.querySelector(`#productContent-${product.id}`);

                // add event to (addToCartButton)
                if (addToCartButton) addToCartButton.onclick = () => {
                    // call method
                    options.actions.addToCart(product);

                    // Show incrementor elements
                    addToCartButton.classList.add("d-none");
                    incrementorWrapper.classList.remove("d-none");
                }

                // add event to (incrementorMinusButton)
                if (incrementorMinusButton) incrementorMinusButton.onclick = () => options.actions.remove(product);

                // add event to (incrementorPlusButton)
                if (incrementorPlusButton) incrementorPlusButton.onclick = () => options.actions.add(product);

                // add event to (unlikedButton)
                if (unlikedButton) {
                    unlikedButton.onclick = () => {
                        // call like method
                        options.actions.like(product);

                        // update element
                        unlikedButton.classList.add("d-none");
                        likedButton.classList.remove("d-none");
                    }
                }

                // add event to (weightedAddButton)
                if (weightedAddButton) weightedAddButton.onclick = () => {
                    const weightedOverView = document.getElementById(`weightedOverView-${product.id}`);
                    const weightedOverViewValue = document.getElementById(`weightedOverViewValue-${product.id}`);
                    const weightedInputWrapper = document.getElementById(`weightedInputWrapper-${product.id}`);
                    const weightedAmountAdded = document.getElementById(`weightedAmountAdded-${product.id}`);
                    const customWeightedValue = document.getElementById(`customWeightedValue-${product.id}`);

                    // call custom function
                    options.actions.acceptCustom(product, customWeightedValue.value);

                    // show the weighted overview
                    weightedOverView.classList.remove("d-none");
                    weightedInputWrapper.classList.add("d-none");

                    // show remove button
                    removeCustomButton.classList.remove("d-none");
                    removeCustomButton.classList.add("d-flex");

                    // hide accept button
                    weightedAddButton.classList.remove("d-flex");
                    weightedAddButton.classList.add("d-none");

                    // show the weighted overview
                    weightedAmountAdded.innerHTML = `Added: R${Number(customWeightedValue.value).toFixed(2)}`;
                    weightedAmountAdded.classList.remove("d-none");

                    // add the custom value to the overview
                    weightedOverViewValue.innerHTML = Number(customWeightedValue.value).toFixed(2);
                };

                // add event to (removeCustomButton)
                if (removeCustomButton) removeCustomButton.onclick = () => {
                    const weightedOverView = document.getElementById(`weightedOverView-${product.id}`);
                    const weightedOverViewValue = document.getElementById(`weightedOverViewValue-${product.id}`);
                    const weightedInputWrapper = document.getElementById(`weightedInputWrapper-${product.id}`);
                    const weightedAmountAdded = document.getElementById(`weightedAmountAdded-${product.id}`);
                    const customWeightedValue = document.getElementById(`customWeightedValue-${product.id}`);

                    // call custom function
                    options.actions.removeCustom(product, customWeightedValue.value);

                    // hide the weighted overview
                    weightedInputWrapper.classList.remove("d-none");
                    weightedInputWrapper.classList.add("d-flex");
                    weightedOverView.classList.add("d-none");

                    // hide remove button
                    removeCustomButton.classList.add("d-none");
                    removeCustomButton.classList.remove("d-flex");

                    // show accept button
                    weightedAddButton.classList.add("d-flex");
                    weightedAddButton.classList.remove("d-none");

                    // hide the weighted overview
                    weightedAmountAdded.innerHTML = "";
                    weightedAmountAdded.classList.add("d-none");
                };

                // add event to (productContent)
                if (productContent) productContent.onclick = () => options.actions.viewProduct(product);

            });
        });
    },

    /**
     * Generates the products HTML
     * @param {{products: Object[]}} options
     * @returns String
     */
    buildHTML: (options) => {
        let products = "";
        options.products.forEach(product => {

            // generate the bubbles
            const bubbles = productGrid.generateBubbles(product.tags);

            // generate the price for grid item
            const defaultPrice = (!product.weighted)? "R"+product.price : product.pricePerKG+"/kg";
            const promotionPrice = (!product.weighted)? "R"+product.promoPrice : product.promoPrice+"/kg";
            const price = (!product.onPromotion)? defaultPrice : promotionPrice;

            // check if product is on promotion
            const promotion = (product.onPromotion)? `
            <div class="promotion d-flex flex-align flex-between">
                <i class="fa-solid fa-bookmark"></i>
                <p> Was <b>${defaultPrice}</b> Now <b>${promotionPrice}</b>!</p>
            </div>` : "";

            // check if product in cart
            const check = Cart.itemInCart(product.id);

            // choose to show add to cart button
            const showAddToCart = (check.inCart)? "d-none" : "";

            // choose to show incrementor
            const showIncrementor = (check.inCart)? "" : "d-none";

            // choose to show qty counter overview
            const showQtyOverview = (check.inCart && !product.weighted)? "" : "d-none";

            // choose to show the weighted overview
            const showWeightedOverview = (check.inCart && product.weighted)? "" : "d-none";

            // check if product is a weighted item or not
            let addToCart = "";
            let incrementor = "";
            let weighted = "";
            if (product.weighted) {
                const showInputWrapper = (check.inCart)? 'd-none' : 'd-flex';
                const showAcceptButton = (check.inCart)? 'd-none' : 'd-flex';
                const showAmountAdded = (check.inCart)? 'd-flex' : 'd-none';
                const showRemoveButton = (check.inCart)? 'd-flex' : 'd-none';
                const amountAddedValue = (check.inCart)? `Added: R${Number(check.item.weightedAmount).toFixed(2)}` : "";

                weighted = `
                <div class="weightedWrapper d-flex flex-align flex-between">
                    <div id="weightedInputWrapper-${product.id}" class="inputWrapper ${showInputWrapper} flex-align">
                        <div class="currency">R</div>
                        <input type="number" id="customWeightedValue-${product.id}" placeholder="Custom Value">
                    </div>
                    <div id="weightedAmountAdded-${product.id}" class="customValueAdded ${showAmountAdded}">${amountAddedValue}</div>
                    <button id="acceptCustomButton-${product.id}" class="${showAcceptButton} flex-align flex-center"><i class="fas fa-check-circle"></i></button>
                    <button id="removeCustomButton-${product.id}" class="${showRemoveButton} flex-align flex-center"><i class="fas fa-trash-alt"></i></button>
                </div>`;
            }
            else {
                incrementor = `
                <div id="incrementorWrapper-${product.id}" class="incrementorWrapper ${showIncrementor} d-flex flex-align flex-even">
                    <button id="incrementor-minus-${product.id}"><i class="fas fa-minus"></i></button>
                    <div class="incrementorValue productQty-${product.id}">${(check.inCart)? check.item.qty : ""}</div>
                    <button id="incrementor-plus-${product.id}"><i class="fas fa-plus"></i></button>
                </div>`;

                addToCart = `<button id="addToCartButton-${product.id}" class="addToCartButton ${showAddToCart}">Add To Cart</button>`;
            }


            products += `
            <div id="gridProduct-${product.id}" class="productWrapper d-flex flex-col flex-grow">
                <!-- Promotion Bubble -->
                ${promotion}
                
                <!-- Product Image -->
                <div class="productImage" style="background-image: url('${product.image}')"></div>
                
                <!-- Product Overview Content -->
                <div class="productOverviewWrapper d-flex flex-align flex-between">
                    <div id="productContent-${product.id}" class="contentWrapper">
                        <h3>${product.label}</h3>
                        <p>${price}</p>
                    </div>
                    <div id="productQtyOverView-${product.id}" class="currentQty productQty-${product.id} ${showQtyOverview}">
                        ${(check.inCart)? check.item.qty : ""}
                    </div>
                    <div id="weightedOverView-${product.id}" class="weightedOverview d-flex flex-align productQty-${product.id} ${showWeightedOverview}">
                        <div>R</div>
                        <div id="weightedOverViewValue-${product.id}">${(check.inCart)? Number(check.item.weightedAmount).toFixed(2) : ""}</div>
                    </div>
                </div>
                
                <!-- Product Hover Section -->
                <div class="topProductContent d-flex flex-col">
                    <!-- Promo Section -->
                    <div class="promoSection d-flex flex-between flex-align">
                        <div class="bubblesWrapper d-flex flex-align flex-wrap">${bubbles}</div>
                        <div class="promotionsWrapper">${promotion}</div>
                    </div>
                    
                    <!-- Actions Section -->
                    <div class="actions d-flex flex-align flex-center">
                        
                        <!-- Incrementor Section -->
                        ${incrementor}

                        <!-- Weighted Section -->
                        ${weighted}

                        <!-- Add To Cart Button -->
                        ${addToCart}

                        <!-- Likes Section -->
                        <div class="likesWrapper d-flex flex-align flex-even">
                            <button id="unliked-${product.id}"><i class="far fa-thumbs-up"></i></button>
                            <button id="liked-${product.id}" class="liked d-none"><i class="fas fa-thumbs-up"></i></button>
                        </div>

                    </div>
                    
                    <div class="spacer"></div>
                    
                    <!-- Content Section -->
                    <!--<div class="content d-flex flex-col">
                        <h3>${product.label}</h3>
                        <p>R${product.price}</p>
                    </div>-->
                </div>
            </div>`;
        });

        return products;
    },

    /**
     * Renders the products grid
     * @param {{renderEl: String, html: String}} options
     * @param {Function} callback
     * @constructor
     */
    Render: (options, callback) => {
        const renderEl = document.getElementById(options.renderEl);
        if (renderEl) {
            renderEl.innerHTML = options.html;
            callback();
        }
    },

    /**
     * Generates tag bubbles for the products
     * @param {String[]} tags
     */
    generateBubbles: (tags=[]) => {
        let bubbles = "";
        tags.filter(_tag => bubbles += `<div class="bubble">${_tag}</div>`);

        return bubbles;
    },
};
