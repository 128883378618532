import { Types } from "../Types";
import { Interface } from "../interfaces";

export const dummyData = {
    /**
     * List of products
     */
    Biltong: [
        {id: null, categoryId: 2, filterId: 'hs739erne6', image: 'src/assets/images/products/Chilli%20Slabs3.JPG', label: 'Chilli Slabs', price: '10.00', promoPrice: '', tags: ['Top Seller'], weighted: true, pricePerKG: 300, active: true, onPromotion: false},
        {id: null, categoryId: 2, filterId: 'hs739erne6', image: 'src/assets/images/products/chilli%205.jpeg', label: 'Chilli Sliced', price: '10.00', promoPrice: '', tags: ['Favourite'], weighted: true, pricePerKG: 300, active: true, onPromotion: false},
        {id: null, categoryId: 2, filterId: 'hs739erne6', image: 'src/assets/images/products/beef chips 2.jpg', label: 'Beef Sliced', price: '10.00', promoPrice: '', tags: ['Favourite'], weighted: true, pricePerKG: 300, active: true, onPromotion: false},
        {id: null, categoryId: 2, filterId: 'mncoshjwhj', image: 'src/assets/images/products/product-1.jpeg', label: 'Cabanossi', price: '10.00', promoPrice: '', tags: ['Top Seller'], weighted: true, pricePerKG: 300, active: true, onPromotion: false},
        {id: null, categoryId: 2, filterId: 'jwkoaw934j', image: 'src/assets/images/products/Peri-Peri Chicken2.JPG', label: 'Peri Peri Chicken', price: '10.00', promoPrice: '', tags: ['Top Seller'], weighted: true, pricePerKG: 300, active: true, onPromotion: false},
    ],

    Snacks: [
        {id: null, categoryId: 3, filterId: 'bvhdheu93k', image: 'src/assets/images/products/Murkoo3.jpg', label: 'Murkoo', price: '10.00', promoPrice: '', tags: ['Favourite'], active: true, onPromotion: false},
        {id: null, categoryId: 3, filterId: '163ncmsu7a', image: 'src/assets/images/products/Raisins4.jpg', label: 'Peanuts Paisins', price: '10.00', promoPrice: '', tags: ['Top Seller'], active: true, onPromotion: false},
        {id: null, categoryId: 3, filterId: 'hbjkaskhud', image: 'src/assets/images/products/My Scotties1.JPG', label: 'My Scotties', price: '10.00', promoPrice: '', tags: ['Top Seller'], active: true, onPromotion: false},
        {id: null, categoryId: 3, filterId: 'mhkit72ga7', image: 'src/assets/images/products/My Scotties1.JPG', label: 'My Scotties', price: '10.00', promoPrice: '', tags: ['Top Seller', 'Favourite'], active: true, onPromotion: false},
        {id: null, categoryId: 3, filterId: 'mhkit72ga7', image: 'src/assets/images/products/Ginger1.JPG', label: 'Ginger Biscuits', price: '10.00', promoPrice: '', tags: ['Favourite'], active: true, onPromotion: false},
        {id: null, categoryId: 3, filterId: 'awd786ascx', image: 'src/assets/images/products/Supers Creamy Vanilla1.JPG', label: 'Supers Creamy Vanilla', price: '10.00', promoPrice: '', tags: ['Favourite'], active: true, onPromotion: false},
    ],

    Filters: [
        {id: 'hs739erne6', categoryId: 2, label: 'Sliced'},
        {id: 'mncoshjwhj', categoryId: 2, label: 'Cabanossi'},
        {id: 'jwkoaw934j', categoryId: 2, label: 'Peri Peri'},
        {id: 'bvhdheu93k', categoryId: 3, label: 'Serve & Nuts'},
        {id: 'mhkit72ga7', categoryId: 3, label: 'Biscuits'},
        {id: '163ncmsu7a', categoryId: 3, label: 'Peanuts'},
    ],

    /**
     * List of banners
     */
    Banners: [
        // {id: 0, categoryId: 0, url: 'src/assets/images/banners/promo-banner.jpg'},
        // {id: 1, categoryId: 1, url: 'src/assets/images/banners/promo-banner.jpg'},
        // {id: 2, categoryId: 2, url: 'src/assets/images/banners/promo-banner.jpg'},
        // {id: 3, categoryId: 3, url: 'src/assets/images/banners/promo-banner.jpg'},
        // {id: 4, categoryId: 4, url: 'src/assets/images/banners/promo-banner.jpg'},
    ],

    Firstnames: ["Lace", "Burnet", "Silurian", "Ruby", "Willow", "Barry", "Sallow", "July", "John", "Fred", "James", "Elbert", "Kirt", "Amy", "Jasmine", "Eric", "Lola", "Stewert"],

    Lastnames: ["Solemn", "Morbid", "Lovesick", "Joyfull", "Crummy", "Somber", "Wornout", "She", "Hand", "Proof", "Case", "Except", "Boeing", "Honda", "Kraft", "Kellogs", "Cisco", "Ebay"],

    Emails: ["glum@gmail.com", "chubs@gmail.com", "demure@gmail.com", "upright@gmail.com", "wellto@gmail.com", "lively@gmail.com", "rabid@gmail.com", "dashing@gmail.com"],

    Numbers: ["019283715", "017492635", "018233957", "098124872", "058127465", "102238867", "076038271", "0102756432", "002375638"],

    HistoryOrders: [
        {
            id: null,
            total: '199',
            updates: {"1": new Date("2021,11,10"), "2": null, "3": null, "4": null, "5": null},
            datePlaced: new Date("2021,11,10"),
            status: Types.ORDER_STATUS_WAITING,
            paymentStatus: Types.PAYMENT_STATUS_AWAITING,
            user: Interface.USER(),
            products: [
                {id: 4, label: "Cabanossi", price: "10.00", image: "src/assets/images/products/product-1.jpeg", qty: 1, kgAmount: "1.66", pricePerKG: 300, qty: 1, weighted: true, weightedAmount: 499},
                {id: 3, label: "Beef Chips", price: "10.00", image: "src/assets/images/products/beef chips 2.jpg", qty: 1, kgAmount: "1.33", pricePerKG: 300, weighted: true, weightedAmount: 399},
                {id: 2, label: "Chilli Sliced", price: "10.00", image: "src/assets/images/products/chilli%205.jpeg", qty: 1, kgAmount: "1.00", pricePerKG: 300, weighted: true, weightedAmount: 299},
                {id: 1, label: "Chilli Slabs", price: "10.00", image: "src/assets/images/products/Chilli%20Slabs3.JPG", qty: 1, kgAmount: "0.66", pricePerKG: 300, weighted: true, weightedAmount: 199}
            ],
        },
        {
            id: null,
            total: '299',
            updates: {"1": new Date("2021,11,10"), "2": null, "3": null, "4": null, "5": null},
            datePlaced: new Date("2021,11,11"),
            status: Types.ORDER_STATUS_PAYMENT_RECEIVED,
            paymentStatus: Types.PAYMENT_STATUS_RECEIVED,
            user: Interface.USER(),
            products: [
                { id: 6, label: "Peanut Brittle", price: "10.00", image: "src/assets/images/products/Peanut%20Brittle7.JPG", qty: 2, kgAmount: 0, pricePerKG: 0, weighted: false, weightedAmount: 0 },
                { id: 7, label: "Coconut Ice", price: "10.00", image: "src/assets/images/products/Coconut Ice2.JPG", qty: 1, kgAmount: 0, pricePerKG: 0, weighted: false, weightedAmount: 0 },
                { id: 8, label: "Squirrels Mixed", price: "10.00", image: "src/assets/images/products/Squirrels mixed1.JPG", qty: 1, kgAmount: 0, pricePerKG: 0, weighted: false, weightedAmount: 0 },
                { id: 9, label: "Original Fudge", price: "10.00", image: "src/assets/images/products/Original Fudge1.JPG", qty: 2, kgAmount: 0, pricePerKG: 0, weighted: false, weightedAmount: 0 }
            ],
        },
        {
            id: null,
            total: '399',
            updates: {"1": new Date("2021,11,10"), "2": null, "3": null, "4": null, "5": null},
            datePlaced: new Date("2021,11,12"),
            status: Types.ORDER_STATUS_PROCESSING,
            paymentStatus: Types.PAYMENT_STATUS_RECEIVED,
            user: Interface.USER(),
            products: [
                { id: 11, label: "Murkoo", price: "10.00", image: "src/assets/images/products/Murkoo3.jpg", qty: 1, kgAmount: 0, pricePerKG: 0, weighted: false, weightedAmount: 0 },
                { id: 12, label: "Peanuts Paisins", price: "10.00", image: "src/assets/images/products/Raisins4.jpg", qty: 2, kgAmount: 0, pricePerKG: 0, weighted: false, weightedAmount: 0 },
                { id: 13, label: "My Scotties", price: "10.00", image: "src/assets/images/products/My Scotties1.JPG", qty: 2, kgAmount: 0, pricePerKG: 0, weighted: false, weightedAmount: 0 },
                { id: 14, label: "My Scotties", price: "10.00", image: "src/assets/images/products/My Scotties1.JPG", qty: 1, kgAmount: 0, pricePerKG: 0, weighted: false, weightedAmount: 0 }
            ],
        },
        {
            id: null,
            total: '399',
            updates: {"1": new Date("2021,11,10"), "2": null, "3": null, "4": null, "5": null},
            datePlaced: new Date("2021,11,13"),
            status: Types.ORDER_STATUS_CANCELED,
            paymentStatus: Types.PAYMENT_STATUS_RECEIVED,
            user: Interface.USER(),
            products: [
                { id: 11, label: "Murkoo", price: "10.00", image: "src/assets/images/products/Murkoo3.jpg", qty: 1, kgAmount: 0, pricePerKG: 0, weighted: false, weightedAmount: 0 },
                { id: 12, label: "Peanuts Paisins", price: "10.00", image: "src/assets/images/products/Raisins4.jpg", qty: 2, kgAmount: 0, pricePerKG: 0, weighted: false, weightedAmount: 0 },
                { id: 13, label: "My Scotties", price: "10.00", image: "src/assets/images/products/My Scotties1.JPG", qty: 2, kgAmount: 0, pricePerKG: 0, weighted: false, weightedAmount: 0 },
                { id: 14, label: "My Scotties", price: "10.00", image: "src/assets/images/products/My Scotties1.JPG", qty: 1, kgAmount: 0, pricePerKG: 0, weighted: false, weightedAmount: 0 }
            ],
        },
        {
            id: null,
            total: '499',
            updates: {"1": new Date("2021,11,10"), "2": null, "3": null, "4": null, "5": null},
            datePlaced: new Date("2021,11,14"),
            status: Types.ORDER_STATUS_SHIPPED,
            paymentStatus: Types.PAYMENT_STATUS_RECEIVED,
            user: Interface.USER(),
            products: [
                {id: '4', label: "Cabanossi", price: "10.00", image: "src/assets/images/products/product-1.jpeg", qty: 1, kgAmount: "1.66", pricePerKG: 300, weighted: true, weightedAmount: 499},
                {id: 3, label: "Beef Chips", price: "10.00", image: "src/assets/images/products/beef chips 2.jpg", qty: 1, kgAmount: "1.33", pricePerKG: 300, weighted: true, weightedAmount: 399},
                {id: 2, label: "Chilli Sliced", price: "10.00", image: "src/assets/images/products/chilli%205.jpeg", qty: 1, kgAmount: "1.00", pricePerKG: 300, weighted: true, weightedAmount: 299},
                {id: 1, label: "Chilli Slabs", price: "10.00", image: "src/assets/images/products/Chilli%20Slabs3.JPG", qty: 1, kgAmount: "0.66", pricePerKG: 300, weighted: true, weightedAmount: 199}
            ],
        },
        {
            id: null,
            total: '499',
            updates: {"1": new Date("2021,11,10"), "2": null, "3": null, "4": null, "5": null},
            datePlaced: new Date("2021,11,15"),
            status: Types.ORDER_STATUS_DELIVERED,
            paymentStatus: Types.PAYMENT_STATUS_RECEIVED,
            user: Interface.USER(),
            products: [
                {id: '4', label: "Cabanossi", price: "10.00", image: "src/assets/images/products/product-1.jpeg", qty: 1, kgAmount: "1.66", pricePerKG: 300, weighted: true, weightedAmount: 499},
                {id: 3, label: "Beef Chips", price: "10.00", image: "src/assets/images/products/beef chips 2.jpg", qty: 1, kgAmount: "1.33", pricePerKG: 300, weighted: true, weightedAmount: 399},
                {id: 2, label: "Chilli Sliced", price: "10.00", image: "src/assets/images/products/chilli%205.jpeg", qty: 1, kgAmount: "1.00", pricePerKG: 300, weighted: true, weightedAmount: 299},
                {id: 1, label: "Chilli Slabs", price: "10.00", image: "src/assets/images/products/Chilli%20Slabs3.JPG", qty: 1, kgAmount: "0.66", pricePerKG: 300, weighted: true, weightedAmount: 199}
            ],
        },
    ],

};
