import {Firebase, AUTH, DB, STORAGE} from "../Firebase";
import { doc, addDoc, getDocs, deleteDoc, setDoc, updateDoc, collection, onSnapshot, orderBy, query, limit } from "firebase/firestore";
import { Utility } from "../../Utility";

export const Filters = {

    /**
     * Will set/override a document under the [filters] collection
     * @param {{filter: Object, log: Boolean}} options
     * @param {Function} finish
     */
    Create: async (options, finish) => {
        addDoc(collection(DB, "filters"), options.filter)
        .then((res) => {
            updateDoc(doc(DB, `filters/${res.id}`),{id: res.id})
                .then((data) => {
                    // callback
                    if (finish) finish(data);

                    // log to console if admin user
                    if (options.log) Utility.Log("SUCCESS", `ADMIN FILTERS :: (addDoc, updateDoc)`, data);
                })
                .catch(err => Popup.New({
                    renderTo: "productsPopup",
                    type: Types.POPUP_ERROR,
                    id: "order-create-fail",
                    header: 'Firebase Failure',
                    message: err.message,
                    other: null,
                    mainAction: {
                        id: 1,
                        text: "Dismiss",
                        action: () => Popup.Close()
                    },
                }));
        });
    },

    /**
     * Retrieves all the documents under the [filters] collection
     * @returns {Array}
     */
    GetAllFilters: async () => {
        const filters = [];
        const snapshot = await getDocs(collection(DB, "filters"));
        snapshot.forEach(doc => filters.push(doc.data()));
        return filters;
    },

    initializeSnapshotUpdates: (callback) => {
        const q = query(collection(DB, "filters"));
        onSnapshot(q, callback);
    },

    /**
     * Will delete a document under the [filters] collection based on an id
     * @param {String} id
     * @param {Boolean} log
     * @param {Function} done
     */
    Delete: async (id, log, done) => {
        await deleteDoc(doc(DB, `filters/${id}/`)).then(() => {
            if (done) done();
            if (log) Utility.Log("SUCCESS", `ADMIN FILTERS :: (deleteDoc)`, id);
        });
    },

    /**
     * Deletes every filter document stored under the filters collection
     */
     deleteAllFilters: async () => {
        const snapshot = await getDocs(collection(DB, "filters"));
        snapshot.forEach(doc => Filters.Delete(doc.data().id));
    },

};
