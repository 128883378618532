import {Firebase, AUTH, DB} from "../Firebase";
import {
    doc,
    getDoc,
    getDocs,
    deleteDoc,
    setDoc,
    updateDoc,
    collection,
    onSnapshot,
    orderBy,
    query,
    limit,
    addDoc,
    where
} from "firebase/firestore";
import { Utility } from "../../Utility";


export const Promos = {
    
    initializePromoSync: async (callback) => {
        const q = query(collection(DB, "promos"));
        return onSnapshot(q, callback);
    },

    /**
     * Will update a promo based on a key
     * @param {Number} id
     * @param {Object} key
     * @param {Boolean} log
     * @param {Function} success
     */
     UpdateWithKey: (id, key, log, success) => {
        const promoRef = doc(DB, `promos/${id}/`);
        updateDoc(promoRef, key, { merge: true }).then(() => {
            if (success) success();
            if (log) Utility.Log("SUCCESS", `ADMIN PROMOS :: (updateDoc)`, key);
        });
    },

}