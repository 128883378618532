import {Firebase, AUTH, DB} from "../Firebase";
import { doc, getDoc, getDocs, deleteDoc, setDoc, updateDoc, collection, query, onSnapshot } from "firebase/firestore";
import { Popup } from "../../Components/Popup";
import { Types } from "../../Types";
import { Utility } from "../../Utility";

export const Users = {

    /**
     * Will set/override a document under the [users] collection
     * @param {{user: Object, log: Boolean}} options
     */
    Create: (options, done) => {
        const userRef = doc(DB, `users/${options.user.id}`);
        setDoc(userRef, options.user, { merge: true })
        .then(() => {
            // allow fontend to show message
            if (done) done();

            // log a success
            if (options.log) Utility.Log("SUCCESS", `ADMIN USERS :: (setDoc)`, options.user);
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            Popup.New({
                renderTo: "app",
                type: Types.POPUP_ERROR,
                id: "user-create-failed",
                header: `Your Creation Failed (${errorCode})`,
                message: errorMessage,
                other: null,
                mainAction: {
                    id: 1,
                    text: "Dismiss",
                    action: () => Popup.Close()
                }
            })
        });
    },

    /**
     * Will attempt to update a users details excluding the delivery details
     * @param {String} id
     * @param {Object} user
     * @param {Boolean} log
     */
    Update: (id, user, log) => {
        const userRef = doc(DB, `users/${id}/`);
        updateDoc(userRef, {
            'firstname': user.firstname,
            'lastname': user.lastname,
            'email': user.email,
            'mobileNumber': user.mobileNumber,
            'role': user.role,
            'active': user.active,
            'avatar': user.avatar,
        }, { merge: true }).then(() => {
            if (log) Utility.Log("SUCCESS", `ADMIN USERS :: (updateDoc)`, user);
        });
    },

    UpdateWithKey: (id, key, log, success) => {
        const userRef = doc(DB, `users/${id}/`);
        updateDoc(userRef, key, { merge: true }).then(() => {
            if (success) success();
            if (log) Utility.Log("SUCCESS", `ADMIN USERS :: (updateDoc)`, key);
        });
    },

    /**
     * Deletes a user based on the ID
     * @param {String} id
     * @param {Boolean} log
     */
    Delete: async (id, log, done) => {
        await deleteDoc(doc(DB, `users/${id}/`)).then(() => {
            if (done) done();
            if (log) Utility.Log("SUCCESS", `ADMIN USERS :: (deleteDoc)`, id);
        });
    },

    /**
     * Will attempt to retrieve all user documents
     * @returns {Array}
     */
    getAll: async () => {
        const users = [];
        const snapshot = await getDocs(collection(DB, "users"));
        snapshot.forEach(doc => users.push(doc.data()));
        return users;
    },

    /**
     * Will attempt to retrieve a document based on an ID
     * @param {String} id
     * @returns {Object | null}
     */
    getByID: async (id) => {
        const userRef = doc(DB, `users/${id}`);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) return userSnap.data();
        else {
            Utility.Log("ERROR", `ADMIN USERS :: (getByID)`, id);
            return null;
        }
    },

    /**
     * Will update a user documents delivery details
     * @param {String} id
     * @param {{streetNumber: Number, streetName: String, city: String, postalCode: Number}} details
     * @param {Boolean} log
     */
    UpdateDeliveryDetails: (id, details, log) => {
        const userRef = doc(DB, `users/${id}/`);
        updateDoc(userRef, {
            'deliveryDetails.streetNumber': details.streetNumber,
            'deliveryDetails.streetName': details.streetName,
            'deliveryDetails.city': details.city,
            'deliveryDetails.postalCode': details.postalCode,
        }, { merge: true }).then(() => {
            if (log) Utility.Log("SUCCESS", `ADMIN USERS :: (updateDoc)`, id);
        });
    },

    /**
     * Deletes every user document stored under the users collection
     */
    deleteAllUsers: async () => {
        const snapshot = await getDocs(collection(DB, "users"));
        snapshot.forEach(doc => Users.Delete(doc.data().id));
    },

    initializeSync: (callback) => {
        const q = query(collection(DB, "users"));
        onSnapshot(q, callback);
    }

};
