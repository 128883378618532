export const Spinner = {
    activeSpinners: [],

    /**
     * Will add a spinner into a button
     * @param {String} id
     * @param {String} color
     * @constructor
     */
    Button: (id, color) => {
        const button = document.getElementById(id);
        if (button) {
            // save button inner HTML
            Spinner.activeSpinners.push({
                id,
                element: button,
                innerHTML: button.innerHTML
            });
            const custom_color = (color)? `style="border-top-color: ${color}!important;"` : '';
            button.innerHTML = `<span id="buttonSpinner" ${custom_color}></span>`;
        }
    },

    /**
     * Will show a default loader over everything
     * @param {{renderEl: String}} options
     * @constructor
     */
    Default: (options) => {
        const renderEl = document.getElementById(options.renderEl);
        if (renderEl) {
            const spinner = `
            <div id="defaultSpinnerOuterWrapper" class="d-flex flex-center flex-align">
                <div class="defaultSpinnerInnerWrapper d-flex flex-center flex-align">
                    <div class="defaultSpinner"></div>
                </div>
            </div>`;

            // parse the string
            const parser = new DOMParser();
            const child = parser.parseFromString(spinner, "text/html").body.firstChild;

            // render the spinner
            renderEl.appendChild(child);
        }
    },

    StopDefault: () => {
        const el = document.getElementById('defaultSpinnerOuterWrapper');
        if (el) el.remove();
    },

    /**
     * Will stop a spinner inside of a button
     * @param id
     * @constructor
     */
    StopButton: (id) => {
        Spinner.activeSpinners.forEach(spinner => {spinner.element.innerHTML = spinner.innerHTML})
    }
};