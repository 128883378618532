/**
 * NOTE
 * @Documentation http://activejs.co.za
 * @GIT https://github.com/PostmanPat123/ActiveJS-Raspberry
 */

// Import the Raspberry API
import {createApp, Animations, Load, Router} from "@jordan_langton/activejs-raspberry-core";
import { CheckIfAppVersionHasChanged } from "./classes/cache";
import { Config } from "./classes/Config";
import { Firebase } from "./classes/Firebase/Firebase";

// custom libraries
import { Utility } from "./classes/Utility";

// Create your app
createApp({
	renderEl: '#app',
	visualErrors: false,
	routes: [
		{
			path: '/testing-view',
			handler: './src/views/test.html',
			meta: {},
		},
		{
			path: '/loading',
			handler: './src/views/store/loadingPage.html',
			meta: {},
		},
		{
			path: '/authentication',
			handler: './src/views/authentication.html',
			meta: {}
		},
		{
			path: '/mobile/authentication',
			handler: './src/views/mobile/authentication.html',
			meta: {}
		},
		{
			path: '/admin/dashboard',
			handler: './src/views/admin/dashboard.html',
			meta: {requiresAdmin: true},
		},
		{
			path: '/admin/users',
			handler: './src/views/admin/usersManagement.html',
			meta: {requiresAdmin: true},
		},
		{
			path: '/admin/orders',
			handler: './src/views/admin/ordersManagement.html',
			meta: {requiresAdmin: true},
		},
		{
			path: '/admin/products',
			handler: './src/views/admin/productsManagement.html',
			meta: {requiresAdmin: true},
		},
		{
			path: '/landingPage',
			handler: './src/views/store/landingPage.html',
			meta: {}
		},
		{
			path: '/storeFront',
			handler: './src/views/store/storeFront.html',
			meta: {}
		},
		{
			path: '/mobile/storeFront',
			handler: './src/views/mobile/storeFront.html',
			meta: {}
		},
		{
			path: '/products',
			handler: './src/views/store/productsView.html',
			meta: {}
		},
		{
			path: '/mobile/products',
			handler: './src/views/mobile/productsView.html',
			meta: {}
		},
		{
			path: '/product',
			handler: './src/views/store/singleProductView.html',
			meta: {}
		},
		{
			path: '/mobile/product',
			handler: './src/views/mobile/singleProductView.html',
			meta: {}
		},
		{
			path: '/profile',
			handler: './src/views/store/profileView.html',
			meta: {}
		},
		{
			path: '/mobile/profile',
			handler: './src/views/mobile/profileView.html',
			meta: {}
		},
		{
			path: '/payment',
			handler: './src/views/store/paymentOptionView.html',
			meta: {}
		},
		{
			path: '/mobile/payment',
			handler: './src/views/mobile/paymentOptionView.html',
			meta: {}
		},
		{
			path: '/orderHistory',
			handler: './src/views/store/orderHistoryView.html',
			meta: {}
		},
		{
			path: '/mobile/orderHistory',
			handler: './src/views/mobile/orderHistoryView.html',
			meta: {}
		},
		{
			path: '/termsOfService',
			handler: './src/views/store/termsOfService.html',
			meta: {}
		},
	]
}, async () => {
	console.log(
		`%c ${Config.VERSION} %c ENVIRONMENT RUNNING: ${Config.ENVIRONMENT} `,
		'background-color: #E3E262; color: #000000; font-weight: bold;',
		'background-color: #180376; color: #ffffff;'
	);

	// start service worker
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.register('./sw.js')
		.then((result) => console.log("Worker Registered"))
		.catch((err) =>  console.log("Worker Did Not Register"));
	}

	// check for app version change
	CheckIfAppVersionHasChanged()

	// check for scripts
	Utility.checkForLoadedScripts()

	// show the loading page
	Utility.showLoadingPage(() => {
		// initialize firebase
		Firebase.initialize()

		// load all custom libraries
		Utility.LoadLibs()

		// set up the before each hook for router
		Utility.initializeBeforeEachRoute()
	});
});
