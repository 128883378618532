import { getAdminSideNavMinimized, setAdminSideNavMinimized } from "../stores/globalState";

export const topNavBarDash = {

    /**
     * Nav item
     * @type {{id: *, icon: String, label: String, active: Boolean, action: Function}}
     */
    TYPE_NAV_ITEM: {
        id: "",
        icon: "" || false,
        label: '',
        active: false,
        action: () => {
        }
    },

    /**
     * Will generate a top navigation bar
     * @param {{
     *     renderEl: String,
     *     navItems: topNavBarDash.TYPE_NAV_ITEM[],
     *     hamBurger: {
     *         heading: String,
     *         icon: String,
     *         action: Function
     *     },
     * }} options
     * @constructor
     */
    Create: (options) => {
        // generate HTML
        const HTML = topNavBarDash.buildHTML({
            heading: options.hamBurger.heading,
            hamburger: options.hamBurger.icon,
            navItems: options.navItems,
        });

        // render the component
        topNavBarDash.Render({renderEl: options.renderEl, html: HTML}, () => {
            // choose to toggle the sidebar
            topNavBarDash.methods.toggleMinimize();

            // apply onclick to burger item
            const burger = document.getElementById("topNavBarDashBurger");
            if (burger) burger.onclick = options.hamBurger.action;
            else console.error("topNavBarDash :: Failed to apply onclick to hamburger icon");

            // apply onclick to all nav items
            options.navItems.forEach(item => {
                // get element
                const element = document.getElementById(`navBarItem-${item.id}`);

                // apply onclick if element was found
                if (element) element.onclick = item.action;
                else console.error(`topNavBarDash :: Failed to apply onclick for element (navBarItem-${item.id})`);
            })
        });
    },

    /**
     * Will build up the html for the component
     * @param {{heading: String, hamburger: String, navItems: topNavBarDash.TYPE_NAV_ITEM[]}} options
     * @returns String
     */
    buildHTML: (options) => {

        // generate the nav items html
        let navItems = "";
        if (options.navItems) options.navItems.forEach(item => {
            // select which item is active
            const isActive = (item.active)? 'is-active' : '';

            // choose to put a logo
            let icon = "";
            if (item.icon !== "") icon = `<div class="icon">${item.icon}</div>`;

            // choose to put a logo
            let label = "";
            if (item.label !== "") label = `<div class="label text-md-bold">${item.label}</div>`;

            navItems += `
            <div id="navBarItem-${item.id}" class="item ${isActive} d-flex flex-center flex-align">
                ${icon}
                ${label}
            </div>`;
        });

        return `
        <div id="topNavBarDash" class="d-flex flex-between flex-align">
            <!-- hamburger -->
            <div class="hamburger d-flex flex-center flex-align">
                <div id="topNavBarDashBurger" class="icon">${options.hamburger}</div>
                <div class="text text-lg-bold">${options.heading}</div>
            </div>
            
            <!-- nav items wrapper -->
            <div class="navItemsWrapper d-flex flex-end flex-align">
                <!-- nav items -->
                ${navItems}
            </div>
        </div>`;
    },

    /**
     *
     * @param {{renderEl: String, html: String}} options
     * @param {Function} callback
     * @constructor
     */
    Render: (options, callback) => {
        // get render element
        const renderElement = document.getElementById(options.renderEl);

        // if element was found, render navbar
        if (renderElement) {
            // render
            renderElement.innerHTML = options.html;

            // apply onclick
            callback();
        }
        else console.error("topNavBarDash :: Failed to find render element");
    },

    /**
     * Methods that the class will use
     */
    methods: {
        toggleMinimize: () => {
            const el = document.getElementById("sideNavBarDashWrapper");
            if (el) {
                el.classList.toggle("minimized");
                setAdminSideNavMinimized(!getAdminSideNavMinimized());
            }
        }
    }
};
