export const Types = {
    /**
     * Environment types
     * @type {String}
     */
    DEVELOPMENT: "development",
    PRODUCTION: "production",

    /**
     * Host types
     * @type {String}
     */
    LOCAL_HOST: "LOCALHOST",

    /**
     * Popup types
     * @type {String}
     */
    POPUP_SUCCESS: "S",
    POPUP_INFO: "I",
    POPUP_ERROR: "E",
    POPUP_WARNING: "W",

    /**
     * User roles types
     * @type {Number}
     */
    USER_ADMIN: 2,
    USER_MEMBER: 1,

    /**
     * User States types
     * @type {String}
     */
    USER_ACTIVE: true,
    USER_SUSPENDED: false,

    /**
     * Navbar types
     * @type {String}
     */
    NAVBAR_LOGO: "NAVBAR_LOGO",
    NAVBAR_BACK: "NAVBAR_BACK",

    /**
     * Products lists types
     * @type {String}
     */
    TYPE_STOREFRONT: "STOREFRONT",
    TYPE_DASHBOARD: "DASHBOARD",

    /**
     * Yes/No types
     * @type {String}
     */
    TYPE_Y: "Y",
    TYPE_N: "N",

    /**
     * Category types
     * @type {String}
     */
    CATEGORY_SWEETS: { id: 1, label: 'SWEETS' },
    CATEGORY_BILTONG: { id: 2, label: 'BILTONG'},
    CATEGORY_SNACKS: { id: 3, label: 'SNACKS'},

    /**
     * Order Status types
     * @type {{ id: Number, label: String, dateUpdated: String }}
     */
    ORDER_STATUS_WAITING: { id: 1, label: 'WAITING' },
    ORDER_STATUS_PAYMENT_RECEIVED: { id: 2, label: 'ORDER PLACED' },
    ORDER_STATUS_PROCESSING: { id: 3, label: 'PROCESSING' },
    ORDER_STATUS_SHIPPED: { id: 4, label: 'SHIPPED' },
    ORDER_STATUS_DELIVERED: { id: 5, label: 'DELIVERED' },
    ORDER_STATUS_CANCELED: { id: 6, label: 'CANCELED' },

    /**
     * Payment Status types
     * @type {{ id: Number, label: String, dateUpdated: String }}
     */
     PAYMENT_STATUS_AWAITING: { id: 1, label: 'AWAITING PAYMENT' },
     PAYMENT_STATUS_RECEIVED: { id: 2, label: 'PAYMENT RECEIVED' },

     /**
     * Device Types
     * @type {String}
     */
     DEVICE_TYPE_DESKTOP: "desktop",
     DEVICE_TYPE_MOBILE: "mobile",
     DEVICE_TYPE_TABLET: "tablet",

     /**
     * DB Update Types
     * @type {String}
     */
     TYPE_DB_ADDED: "added",
     TYPE_DB_MODIFIED: "modified",
     TYPE_DB_REMOVED: "removed",

     /**
     * User registered
     * @type {String}
     */
    USER_REGISTERED: "bm9ZAb",

     /**
     * Cart abandon type
     * @type {String}
     */
    CART_ABANDON: "bk0RYd",

     /**
     * Cart updated type
     * @type {String}
     */
    CART_DELETED: "cart_deleted",

     /**
     * Order completed type
     * @type {String}
     */
    ORDER_COMPLETED: "order_completed",

     /**
     * Status update type
     * @type {String}
     */
    STATUS_UPDATE_AWAITING_PAYMENT: "ej9Rzd",

     /**
     * Status update type
     * @type {String}
     */
    STATUS_UPDATE_PROCESSING: "b8D25b",

     /**
     * Status update type
     * @type {String}
     */
    STATUS_UPDATE_RECEIVED: "eg9Jlb",

     /**
     * Status update type
     * @type {String}
     */
    STATUS_UPDATE_SHIPPED: "e0rRGb",

     /**
     * Status update type
     * @type {String}
     */
    STATUS_UPDATE_DELIVERED: "e9Er4e",

     /**
     * Status update type
     * @type {String}
     */
    STATUS_UPDATE_CANCELLED: "dNor6e",
};
