import {Cart} from "../Cart";
import {Popup} from "./Popup";
import {Types} from "../Types";
import { Config } from "../Config";
import { Router } from "@jordan_langton/activejs-raspberry-core";
import { GetAllPromos } from "../stores/promoState";
import { getLoggedInUserFromState } from "../stores/usersState";
export const cartOverlay = {

    actions: null,

    showing: false,

    /**
     * Creates a cart overlay
     * @param {{
     *      renderEl: String,
     *      heading: String,
     *      addressInfo: Object,
     *      actions: {
     *          plus: Function,
     *          minus: Function,
     *          remove: Function,
     *          close: Function,
     *          weightedUpdate: Function,
     *          checkout: Function
     *      }
     * }} options
     * @returns {{toggle: Function, update: Function}}
     */
    Create: (options) => {
        // generate html
        const HTML = cartOverlay.buildHTML(options.addressInfo);

        // setup actions
        cartOverlay.actions = options.actions;

        cartOverlay.Render({
            html: HTML,
            renderEl: options.renderEl,
        }, () => {
            // add close event
            const closeButton = document.getElementById("closeCartOverlay");
            if (closeButton) closeButton.onclick = () => options.actions.close();

            // add checkout event
            const checkoutButton = document.getElementById("cartCheckoutButton");
            if (checkoutButton) checkoutButton.onclick = () => options.actions.checkout();

            // add checkout event
            const editAddressInfo = document.getElementById("editAddressInfo");
            if (editAddressInfo) editAddressInfo.onclick = () => Router.route('/profile');

            // add the promo events
            cartOverlay.applyPromoEvents();

            // update cart items
            cartOverlay.updateCart();
        });

        return {
            update: () => cartOverlay.updateCart(),
            showing: () => cartOverlay.showing,
            toggle: (show) => {
                const el = document.getElementById("cartOverlay");

                // you are not logged in
                if ((cartOverlay.showing === false) && (Cart.items.length === 0) && (!show)) {
                    return Popup.New({
                        renderTo: "app",
                        type: Types.POPUP_WARNING,
                        id: "cartEmptyPopup",
                        header: "Empty Cart",
                        message: "Your cart is currently empty, please add some items to your cart",
                        other: null,
                        mainAction: {
                            id: 1,
                            text: "Dismiss",
                            action: () => Popup.Close()
                        },
                    });
                }

                // choose whether or not to show/hide
                if ((!cartOverlay.showing && el) || show) {
                    cartOverlay.showing = true;
                    el.classList.remove("hide");
                    el.classList.add("showing");
                }
                else {
                    cartOverlay.showing = false;
                    el.classList.remove("showing");
                    el.classList.add("hide");
                }
            },
            hide: () => {
                const el = document.getElementById("cartOverlay");
                cartOverlay.showing = false;
                el.classList.remove("showing");
                el.classList.add("hide");
            },
        };
    },

    buildHTML: (addressInfo) => {
        return `
        <div id="cartOverlay" class="d-flex flex-col">
            <!-- Content -->
            <div class="cartContent d-flex flex-col">
                <!-- Header -->
                <div class="header d-flex flex-align flex-between">
                    <h1 class="mainHeading">My Cart</h1>
                    <button id="closeCartOverlay" class="qtyMinus"><i class="fas fa-times"></i> Close</button>
                </div>

                <!-- Address Info -->
                <div class="addressWrapper d-flex flex-col">
                    <div class="addressHeader d-flex flex-align">
                        <div class="addressHeading">Delivery Address</div>
                        <button id="editAddressInfo" >Edit <i class="fas fa-chevron-down"></i></button>
                    </div>
                    <div class="address">${addressInfo.streetNumber} ${addressInfo.streetName}</div>
                    <div class="subAddress">${addressInfo.city}, ${addressInfo.postalCode}</div>
                </div>

                <!-- Cart Items -->
                <div id="overlayCartItems" class="cartItemsWrapper d-flex flex-col flex-align"></div>
            </div>

            <!-- Totals -->
            <div class="cartTotals d-flex flex-col flex-align">
                <div class="cartSummary d-flex flex-col">
                    <div class="item d-flex flex-align flex-between">
                        <h1>Sub Total</h1>
                        <p id="cartSubTotal">R800.00</h1>
                    </div>
                    <!--<div class="item d-flex flex-align flex-between">
                        <h1>Delivery Fee</h1>
                        <p id="cartTotalInCents">R90.00</h1>
                    </div>-->
                    <div class="item d-flex flex-align flex-between">
                        <h1>Delivery Fee</h1>
                        <p id="cartDeliveryFee">R90.00</h1>
                        <p id="cartDeliveryFeeFree">R0.00</h1>
                    </div>
                    <div class="item d-flex flex-align flex-between">
                        <h1>Promo Code</h1>
                        <div class="promoCodeWrapper d-flex flex-col">
                            <button id="promoButton" class="">Enter code</button>
                            <div id="promoInputWrapper" class="d-none flex-col">
                                <div class="input-wrapper d-flex flex-between flex-align">
                                    <input type="text" id="promoCode" placeholder="Promo code">
                                    <div class="d-flex flex-align">
                                        <button id="submitPromo" class="submit">Submit</button>
                                        <button id="clearPromo" class="clear">clear</button>                                    
                                    </div>
                                </div>
                                <p id="promoLabel" class="d-none"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="actions d-flex flex-col">
                    <div class="item d-flex flex-align flex-between">
                        <h1>Total</h1>
                        <p id="cartTotal" class="big">R890.00</p>
                    </div>
                    <button id="cartCheckoutButton">Checkout</button>
                </div>
            </div>
        </div>`;
    },

    Render: (options, callback) => {
        const el = document.getElementById(options.renderEl);
        if (el) {
            el.innerHTML = options.html;
            callback();
        }
    },

    updateCart: () => {
        const items = cartOverlay.generateCartItems();
        const renderEl = document.getElementById("overlayCartItems");
        if (renderEl) {
            // render the items
            renderEl.innerHTML = items;

            // add events to the items
            Cart.items.forEach(item => {
                const incrementorAdd = document.getElementById(`cartQtyPlus-${item.id}`);
                const incrementorRemove = document.getElementById(`cartQtyMinus-${item.id}`);
                const removeItemButton = document.getElementById(`removeItem-${item.id}`);
                const incrementorValue = document.getElementById(`cartQtyValue-${item.id}`);
                const cartWeightedValue = document.getElementById(`cartWeightedValue-${item.id}`);
                const kgAmount = document.getElementById(`kgAmount-${item.id}`);

                // add event to (incrementorAdd)
                if (incrementorAdd) incrementorAdd.onclick = () => cartOverlay.actions.plus(item, () => {
                    incrementorValue.innerHTML = item.qty;
                    cartOverlay.updateCart();
                });

                // add event to incrementorRemove
                if (incrementorRemove) incrementorRemove.onclick = () => cartOverlay.actions.minus(item, () => {
                    incrementorValue.innerHTML = item.qty;
                    cartOverlay.updateCart();
                });

                // add event to removeItemButton
                if (removeItemButton) removeItemButton.onclick = () => cartOverlay.actions.remove(item, cartOverlay.updateCart);

                // update weighted amount value
                if (cartWeightedValue && kgAmount) cartWeightedValue.oninput = (e) => cartOverlay.actions.weightedUpdate(item, e.target, kgAmount, cartOverlay.updateCartTotals);

            });

            // update the cart totals
            cartOverlay.updateCartTotals();
        }
    },

    updateCartTotals: () => {
        const subTotalEl = document.getElementById("cartSubTotal");
        const subdeliveryFee = document.getElementById("cartDeliveryFee");
        const cartDeliveryFeeFree = document.getElementById("cartDeliveryFeeFree");
        const cartTotal = document.getElementById("cartTotal");

        if (subTotalEl && subdeliveryFee && cartTotal) {
            subTotalEl.innerHTML = "R"+Cart.getSubTotal().toFixed(2);
            cartTotal.innerHTML = "R"+Cart.getTotal();
            // cartTotalInCents.innerHTML = Cart.getTotalInCents()+"c";
            subdeliveryFee.innerHTML = "R"+Config.DELIVERY_FEE;

            const subTotal = Cart.getSubTotal();
            if (subTotal >= Config.FREE_DELIVERY_AMOUNT) {
                subdeliveryFee.classList.add('d-none');
                cartDeliveryFeeFree.classList.remove('d-none');
            }
            else {
                subdeliveryFee.classList.remove('d-none');
                cartDeliveryFeeFree.classList.add('d-none');
            }
        }
    },

    generateCartItems: () => {
        let items = "";
        Cart.items.forEach(item => {
            // check if item is a weighted item or not
            let removeButton = "";
            let incrementor = "";
            let weighted = "";
            if (!item.weighted) {
                incrementor = `
                <div class="qtyWrapper d-flex flex-align">
                    <button id="cartQtyMinus-${item.id}" class="qtybutton"><i class="fas fa-minus"></i></button>
                    <div id="cartQtyValue-${item.id}" class="qtyValue">${item.qty}</div>
                    <button id="cartQtyPlus-${item.id}" class="qtybutton"><i class="fas fa-plus"></i></button>
                </div>`;

                removeButton = `<button id="removeItem-${item.id}" class="removeItem"><i class="fas fa-trash-alt"></i></button>`;
            }
            else {
                weighted = `
                <div class="weightedWrapper d-flex flex-align flex-between">
                    <div id="cartWeightedWrapper-${item.id}" class="inputWrapper d-flex flex-align">
                        <div class="currency">R</div>
                        <input value="${item.weightedAmount}" type="number" id="cartWeightedValue-${item.id}">
                    </div>
                    <div id="kgAmount-${item.id}" class="kgAmount">${item.kgAmount} kgs</div>
                    <button id="removeItem-${item.id}" class="removeItem"><i class="fas fa-trash-alt"></i></button>
                </div>`;
            }

            items += `
            <div class="cartItem d-flex">
                <div class="productImage" style="background-image: url('${item.image}');"></div>
    
                <div class="itemContent d-flex flex-col">
                    <h3 class="label">${item.label}</h3>
                    <div class="actions d-flex flex-align">   
                        <!-- Weighted Wrapper --> 
                        ${weighted}
                        
                        <!-- Incrementor Wrapper -->                           
                        ${incrementor}

                        <!-- Remove Button -->    
                        ${removeButton}
                    </div>
                </div>
    
                <div class="itemPrice">R${(!item.weighted)? item.price : item.pricePerKG+"/kg"}</div>
            </div>`;
        });

        return items;
    },

    /**
     * Will attach all the events to the promo elements
     */
    applyPromoEvents: () => {
        // add the event to show the promo input
        const promoButton = document.querySelector('#promoButton');
        if (promoButton) promoButton.onclick = () => {
            const promoInputWrapper = document.querySelector('#promoInputWrapper');
            if (promoInputWrapper) {
                // show the "promoInputWrapper"
                promoInputWrapper.classList.remove('d-none');
                promoInputWrapper.classList.add('d-flex');

                // hide the button which shows promo wrapper
                promoButton.classList.add('d-none');
            }
        };

        // add event to the clear promo button
        const clearPromo = document.querySelector('#clearPromo');
        if (clearPromo) clearPromo.onclick = () => {
            const promoInputWrapper = document.querySelector('#promoInputWrapper');
            if (promoInputWrapper) {
                // clear out the active promo
                Cart.appliedPromoCode = null;

                // hide the "promoInputWrapper"
                promoInputWrapper.classList.remove('d-flex');
                promoInputWrapper.classList.add('d-none');

                // show the button which shows promo wrapper
                promoButton.classList.remove('d-none');

                // clear our the UI
                document.querySelector('#promoCode').value = "";
                const promoLabel =  document.querySelector('#promoLabel');
                promoLabel.classList.add('d-none');
                promoLabel.innerHTML = "";

                // update cart totals
                cartOverlay.updateCartTotals();
            }
        }

        // add event to the submit promo button
        const promoLabel = document.querySelector('#promoLabel');
        const submitPromo = document.querySelector('#submitPromo');
        if (submitPromo) submitPromo.onclick = () => {
            const promoCode = document.querySelector('#promoCode').value;
            
            // check if the code matches a loaded promo code and hasn't been used by said user yet
            const user = getLoggedInUserFromState();
            const match = GetAllPromos().filter(promo => promo.code == promoCode)[0];
            console.log('match', match);
            if (match) {
                // apply the promo code
                Cart.appliedPromoCode = match;
                cartOverlay.updateCartTotals();

                // update UI
                promoLabel.innerHTML = match.label;
                promoLabel.classList.remove('d-none');
            }
        }

        // check if there is already a active promo code
        if (Cart.appliedPromoCode) {
            console.log('Code Already Active :: '+Cart.appliedPromoCode.code)
            // show the promo input
            promoButton.click();

            // insert the code into input and show the applied label
            document.querySelector('#promoCode').value = Cart.appliedPromoCode.code;
            const promoLabel =  document.querySelector('#promoLabel');
            promoLabel.classList.remove('d-none');
            promoLabel.innerHTML = Cart.appliedPromoCode.label
        }
    }

};
