export const productList = {

    /**
     * Will generate a top navigation bar
     * @param {{
     *     renderEl: String,
     *     products: Array,
     *     actions: {
     *         edit: Function,
     *         delete: Function
     *     }
     * }} options
     * @constructor
     */
    Create: (options) => {
        // generate HTML
        const HTML = productList.buildHTML({products: options.products});

        // render the component
        productList.Render({renderEl: options.renderEl, html: HTML}, () => {
            options.products.forEach(product => {
                const editProduct = document.getElementById(`editProduct-${product.id}`);
                const deleteProduct = document.getElementById(`deleteProduct-${product.id}`);

                if (editProduct) editProduct.onclick = () => options.actions.edit(product);
                if (deleteProduct) deleteProduct.onclick = () =>  options.actions.delete(product);
            });
        });
    },

    /**
     * Will build up the html for the component
     * @param {{products: Array}} options
     * @returns String
     */
    buildHTML: (options) => {

        // generate the nav items html
        let productList = `
        <div class="productRow d-flex flex-center flex-align">
            <div class="column photo text-sm-bold">Photo</div>
            <div class="column name text-sm-bold">Label</div>
            <div class="column email text-sm-bold">Price</div>
            <div class="column mobile text-sm-bold">Weighted</div>
            <div class="column status text-sm-bold">Status</div>
            <div class="column actions text-center text-sm-bold">Actions</div>
        </div>`;

        // generate rows
        if (options.products) options.products.forEach(product => {
            const weighted = (product.weighted)? 'YES' : 'NO';
            const weightedColor = (product.weighted)? "background-color: var(--info); color: var(--white)" : "background-color: var(--warning); color: var(--dark)";
            const status = (product.active)? "ACTIVE" : "SUSPENDED";
            const statusColor = (product.active)? "background-color: var(--success); color: var(--white)" : "background-color: var(--warning); color: var(--dark)";

            // generate the price for list item
            const defaultPrice = (!product.weighted)? product.price : product.pricePerKG+"/kg";
            const promotionPrice = (!product.weighted)? product.promoPrice : product.promoPrice+"/kg";
            const price = (!product.onPromotion)? defaultPrice : promotionPrice;

            productList += `
            <div id="product-${product.id}" class="productRow d-flex flex-center flex-align">
                <div class="column photo text-sm"><img src="${product.image}"></div>
                <div class="column label text-sm">${product.label}</div>                
                <div class="column price text-sm">R${price}</div>
                <div class="column weighted text-sm"><span style="${weightedColor}">${weighted}</span></div>
                <div class="column status text-sm"><span style="${statusColor}">${status}</span></div>
                <div class="column actions d-flex flex-even flex-align">
                    <div id="editProduct-${product.id}" class="productRowEditAction icon"><i class="fas fa-pencil-alt"></i></div>
                    <div id="deleteProduct-${product.id}" class="productRowDeleteAction icon"><i class="fas fa-trash-alt"></i></div>
                </div>
            </div>`;
        });

        return `
        <div id="productDashList" class="d-flex flex-col">
            ${productList}
        </div>`;
    },

    /**
     *
     * @param {{renderEl: String, html: String}} options
     * @param {Function} callback
     * @constructor
     */
    Render: (options, callback) => {
        // get render element
        const renderElement = document.getElementById(options.renderEl);

        // if element was found, render navbar
        if (renderElement) {
            // render
            renderElement.innerHTML = options.html;

            // apply onclick
            callback();
        }
        else console.error("productList :: Failed to find render element");
    },
};
