import { FUNCTIONS } from "../Firebase";
import { httpsCallable } from "firebase/functions";
import { Popup } from "../../Components/Popup";
import { Users } from "./Users";
import { LoadNewUser } from "../../stores/usersState";
import { Types } from "../../Types";
import { Utility } from "../../Utility";
import axios from "axios";

/**
 * Will create a new user under the firebase authentication
 * @param {Object} user
 * @param {(user) => void} done
 */
export const CreateUserAsAdmin = (googleUser, userDocument, done) => {
    // get the function
    const create = httpsCallable(FUNCTIONS, 'CREATE_NEW_USER');

    // call the create user function
    create(googleUser)
    .then( async (response) => {
        if (response.data.errorInfo == undefined) {
            // update the user UId
            userDocument.id = response.data.uid;

            // save to the DB
            Users.Create({user: userDocument, log: true});

            // save user in state and CACHE
            LoadNewUser(userDocument);

            // finish
            if (done) {
                done(userDocument);

                // log to console if admin user
                Utility.Log("INFO", `ADMIN Event :: (CreateUserAsAdmin)`, userDocument);
            }
        }
        else {
            console.log('[CreateUserAsAdmin] FAILED', response.data);
            // show success
            Popup.New({
                renderTo: "app",
                type: Types.POPUP_ERROR,
                id: "new-user-error",
                header: `User Creation Failed (${response.data.errorInfo.code})`,
                message: `Failed to create a google account (${googleUser.email}). ${response.data.errorInfo.message}`,
                other: null,
                mainAction: {
                    id: 1,
                    text: "Dismiss",
                    action: () => Popup.Close()
                },
            });
        }
    });
};

/**
 *
 * @param {Object<{ token: String, amountInCents: Number, currency: String, secret: String }>} payment
 * @param { Function } done
 * @constructor
 */
export const MakePayment = async (payment, done) => {
    // get the function
    // const pay = httpsCallable(FUNCTIONS, 'CREATE_NEW_PAYMENT');
    // pay()
    console.log("Before Charge Request");
    axios.post(
        'https://online.yoco.com/v1/charges/',
        {
            token: payment.token,
            amountInCents: payment.amountInCents,
            currency: payment.currency,
        },
        {
            headers: {
                'X-Auth-Secret-Key': payment.secret,
            },
        },
    )
    .then(res => {
        console.log('Request Response', res)
        // res.status will contain the HTTP status code
        // res.data will contain the response body
    })
    .catch(error => {
        console.error(res)
        // handle errors
    })
};


