import { Utility } from "./Utility";

export const Config = {
    // -=-=-=-=-=-=-=- Common  -=-=-=-=-=-=-=-
    DELIVERY_FEE: "99.00", // Current Delivery Fee
    FREE_DELIVERY_AMOUNT: "1000", // Amount in which the customer will receive free delivery
    AUTO_REFRESH_TIME: 60000, // How long before the cache auto refreshes
    USERS_PERSIST: true, // For dummy user data being loading into DB

    // -=-=-=-=-=-=-=- Sensitive Info -=-=-=-=-=-=-=-
    VERSION: Utility.ENV_VALUE(process.env.VERSION), // App Version
    ENVIRONMENT: Utility.ENV_VALUE(process.env.ENVIRONMENT), // Working Environment
    WEBSITE_URL: Utility.ENV_VALUE(process.env.WEBSITE_URL), // Lions Den Biltong Website
    WHATSAPP_URL: Utility.ENV_VALUE(process.env.WHATSAPP_URL), // Lions Den Biltong Whatsapp Link

    // -=-=-=-=-=-=-=- Yoko Info -=-=-=-=-=-=-=-
    YOCO_PUBLIC_LIVE: Utility.ENV_VALUE(process.env.YOCO_PUBLIC_LIVE), // Live Public Key
    YOCO_PUBLIC_TEST: Utility.ENV_VALUE(process.env.YOCO_PUBLIC_TEST), // Test Public Key
    YOCO_SECRET_LIVE: Utility.ENV_VALUE(process.env.YOCO_SECRET_LIVE), // Secret Live Key
    YOCO_SECRET_TEST: Utility.ENV_VALUE(process.env.YOCO_SECRET_TEST), // Secret Test Key
    YOCO_CHARGE_API: Utility.ENV_VALUE(process.env.YOKO_CHARGE_API), // '/charge' API

    // -=-=-=-=-=-=-=- HTTP Cloud Functions -=-=-=-=-=-=-=-
    MAKE_YOCO_PAYMENT: Utility.ENV_VALUE(process.env.MAKE_YOCO_PAYMENT), // URL for the Yoco payment API
    SENDER_API_TOKEN: Utility.ENV_VALUE(process.env.SENDER_API_TOKEN), // URL for the Yoco payment API
};
