export const userList = {
    /**
     * Nav item
     * @type {{}}
     */
    TYPE_USER: {
        id : 0,
        firstname: "",
        lastname: "",
        email : "",
        password : "",
        mobileNumber : "",
        role : 1,
        status: true,
        photo: "",
        deliveryDetails : {
            streetNumber : 0,
            streetName : "",
            city : "",
            postalCode : ""
        }
    },

    /**
     * Will generate a top navigation bar
     * @param {{
     *     renderEl: String,
     *     users: userList.TYPE_USER[],
     *     actions: {
     *         edit: Function,
     *         delete: Function
     *     }
     * }} options
     * @constructor
     */
    Create: (options) => {
        // generate HTML
        const HTML = userList.buildHTML({users: options.users});
        
        // render the component
        userList.Render({renderEl: options.renderEl, html: HTML}, () => {
            options.users.forEach(user => {
                const editUser = document.getElementById(`editUser-${user.id}`);
                const deleteUser = document.getElementById(`deleteUser-${user.id}`);

                if (editUser) editUser.onclick = () => options.actions.edit(user);
                if (deleteUser) deleteUser.onclick = () =>  options.actions.delete(user);
            });
        });
    },

    /**
     * Will build up the html for the component
     * @param {{users: Array}} options
     * @returns String
     */
    buildHTML: (options) => {

        // generate the nav items html
        let userList = `
        <div class="userRow d-flex flex-center flex-align">
            <div class="column photo text-sm-bold">Photo</div>
            <div class="column name text-sm-bold">Member name</div>
            <div class="column mobile text-sm-bold">Mobile</div>
            <div class="column email text-sm-bold">Email</div>
            <div class="column status text-sm-bold">Status</div>
            <div class="column actions text-center text-sm-bold">Actions</div>
        </div>`;

        // generate rows
        if (options.users) options.users.forEach(user => {
            const avatar = (user.avatar)? user.avatar : "/src/assets/images/users/default.png";
            const status = (user.active)? "ACTIVE" : "SUSPENDED";
            const statusColor = (user.active)? "background-color: var(--success); color: var(--white)" : "background-color: var(--warning); color: var(--dark)";
            userList += `
            <div id="user-${user.id}" class="userRow d-flex flex-center flex-align">
                <div class="column photo text-sm"><img src="${avatar}"></div>
                <div class="column name text-sm">${user.firstname} ${user.lastname}</div>
                <div class="column mobile text-sm">${user.mobileNumber}</div>
                <div class="column email text-sm">${user.email}</div>
                <div class="column status text-sm"><span style="${statusColor}">${status}</span></div>
                <div class="column actions d-flex flex-even flex-align">
                    <div id="editUser-${user.id}" class="userRowEditAction icon"><i class="fas fa-pencil-alt"></i></div>
                    <div id="deleteUser-${user.id}" class="userRowDeleteAction icon"><i class="fas fa-trash-alt"></i></div>
                </div>
            </div>`;
        });

        return `
        <div id="userDashList" class="d-flex flex-col">
            ${userList}
        </div>`;
    },

    /**
     *
     * @param {{renderEl: String, html: String}} options
     * @param {Function} callback
     * @constructor
     */
    Render: (options, callback) => {
        // get render element
        const renderElement = document.getElementById(options.renderEl);

        // if element was found, render navbar
        if (renderElement) {
            // render
            renderElement.innerHTML = options.html;

            // apply onclick
            callback();
        }
        else console.error("userList :: Failed to find render element");
    },
};