import { State } from "@jordan_langton/activejs-raspberry-core";

const globaleState = State.createStore({
    initialMessage: {
        header: "",
        message: "",
        buttons: []
    },
    adminSideNavMinimized: false,
    loadedPromoCodes: [
        {
            code: "TESTPROMO",
            label: "10% discounted from your order",
            percentage: .10,
            uses: 1,
            users: [],
        },
        {
            code: "TESTPROMO2",
            label: "50% discounted from your order",
            percentage: .50,
            uses: 3,
            users: [],
        },
    ]
});

/**
 * Gets the proptery (adminSideNavMinimized)
 * @returns {Boolean}
 */
export const getAdminSideNavMinimized = () => globaleState.Select('adminSideNavMinimized');

/**
 * Sets the proptery (adminSideNavMinimized)
 * @param {Boolean}
 * @returns {*}
 */
export const setAdminSideNavMinimized = value => globaleState.Commit('adminSideNavMinimized', value);

/**
 * Gets the data for the initial message
 * @returns {{
        header: String,
        message: String,
        buttons: Array
    }}
 */
export const getInitialMessage = () => globaleState.Select('initialMessage');

/**
 * Will set the values up for the initial message to show
 * @param {{
        header: String,
        message: String,
        buttons: Array<{
            id: String,
            text: String,
            action: () => Any
        }>
    }} value 
 * @returns 
 */
export const setInitialMessage = (value) => globaleState.Commit('initialMessage', value);