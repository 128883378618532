import { Config } from "./Config";

const LOCAL_DB_VERSION = btoa(Config.VERSION);
const LOCAL_DB_ORDERS = btoa("ORDERS");
const LOCAL_DB_USERS = btoa("USERS");
const LOCAL_DB_PRODUCTS = btoa("PRODUCTS");
const LOCAL_DB_FILTERS = btoa("FILTERS");

/**
 * Checks if the version of the app has changed
 * and if so, we clear the Cache in order to update
 * it with the new CACHE
 */
export const CheckIfAppVersionHasChanged = () => {
    const DB_VERSION = atob(localStorage.getItem(LOCAL_DB_VERSION));
    if (DB_VERSION !== Config.VERSION) {
        ClearCache();
        console.log('[CACHE VERSION CHANGED] successfully cleared CACHE');
    }
};

/**
 * Checks if there are any orders stored in cache
 * @returns {Boolean}
 */
export const CheckIfOrdersAreStored = () => {
    const CACHE_ORDERS = localStorage.getItem(LOCAL_DB_ORDERS);
    if (CACHE_ORDERS) return true;
    return false;
};

/**
 * Checks if there are any Users stored in cache
 * @returns {Boolean}
 */
export const CheckIfUsersAreStored = () => {
    const CACHE_ORDERS = localStorage.getItem(LOCAL_DB_USERS);
    if (CACHE_ORDERS) return true;
    return false;
};

/**
 * Checks if there are any Products stored in cache
 * @returns {Boolean}
 */
export const CheckIfProductsAreStored = () => {
    const CACHE_PRODUCTS = localStorage.getItem(LOCAL_DB_PRODUCTS);
    if (CACHE_PRODUCTS) return true;
    return false;
};

/**
 * Checks if there are any Filters stored in cache
 * @returns {Boolean}
 */
export const CheckIfFiltersAreStored = () => {
    const CACHE_FILTERS = localStorage.getItem(LOCAL_DB_FILTERS);
    if (CACHE_FILTERS) return true;
    return false;
};

/**
 * Will store all orders retrieved from DB into cache
 * @param {Object[]} orders
 */
export const StoreOrdersInCache = (orders) => {
    const key = {};
    orders.forEach(order => key[order.id] = order);
    localStorage.setItem(LOCAL_DB_VERSION, LOCAL_DB_VERSION);
    localStorage.setItem(LOCAL_DB_ORDERS, btoa(JSON.stringify(key)));
};

/**
 * Will store all Users retrieved from DB into cache
 * @param {Object[]} users
 */
export const StoreUsersInCache = (users) => {
    const key = {};
    users.forEach(user => key[user.id] = user);
    localStorage.setItem(LOCAL_DB_USERS, btoa(JSON.stringify(key)));
    localStorage.setItem(LOCAL_DB_VERSION, LOCAL_DB_VERSION);
};

/**
 * Will store all Products retrieved from DB into cache
 * @param {Object[]} products
 */
export const StoreProductsInCache = (products) => {
    const key = {};
    products.forEach(product => key[product.id] = product);
    localStorage.setItem(LOCAL_DB_PRODUCTS, btoa(JSON.stringify(key)));
    localStorage.setItem(LOCAL_DB_VERSION, LOCAL_DB_VERSION);
};

/**
 * Will store all Filters retrieved from DB into cache
 * @param {Object[]} filters
 */
export const StoreFiltersInCache = (filters) => {
    const key = {};
    filters.forEach(filter => key[filter.id] = filter);
    localStorage.setItem(LOCAL_DB_FILTERS, btoa(JSON.stringify(key)));
    localStorage.setItem(LOCAL_DB_VERSION, LOCAL_DB_VERSION);
};

/**
 * Retrieves all orders from cache
 * @returns {Object[]}
 */
export const GetOrdersFromCache = () => {
    if (CheckIfOrdersAreStored()) {
        const returnValue = [];
        const CACHE_ORDERS = JSON.parse(atob(localStorage.getItem(LOCAL_DB_ORDERS)));
        for (const key in CACHE_ORDERS) returnValue.push(CACHE_ORDERS[key]);
        return returnValue;
    }
};

/**
 * Retrieves all Users from cache
 * @returns {Object[]}
 */
export const GetUsersFromCache = () => {
    if (CheckIfUsersAreStored()) {
        const returnValue = [];
        const CACHE_USERS = JSON.parse(atob(localStorage.getItem(LOCAL_DB_USERS)));
        for (const key in CACHE_USERS) returnValue.push(CACHE_USERS[key]);
        return returnValue;
    }
};

/**
 * Retrieves all Products from cache
 * @returns {Object[]}
 */
export const GetProductsFromCache = () => {
    if (CheckIfProductsAreStored()) {
        const returnValue = [];
        const CACHE_PRODUCTS = JSON.parse(atob(localStorage.getItem(LOCAL_DB_PRODUCTS)));
        for (const key in CACHE_PRODUCTS) returnValue.push(CACHE_PRODUCTS[key]);
        return returnValue;
    }
};

/**
 * Retrieves all Filters from cache
 * @returns {Object[]}
 */
export const GetFiltersFromCache = () => {
    if (CheckIfFiltersAreStored()) {
        const returnValue = [];
        const CACHE_FILTERS = JSON.parse(atob(localStorage.getItem(LOCAL_DB_FILTERS)));
        for (const key in CACHE_FILTERS) returnValue.push(CACHE_FILTERS[key]);
        return returnValue;
    }
};

/**
 * Clears the current cache stored
 * @constructor
 */
export const ClearCache = () => localStorage.clear();
