import { Types } from "./Types";
import { Utility } from "./Utility";

export const Interface = {
    /**
     * All details describing a user
     * @returns {{firstname: string, role: number, mobileNumber: string, deliveryDetails: {streetName: string, streetNumber: string, city: string, postalCode: string}, active: boolean, id: string, avatar: string, email: string, lastname: string}}
     */
    USER: () => {
        return {
            id : "",
            firstname: "",
            lastname: "",
            email : "",
            mobileNumber : "",
            role : 1,
            active: true,
            avatar: "",
            usedPromoCodes: [],
            deliveryDetails : {
                streetNumber : "",
                streetName : "",
                city : "",
                postalCode : ""
            }
        }
    },

    GOOGLE_USER: () => {
        return {
            uid: "",
            displayName: "",
            photoURL: "",
            phoneNumber: "",
            email: "",
            password: ""
        };
    },

    PRODUCT: () => {
        return {
            id: 0,
            label: "",
            description: "",
            image: "",
            price: "",
            rating: 0,
            categoryId: 0,
            filterId: "",
            promoPrice: 0,
            pricePerKG: 0,
            weightedAmount: 0,
            weighted: false,
            onPromotion: false,
            active: true
        };
    },

    CATEGORY: () => {
        return {
            id: 0,
            label: "",
        };
    },

    FILTER: () => {
        return {
            id: "",
            categoryId: "",
            label: "",
        };
    },

    /**
     * All details describing a cart item
     * @returns {{id: Number, label: String, price: String, image: String, qty: Number, kgAmount: Number, pricePerKG: Number, weighted: Boolean, weightedAmount: Number}}
     */
    CART_ITEM: () => {
        return {
            id: 0,
            label: "",
            price: "",
            image: "",
            qty: 0,
            kgAmount: 0,
            pricePerKG: 0,
            weighted: false,
            weightedAmount: 0
        };
    },

    BANNER: () => {
        return {
            id: 0,
            url: '',
            categoryId: 0,
        };
    },

    ORDER: () => {
        return {
            tempId: Utility.getRandomString(16),
            total: 0,
            subTotal: 0,
            deliveryFee: 0,
            datePlaced: '',
            updates: {},
            trackingLink: "",
            appliedCode: { code: "", label: "", percentage: 0, uses: 0 },
            status: { referenceId: 0, label: '' },
            paymentStatus: { referenceId: 0, label: '' },
            user: Interface.USER(),
            products: [],
        }
    },

    /**
     * The event data required for a sendInBlue event
     * @returns Object
     */
    SIB_EVENT: () => {
        return {
            name: "",
            properties: {},
            data: null,
        };
    },
};
