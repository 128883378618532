import {Firebase, AUTH, DB} from "../Firebase";
import { Users } from "./Users";
import { Interface } from "../../interfaces";
import { Popup } from "../../Components/Popup";
import { Utility } from "../../Utility";
import { Types } from "../../Types";
import { saveLoggedInUserToState, getLoggedInUserFromState, removeLoggedInUser, restTempUser, getTempUser, getTempUsers, updateUserEmailInState, setupUsersDBSync, LoadNewUser, setTempUsers } from "../../stores/usersState";
import { onAuthStateChanged, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail, updateEmail  } from "firebase/auth";
import { Router } from "@jordan_langton/activejs-raspberry-core";
import { ClearCache } from "../../cache";
import { GetAllOrdersForUser, GetLatestOrderForUser, resetOrdersStore, setupOrdersDBSync } from "../../stores/ordersState";
import { getNewUserFromAdmin, setNewUserFromAdmin } from "../../stores/globalState";
import { LoadProducts, setupProductDBSync, setupFilterDBSync, LoadFilters, resetProductsStore } from "../../stores/productState";
import { Cart } from "../../Cart";
import { GetAllPromos, setupPromosDBSync } from "../../stores/promoState";
import { Email } from "../../Email";

/**
 * Checks if a user is sign in
 * @returns {{details: Object | null, signedIn: Boolean}}
 */
export const isUserSignedIn = () => {
    const user = getLoggedInUserFromState();

    // user is signed in
    if (user) {
        return {details: user, signedIn: true};
    }
    else return {details: null, signedIn: false};
};

/**
 * Checks if there is a cached user that is signed in and stores that user in state if so
 */
export const setupAuthStateChange = () => {
    onAuthStateChanged(AUTH, async (user) => {
        Utility.Log("SUCCESS", `ADMIN USERS :: (onAuthStateChanged)`, user);

        // get url info
        const URL = Utility.getCurrentURL();
        const PARAMS = Utility.getCurrentParams();
        const PARSED_PARAMS = Utility.generateUrlParams(PARAMS);

        // check that a new user was logged in
        if (user) {

            // save the user in DB/STATE
            await saveUserDocument(user);

            // always reset temp user credetials
            if (getTempUser() !== null) restTempUser();

            // do the default route
            const stateUser = getLoggedInUserFromState();
            if (stateUser.active) {
                // load all existing orders
                await setupOrdersDBSync();

                // load all existing users
                await setupUsersDBSync();

                // load all existing products
                await setupProductDBSync();

                // load all existing filters
                await setupFilterDBSync();

                // load all existing promos
                await setupPromosDBSync();

                // started the auto refresh of data in cache
                await Utility.autoRefreshCacheWithDB();

                // If cart was built up before this loads the items back into cart
                Cart.initialize();

                // try auto apply a promo code
                if (PARSED_PARAMS.promoCode) {
                    // apply the promo code
                    const match = GetAllPromos().filter(promo => promo.code == PARSED_PARAMS.promoCode)[0];
                    if (match) Cart.appliedPromoCode = match;
                }

                // route to initial page
                if (URL && (URL != "/authentication") && (URL != "/mobile/authentication")) await Router.route(URL, Utility.generateUrlParams(PARAMS));
                else await Router.route('/storeFront');

                // check if the user has a loaded order
                const allowedViews = [
                    '/storeFront',
                    '/mobile/storeFront',
                    '/products',
                    '/mobile/products',
                ];
                if (GetAllOrdersForUser(stateUser.id).length >= 1 && allowedViews.includes(URL)) {
                    setTimeout(() =>Popup.New({
                        renderTo: "app",
                        type: Types.POPUP_SUCCESS,
                        id: "user-has-orders",
                        header: `Welcome Back ${stateUser.firstname} ${stateUser.lastname}!`,
                        message: "You have an order(s) waiting for you. Would you like to see your current orders?",
                        mainAction: {
                            id: 1,
                            text: "See Order(s)",
                            action: () => {
                                // get url info
                                const latestOrder = GetLatestOrderForUser(stateUser.id);
                                Router.route('/orderHistory', {orderId: latestOrder.id});
                                Popup.Close();
                            },
                        },
                        secondary: {
                            id: 2,
                            text: "No Thanks",
                            action: () => Popup.Close(),
                        }
                    }), 200)
                }

                // tell user that we saved their cart
                if (Cart.items.length > 0 && URL != "/payment" && GetAllOrdersForUser(stateUser.id).length == 0) setTimeout(() => Popup.New({
                    renderTo: "app",
                    type: Types.POPUP_SUCCESS,
                    id: "old-cart-loaded",
                    header: `Welcome Back ${stateUser.firstname} ${stateUser.lastname}!`,
                    message: "Thank you for coming back to our store! Your cart is exactly as you left it. Would you like to view your cart?",
                    mainAction: {
                        id: 1,
                        text: "Continue To Cart",
                        action: () => {
                            // get url info
                            const URL = Utility.getCurrentURL();
                            const PARAMS = Utility.getCurrentParams(["showCart=true", `categoryId=${Types.CATEGORY_BILTONG.id}`]);

                            // route to initial page
                            if (URL && (URL !== "/storeFront")) Router.route(URL, Utility.generateUrlParams(PARAMS));
                            else Router.route('/products', {categoryId: Types.CATEGORY_BILTONG.id, showCart: true});
                            Popup.Close();
                        },
                    },
                    secondary: {
                        id: 2,
                        text: "No Thanks",
                        action: () => {
                            Utility.setCookie('CART', false);
                            Popup.Close();
                        },
                    }
                }), 200)
            }
            else {
                // reset logged in user in state
                removeLoggedInUser();
                signUserOut();

                setTimeout(() => {
                    Popup.New({
                        renderTo: "productsPopup",
                        type: Types.POPUP_ERROR,
                        id: "susspended-user",
                        header: 'User Profile Suspended',
                        message: `Your profile under the email (${stateUser.email}) has been suspended. Please contact our support if you wish to activate it again.`,
                        other: null,
                        mainAction: {
                            id: 1,
                            text: "Dismiss",
                            action: () => Popup.Close()
                        },
                    });
                }, 500);
            }
        }

        else {
            // reset logged in user in state
            removeLoggedInUser();

            // always reset temp user credentials
            if (getTempUser() !== null) restTempUser();

            // route to login
            switch (Utility.device()) {
                case Types.DEVICE_TYPE_DESKTOP: return Router.route('/storeFront');
                // case Types.DEVICE_TYPE_DESKTOP: return Router.route('/authentication');
                case Types.DEVICE_TYPE_MOBILE: return Router.route('/mobile/storeFront');
                case Types.DEVICE_TYPE_TABLET: return Router.route('/mobile/storeFront');
            }
        }
    });
};

/**
 * Will get you the currently signed in user Google info
 * @returns {Object}
 */
export const getSignedInUser = () => AUTH.currentUser;

/**
 * Will signout the current user
 * @returns {void}
 */
export const signUserOut = () => {
    // sign out the google acount
    signOut(AUTH);

    // clear out the logged in user
    removeLoggedInUser();

    // clear out orders from state
    resetOrdersStore();

    // clear out products and filters in state
    resetProductsStore();

    // clear the cache
    ClearCache();

    // log
    console.log("User successfully signed out");
};

/**
 * Builds up a new user document to be stored in DB
 * @returns {Object}
 */
export const newUserDocument = (googleProfile) => {
    // check if a temp user was stored
    const tempUser = getTempUser();
    const tempUsers = getTempUsers();
    if (tempUser || tempUsers) {
        const returnData = {userDocument: null, isTemp: true};
        if (tempUser !== null) {
            tempUser.id = googleProfile.uid;
            returnData.userDocument = tempUser;
        }
        else if (tempUsers !== null) {
            let user = null;
            const entry = tempUsers;

            if (entry.length >= 1) {
                user = entry[0];
                user.id = googleProfile.uid;
                tempUsers.splice(0, 1);
                setTempUsers(tempUsers);
                returnData.userDocument = user;
            }else console.log(entry.length);
        }

        return returnData;
    }
    else {
        // initialise the default user info
        const userDocument = Interface.USER();

        // get the user ID
        userDocument.id = googleProfile.uid;

        // get first and last names
        if (googleProfile.displayName) {
            const diplayName = googleProfile.displayName.split(" ");
            userDocument.firstname = diplayName[0];
            userDocument.lastname = diplayName[1];
        }

        // the rest
        userDocument.email = googleProfile.email;
        userDocument.mobileNumber = (googleProfile.phoneNumber)? googleProfile.phoneNumber : "";
        userDocument.avatar = (googleProfile.photoURL)? googleProfile.photoURL : "";

        return {userDocument, isTemp: false, single: false};
    }
};

/**
 * Saves a user record in DB and in the state
 * @param {Object} userDocument
 */
export const saveUserDocument = async (googleAccount) => {
    // creates record in DB
    const stored = await Users.getByID(googleAccount.uid);
    const subscriberExists = await Email.subscriberExists(googleAccount.email);

    const saveSubscriberAndSendEmail = (userType) => {
        // track user registered event
        Email.workflowID(Types.USER_REGISTERED);

        // define the email contact to sent email to
        const POST = Email.POST();
        POST.data("email", userType.userDocument.email);
        POST.data("firstname", userType.userDocument.firstname);
        POST.data("lastname", userType.userDocument.lastname);

        // send email
        Email.subscribeUser(POST.options, (res, passedOptions) => {
            // chick for failures
            if (!res.success) Utility.Log("ERROR", `SENDER Event :: (SUBSCRIBER CREATION) ${res.message}`, updateOptions);

            // only send welcome email if user in new
            if (subscriberExists.success == false) {
                const POST = Email.POST();
                POST.data("email", userType.userDocument.email);

                Email.send(POST.options, (sendRes, options) => {
                    // chick for failures
                    if (!res.success) Utility.Log("ERROR", `SENDER Event :: (WELCOME EMAIL) ${res.message}`, updateOptions);
                    Utility.Log("INFO", `SENDER Event :: (WELCOME EMAIL)`, sendRes)
                })
            }
            // log to console if admin user
            Utility.Log("INFO", `SENDER Event :: (SUBSCRIBER ADDED)`, passedOptions);
        });
    }

    if (!stored) {
        // build up document
        const userType = newUserDocument(googleAccount);

        // save to the DB
        Users.Create({user: userType.userDocument, log: true});

        // setup the logged in user in state
        saveLoggedInUserToState(userType.userDocument);

        // add the user to the list of users and store in CACHE
        LoadNewUser(userType.userDocument);

        // send the welcome email to new user\
        saveSubscriberAndSendEmail(userType);
    }
    else {
        saveLoggedInUserToState(stored);

        // save the subscriber if it's not already
        if (!subscriberExists.success) {
            // define the email contact to sent email to
            const POST = Email.POST();
            POST.data("email", stored.email);
            POST.data("firstname", stored.firstname);
            POST.data("lastname", stored.lastname);
            Email.subscribeUser(POST.options, (res, passedOptions) => {
                // chick for failures
                if (!res.success) Utility.Log("ERROR", `SENDER Event :: (SUBSCRIBER CREATION) ${res.message}`, updateOptions);

                // log to console if admin user
                Utility.Log("INFO", `SENDER Event :: (SUBSCRIBER ADDED)`, passedOptions);
            });
        }
    }
};

/**
 * Will sign in a user using their Google account
 */
export const signInWithGoogle = (fail) => {
    // setup the provider
    const GOOGLE_PROVIDER = new GoogleAuthProvider();

    // start the popup sign in process
    signInWithPopup(AUTH, GOOGLE_PROVIDER)
        .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;

            // The email of the user's account used.
            const email = error.email;

            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);

            // error
            fail(true, {
                email,
                credential,
                code: errorCode,
                message: errorMessage,
            })
        });
};

/**
 * Will attempt to authenticate a user based on an email and password
 * @param {String} email
 * @param {String} password
 * @param {Function} fail
 */
export const signInEmailAndPassword = (email, password, fail) => {
    signInWithEmailAndPassword(AUTH, email, password).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        // error
        fail(true, {
            email,
            code: errorCode,
            message: errorMessage,
        })
    });
};

/**
 * Will attempt to creat a user based on an email and password and authenticate
 * @param {String} email
 * @param {String} password
 * @param {Function} fail
 */
export const createWithEmailAndPassword = (email, password, fail) => {
    return createUserWithEmailAndPassword(AUTH, email, password).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        // error
        fail(true, {
            email,
            code: errorCode,
            message: errorMessage,
        })
    });
};

/**
 * Will send a reset password email to the user
 * @param {String} email
 * @param {Function} success
 */
export const sendResetPasswordEmail = (email, success) => {
    sendPasswordResetEmail(AUTH, email).then(success)
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        Popup.New({
            renderTo: "app",
            type: Types.POPUP_ERROR,
            id: "app",
            header: `Email failed to send (${errorCode})`,
            message: errorMessage,
            other: null,
            mainAction: {
                id: 1,
                text: "Dismiss",
                action: () => Popup.Close()
            },
        });
    });
};

/**
 * Will request an update for a users email
 * @param {String} email
 * @param {Function} success
 */
export const requestEmailUpdate = (email, success) => {
    updateEmail(AUTH.currentUser, email).then(() => {
        // update the email in state
        const userDocument = updateUserEmailInState(email).loggedInUser;
        Users.UpdateWithKey(AUTH.currentUser.uid, {"email": userDocument.email}, true);

        success();
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        Popup.New({
            renderTo: "app",
            type: Types.POPUP_ERROR,
            id: "app",
            header: `Email failed to update (${errorCode})`,
            message: errorMessage,
            other: null,
            mainAction: {
                id: 1,
                text: "Dismiss",
                action: () => Popup.Close()
            },
        });
    });
};
