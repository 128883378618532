import { Config } from "./Config";
import { Types } from "./Types";
import { Interface } from "./interfaces";
import { Popup } from "./Components/popup";
import { getLoggedInUserFromState } from "./stores/usersState";
import { GetAllPromos } from "./stores/promoState";
import { Utility } from "./Utility";
import { Router } from "@jordan_langton/activejs-raspberry-core";
import { Email } from "./Email";

export const Cart = {
    // abandonTime: 60000,
    abandonTime: 300000,
    appliedPromoCode: null,

    items: [
        // {id: 0, image: 'src/assets/images/products/BBQ1.JPG', label: 'BBQ Pork Crackling', price: '10.00', tags: ['Top Seller', 'Favourite'], qty: 2, weightedAmount: 0},
        // {id: 1, image: 'src/assets/images/products/Chilli%20Slabs3.JPG', label: 'Chilli Slabs', price: '10.00', tags: ['Top Seller'], qty: 1, weightedAmount: 300},
    ],

    initialize: () => {
        const cookieRaw = Utility.getCookie('CART');
        const previousCart = (cookieRaw)? JSON.parse(cookieRaw): false;
        if (previousCart) Cart.items = previousCart;
    },

    logEvent: (type, item) => {
        const user = getLoggedInUserFromState();
        if (user) {
            // define the email contact to sent email to
            const POST = Email.POST();
            Email.workflowID(type);
            POST.data("email", user.email);
            POST.data("firstname", user.firstname);
            POST.data("lastname", user.lastname);
            POST.data("phone", user.mobileNumber);

            // send email
            Email.send(POST.options, (res, passedOptions) => {
                console.log(res)
                // log to console if admin user
                Utility.Log("INFO", `SENDER Event :: (CART ABANDONED)`, passedOptions);
            });
        }
    },

    /**
     * Will add a product to the cart
     * @param {Object} newItem
     */
    Add: (newItem) => {
        const defaultItem = Interface.CART_ITEM();
        defaultItem.id = newItem.id;
        defaultItem.label = newItem.label;
        defaultItem.price = (newItem.onPromotion)? newItem.promoPrice : newItem.price;
        defaultItem.image = newItem.image;
        defaultItem.qty = 1;

        Cart.items.push(defaultItem);
        Utility.setCookie('CART', Cart.items);

        // log event with sendInBlue
        if (Cart.Abandoned) clearTimeout(Cart.Abandoned)
        Cart.Abandoned = setTimeout(() => Cart.logEvent(Types.CART_ABANDON, newItem), Cart.abandonTime)
    },

    /**
     * Will add a weighted product to the cart
     * @param {Object} newItem
     * @param {Number} weightedValue
     */
    AddWeighted: (newItem, weightedValue) => {
        const weightedItem = Interface.CART_ITEM();
        weightedItem.id = newItem.id;
        weightedItem.label = newItem.label;
        weightedItem.price = (newItem.onPromotion)? newItem.promoPrice : newItem.price;
        weightedItem.image = newItem.image;
        weightedItem.weighted = true;
        weightedItem.kgAmount = (weightedValue / ((newItem.onPromotion)? newItem.promoPrice : newItem.pricePerKG)).toFixed(2);
        weightedItem.pricePerKG = (newItem.onPromotion)? newItem.promoPrice : newItem.pricePerKG;
        weightedItem.weightedAmount = weightedValue;
        weightedItem.qty = 1;

        Cart.items.push(weightedItem);
        Utility.setCookie('CART', Cart.items);

        // log event with sendInBlue
        // log event with sendInBlue
        if (Cart.Abandoned) clearTimeout(Cart.Abandoned)
        Cart.Abandoned = setTimeout(() => Cart.logEvent(Types.CART_ABANDON, weightedItem), Cart.abandonTime)
    },

    /**
     * Gets a specific item from the cart based on the ID
     * @param {Number} id
     * @returns {{id: Number, label: String, price: String, image: String, qty: Number}}
     */
    Get: (id) => Cart.items[Cart.items.findIndex(item => item.id === id)],

    /**
     * Will remove an item from the cart based on the item ID
     * @param {Number} id
     */
    Remove: (id) => {
        Cart.items.splice(Cart.items.findIndex(item => item.id === id), 1);
        if (Cart.items.length >= 1) Utility.setCookie('CART', Cart.items);
        else Utility.setCookie('CART', false);

        // log event with sendInBlue
        if (Cart.Abandoned) clearTimeout(Cart.Abandoned)
        Cart.Abandoned = setTimeout(() => Cart.logEvent(Types.CART_ABANDON, id), Cart.abandonTime)
    },

    /**
     * Will update an item's qty within the cart based on the item ID
     * @param {Number} id
     * @param {Number} quantity
     * @returns
     */
    UpdateQty: (id, quantity) => {
        Cart.items[Cart.items.findIndex(item => item.id === id)].qty = quantity;
        Utility.setCookie('CART', Cart.items);

        // log event with sendInBlue
        if (Cart.Abandoned) clearTimeout(Cart.Abandoned)
        Cart.Abandoned = setTimeout(() => Cart.logEvent(Types.CART_ABANDON, id), Cart.abandonTime)
    },

    /**
     * Will update an item's weighted amount within the cart based on the item ID
     * @param {Number} id
     * @param {Number} amount
     * @returns
     */
    UpdateWeightedAmount: (id, amount) => {
        Cart.items[Cart.items.findIndex(item => item.id === id)].weightedAmount = amount;
        Utility.setCookie('CART', Cart.items);

        // log event with sendInBlue
        if (Cart.Abandoned) clearTimeout(Cart.Abandoned)
        Cart.Abandoned = setTimeout(() => Cart.logEvent(Types.CART_ABANDON, id), Cart.abandonTime)
    },

    /**
     * Will return the quantity of the item based on the ID
     * @param {Number} id
     * @returns {Number}
     */
    getCartItemQty: (id) => Cart.items[Cart.items.findIndex(item => item.id === id)].qty,

    /**
     * Will get all the cart items
     * @returns {Array}
     */
    getCurrentCartItems: () => Cart.items,

    /**
     * Will get the current cart sub total
     * @returns {String}
     */
     getSubTotal: () => Cart.items.reduce((acc, current) => {
        const amount = (!current.weighted)? +current.price : +current.weightedAmount;
        const quantity = +current.qty;
        const subtotal = (amount * quantity)
        const subtotalWithDiscount = (subtotal - (subtotal * ((Cart.appliedPromoCode)? Cart.appliedPromoCode.percentage : 0)));
        const subtotalWithDiscountPlus = (subtotal - (subtotal * ((Cart.appliedPromoCode)? .10 : 0)));

        // check if a promo code is applied
        if (Cart.appliedPromoCode) {
            if (subtotal >= 2000) return (acc + subtotalWithDiscountPlus)
            else return (acc + subtotalWithDiscount)
        }
        return (acc + subtotal)
     }, 0),

     /**
      * Returns the cart total
      * @returns {String}
      */
     getTotal: () => ((+Cart.getSubTotal()) + ((Cart.getSubTotal() < Config.FREE_DELIVERY_AMOUNT)? +Config.DELIVERY_FEE : 0)).toFixed(2),

     /**
      * Will return the cart total as a cents value
      * @returns {Number}
      */
     getTotalInCents: () => (parseFloat(Cart.getTotal()) * 100),

    /**
     * Checks if an item is in the cart based on the ID
     * @param {Number} id
     * @returns {{inCart: Boolean, item: Object}}
     */
    itemInCart: (id) => {
        const index = Cart.items.findIndex(item => item.id === id);
        if (index > -1) return {inCart: true, item: Cart.items[index]};
        else return {inCart: false, item: null};
    },

    /**
     * Will validate that your profile in=s complete and if so carry on to payment
     */
    checkout: () => {
        let required = false;
        const missing = {};
        const user = getLoggedInUserFromState();

        // don't show cart if nothing is in it
        if (!user) {
            return Popup.New({
                renderTo: "app",
                type: Types.POPUP_ERROR,
                id: "not-logged-in",
                header: "Not Logged In",
                message: "Please login to access your cart.",
                other: null,
                mainAction: {
                    id: "login-button",
                    text: "Login",
                    action: () => Popup.Close()
                },
                secondary: {
                    id: "carry-on",
                    text: "Carry on shopping",
                    action: () => Popup.Close()
                }
            });
        }

        // check which fields are missing
        if (user.firstname == "") missing["Firstname"] = true;
        if (user.lastname == "") missing["Lastname"] = true;
        if (user.email == "") missing["Email"] = true;
        if (user.mobileNumber == "") missing["Mobile Number"] = true;
        if (user.deliveryDetails.streetNumber == "") missing["Street Number"] = true;
        if (user.deliveryDetails.streetName == "") missing["Street Name"] = true;
        if (user.deliveryDetails.city == "") missing["City"] = true;
        if (user.deliveryDetails.postalCode == "") missing["Postal Code"] = true;

        // check if object is empty
        if (missing && Object.keys(missing).length === 0 && Object.getPrototypeOf(missing) === Object.prototype) required = false;
        else required = missing;

        // check for a promo code
        const promoCode = document.querySelector('#promoCode');
        if (promoCode.value != "") {
            const code = promoCode.value;
            const subTotal = Cart.getSubTotal();
            const matched = GetAllPromos().filter(promo => promo.code == code)[0];

            // if the code entered matches a loaded promo, apply the promo discount
            if (matched) {

            }
        }

        // if your profile is complete
        if (!required) {
            Router.route("/payment");

            // meta event
            fbq('track', 'InitiateCheckout',
                {
                    content_ids: ['CART_CHECKOUT'],
                    eventref: 'fb_oea' // or set to empty string
                }
            );

            // log event with sendInBlue
            if (Cart.Abandoned) clearTimeout(Cart.Abandoned)
        }
        else {
            const keys = [];
            for (const key in required) keys.push(key);
            Popup.New({
                renderTo: "app",
                type: Types.POPUP_WARNING,
                id: "update-info-nothing",
                header: 'Missing profile information',
                message: `Your profile is incomplete. Please can you update the following fields: (${keys})`,
                other: null,
                mainAction: {
                    id: 1,
                    text: "View Profile",
                    action: () => {
                        Popup.Close();
                        Router.route('/profile');
                    }
                },
                secondary: {
                    id: 2,
                    text: "Cancel",
                    action: () => Popup.Close()
                }
            });
        }
    },

    // resets the cart to be ready to place another order
    reset: () => {
        Cart.items = [];
        Cart.appliedPromoCode = null;
        Utility.setCookie('CART', Cart.items);
    },

    getEmailCartItems: () => {
        return Cart.items.map(item => ({
            "cart_link": "https://lionsdenbiltong-dev.web.app/#/orderHistory",
            "product_image": item.image,
            "product_name": item.label,
            "product_price": item.price,
            "product_qty": item.qty,
        }));
    },

};
