export const FilterList = {

    /**
     * Creates a list of FilterList
     * @param {{filters: *, renderEl: string}} options
     */
    Create: (options) => {
        const HTML = FilterList.generateHTML({filters: options.filters, template: options.template});
        FilterList.Render({renderEl: options.renderEl, html: HTML});
    },

    /**
     * Generates the FilterList HTML
     * @param {{filters: Object[], template: Function}} options
     */
    generateHTML: (options) => {
        let HTML = "";
        options.filters.forEach(filter => {
            if (options.template) HTML += options.template(filter);
            else {
                HTML += `
                <div id="filter-${filter.id}" class="filterOption d-flex flex-between flex-align">
                    <div class="label">${filter.label}</div>
                    <div class="actions d-flex flex-align">
                        <button onclick="$this.deleteFilter('${filter.id}', '${filter.label}')">Delete</button>
                    </div>
                </div>`;
            }
        });

        return HTML;
    },

    /**
     *
     * @param {{renderEl: String, html: String}} options
     */
    Render: (options) => {
        const el = document.getElementById(options.renderEl);
        if (el) el.innerHTML = options.html;
    },

};
