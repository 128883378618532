import {Firebase, AUTH, DB} from "../Firebase";
import {
    doc,
    getDoc,
    getDocs,
    deleteDoc,
    setDoc,
    updateDoc,
    collection,
    onSnapshot,
    orderBy,
    query,
    limit,
    addDoc,
    where
} from "firebase/firestore";
import {Popup} from "../../Components/popup";
import {Types} from "../../Types";
import { Utility } from "../../Utility";

export const Orders = {

    /**
     * Will set/override a document under the [users] collection
     * @param {{order: Object, log: Boolean}} options
     * @param { Function } done
     */
    Create: (options, done) => {
        addDoc(collection(DB, "orders"), options.order)
        .then((res) => {
            updateDoc(doc(DB, `orders/${res.id}`),{id: res.id})
                .then((data) => {
                    // callback
                    if (done) done(res)
                    
                    // log a success
                    if (options.log) Utility.Log("SUCCESS", `ADMIN ORDERS :: (addDoc, updateDoc)`, res);
                })
                .catch(err => Popup.New({
                    renderTo: "productsPopup",
                    type: Types.POPUP_ERROR,
                    id: "order-create-fail",
                    header: 'Firebase Failure',
                    message: err.message,
                    other: null,
                    mainAction: {
                        id: 1,
                        text: "Dismiss",
                        action: () => Popup.Close()
                    },
                }));
        });
    },

    /**
     * Will update an order based on a key
     * @param {Number} id
     * @param {Object} key
     * @param {Boolean} log
     * @param {Function} success
     */
    UpdateWithKey: (id, key, log, success) => {
        const orderRef = doc(DB, `orders/${id}/`);
        updateDoc(orderRef, key, { merge: true }).then(() => {
            if (success) success();
            if (log) Utility.Log("SUCCESS", `ADMIN ORDERS :: (updateDoc)`, key);
        });
    },

    /**
     * Deletes a user based on the ID
     * @param {String} id
     * @param {Boolean} log
     */
    Delete: async (id, log) => {
        await deleteDoc(doc(DB, `orders/${id}/`)).then(() => {
            if (log) Utility.Log("SUCCESS", `ADMIN ORDERS :: (deleteDoc)`, id);
        });
    },

    /**
     * Will attempt to retrieve all user documents
     * @returns {Array}
     */
    getAll: async () => {
        const orders = [];
        const snapshot = await getDocs(collection(DB, "orders"));
        snapshot.forEach(doc => orders.push(doc.data()));
        return orders;
    },

    /**
     * Will attempt to retrieve a document based on an ID
     * @param {String} id
     * @returns {Object | null}
     */
    getByID: async (id) => {
        const orderRef = doc(DB, `orders/${id}`);
        const orderSnap = await getDoc(orderRef);

        if (orderSnap.exists()) return orderSnap.data();
        else {
            Utility.Log("ERROR", `ADMIN ORDERS :: (getByID)`, id);
            return null;
        }
    },

    /**
     * Deletes every order document stored under the orders collection
     */
    deleteAllOrders: async () => {
        const snapshot = await getDocs(collection(DB, "orders"));
        snapshot.forEach(doc => Orders.Delete(doc.data().id));
    },

    initializeSync: (callback) => {
        const q = query(collection(DB, "orders"), orderBy("datePlaced"));
        onSnapshot(q, callback);
    }

};
