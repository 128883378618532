import { Filters } from "../src/classes/Firebase/Collections/Filters"
import { filters_dump } from "./dumps/filters_dump"

/**
 * Will import filters into the LIVE DB
 * @param {Array<{id: String, categoryId: String, label: String}>} filters 
 */
export const importFilters = () => new Promise((resolve, reject) => {
    // get filters
    const filters = filters_dump;

    // load filters into DB
    filters.forEach( async (filter) => {
        setTimeout(async () => {
            await Filters.Create({ filter, log: true }, () => {
                // after successful creation
            })
        }, 2000);
    })

    // resolve after filters have been added
    resolve();
})